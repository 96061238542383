import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlanAlarm } from '@app/models';

export const {
  initialState: jobPlanAlarmInitialState,
  selectors: jobPlanAlarmSelectors,
  facade: JobPlanAlarmFacadeBase,
} = buildState(JobPlanAlarm);

export function jobPlanAlarmReducer(
  state = jobPlanAlarmInitialState
): IEntityState<JobPlanAlarm> {
  return state;
}
