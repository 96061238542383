import { parseISO } from 'date-fns';
import { createSelector } from '@ngrx/store';
import { jobDateEquipmentSelectors } from './job-date-equipment.state';
import { JobDateEquipmentVM } from '@app/models';
import { equipMachineVMEntitySelector } from './equipment-machine.join-selectors';

export const selectJobDateEquipVMAll = createSelector(
  jobDateEquipmentSelectors.selectAll,
  equipMachineVMEntitySelector,
  (jdEquip, equipment): JobDateEquipmentVM[] => {
    return jdEquip.map(
      (jde): JobDateEquipmentVM => {
        return {
          ...jde,
          equip: jde.equip in equipment ? equipment[jde.equip] : null,
          arrival: jde.arrival ? parseISO(jde.arrival) : null,
          departure: jde.departure ? parseISO(jde.departure) : null,
          actualDeparture: jde.actualDeparture
            ? parseISO(jde.actualDeparture)
            : null,
        };
      }
    );
  }
);
