import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { differenceInCalendarDays, addDays } from 'date-fns';

export interface PlannerDateRange {
  startDate: Date;
  endDate: Date;
  numDays: number;
}

export interface PlannerScrollDims {
  scrollLeft: number;
  width: number;
}

export interface PlannerVisibileDates {
  start: Date;
  end: Date;
}

@Injectable({
  providedIn: 'root',
})
export class PlannerUIService {
  private rootEle: HTMLElement = null;
  // tslint:disable-next-line: variable-name
  private _dayWidth = new BehaviorSubject<number>(0);
  // tslint:disable-next-line: variable-name
  private _dateRange = new BehaviorSubject<PlannerDateRange>({
    startDate: new Date(),
    endDate: new Date(),
    numDays: 0,
  });

  private _hilight = new BehaviorSubject<number>(-1);

  private _hoveredJob = new BehaviorSubject<string>('');

  // tslint:disable-next-line: variable-name
  private _scrollDisabled = new BehaviorSubject<boolean>(false);

  // tslint:disable-next-line: variable-name
  private _actionType = new BehaviorSubject<string>('normal');
  mobile = false;

  // tslint:disable-next-line: variable-name
  private _visibleDates = new BehaviorSubject<PlannerVisibileDates>({
    start: null,
    end: null,
  });

  private scrollDimensions: PlannerScrollDims;

  constructor(private platform: Platform) {
    this.mobile = this.platform.is('mobile');
  }

  setup(rootEle: HTMLElement, startDate: Date, endDate: Date) {
    this.rootEle = rootEle;
    const dayWidthValue = parseInt(
      getComputedStyle(this.rootEle).getPropertyValue('--day-width'),
      10
    );
    this._dayWidth.next(dayWidthValue);
    this.dateRange = { startDate, endDate };
    this._actionType.next('normal');
  }

  set scrollDims(dims: PlannerScrollDims) {
    this.scrollDimensions = dims;
    const dayWidth = this._dayWidth.value;
    const startDate = this._dateRange.value.startDate;
    const visibleDates = this._visibleDates.value;
    const leftDays = Math.floor(dims.scrollLeft / dayWidth);
    const rightDays = Math.floor((dims.scrollLeft + dims.width) / dayWidth);
    const newDates: PlannerVisibileDates = {
      start: addDays(startDate, leftDays),
      end: addDays(startDate, rightDays),
    };
    if (
      !visibleDates.start ||
      !visibleDates.end ||
      newDates.start.toDateString() !== visibleDates.start.toDateString() ||
      newDates.end.toDateString() !== visibleDates.end.toDateString()
    ) {
      this._visibleDates.next(newDates);
    }
  }

  set hilight(index: number) {
    this._hilight.next(index);
  }

  set hoveredJob(id: string) {
    this._hoveredJob.next(id);
  }

  get hilight$(): BehaviorSubject<number> {
    return this._hilight;
  }

  get hoveredJob$(): Observable<string> {
    return this._hoveredJob.asObservable();
  }

  get visibleDates$(): BehaviorSubject<PlannerVisibileDates> {
    return this._visibleDates;
  }

  set scrollDisabled(disabled: boolean) {
    this._scrollDisabled.next(disabled);
  }

  get scrollDisabled$(): BehaviorSubject<boolean> {
    return this._scrollDisabled;
  }

  set dayWidth(dayWidth: number) {
    this._dayWidth.next(dayWidth);
    this.scrollDims = this.scrollDimensions;
    this.rootEle.style.setProperty('--day-width', dayWidth + 'px');
  }

  getDayWidth(): BehaviorSubject<number> {
    return this._dayWidth;
  }

  set dateRange(dates: { startDate: Date; endDate: Date }) {
    const numDays =
      differenceInCalendarDays(dates.endDate, dates.startDate) + 1;
    this._dateRange.next({ ...dates, numDays });
    this.rootEle.style.setProperty('--num-days', `${numDays}`);
  }

  getDateRange(): BehaviorSubject<PlannerDateRange> {
    return this._dateRange;
  }

  set actionType(newValue: string) {
    this._actionType.next(newValue);
  }

  getActionType(): BehaviorSubject<string> {
    return this._actionType;
  }
}
