import { JobDateEffects } from './job-date/job-date.effects';
import { JobLocateEffects } from './job-locate.effects';
import { BaseDataEffects } from './base-data/base-data.effects';
import { JobIncidentMediaEffects } from './job-incident-media.effects';
import { JobMediaEffects } from './job-media.effects';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { appMetaReducers, appReducer } from './app.state';
import { NgrxAutoEntityModule } from '@briebug/ngrx-auto-entity';
import { environment } from '@env/environment';
import { EquipmentEffects } from './equipment/equipment.effects';
import { MaterialsEffects } from './materials/materials.effects';
import { SubcontractorsEffects } from './subcontractors/subcontractors.effects';
import { RestorationEffects } from './restoration/restoration.effects';
import { TruckingEffects } from './trucking/trucking.effects';
import { TruckingMoveEffects } from './trucking-move.effects';
import { UserMeEffects } from './user-me/user-me.effects';
import { JobPlannerEffects } from './job-planner/job-planner.effects';
import { JobPlanEffects } from './job-plan.effects';
import { JobPlanCrewAssignmentEffects } from './job-plan-crew-assignment.effects';
import { JobDetailsEffects } from './job-details/job-details.effects';
import { JobDocumentEffects } from './job-document.effects';
import { JobSiteEquipmentEffects } from './job-site-equipment.effects';
import { EmergencyTicketEffects } from './emergency-ticket.effects';
import { ContractExtraEffects } from './contract-extra.effects';
import { ContractExtraFieldLogEffects } from './contract-extra-field-log.effects';
import { JobRestorationEffects } from './job-restoration.effects';
import { JobRestorationMediaEffects } from './job-restoration-media.effects';
import { BackgroundUploadEffects } from './background-upload/background-upload.effect';
import { EquipmentMachineEffects } from './equipment-machine.effects';

@NgModule({
  imports: [
    StoreModule.forRoot(appReducer, { metaReducers: appMetaReducers }),
    EffectsModule.forRoot([
      BackgroundUploadEffects,
      BaseDataEffects,
      ContractExtraEffects,
      ContractExtraFieldLogEffects,
      EquipmentEffects,
      MaterialsEffects,
      SubcontractorsEffects,
      RestorationEffects,
      EmergencyTicketEffects,
      EquipmentMachineEffects,
      JobDetailsEffects,
      JobDocumentEffects,
      JobIncidentMediaEffects,
      JobRestorationMediaEffects,
      JobMediaEffects,
      JobLocateEffects,
      JobSiteEquipmentEffects,
      JobDateEffects,
      JobRestorationEffects,
      JobPlannerEffects,
      JobPlanEffects,
      JobPlanCrewAssignmentEffects,
      TruckingEffects,
      TruckingMoveEffects,
      UserMeEffects,
    ]),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    NgrxAutoEntityModule.forRoot(),
  ],
})
export class StateModule {}
