import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlanCrewRequirement } from '@app/models';
import { createAction, createReducer, on, props } from '@ngrx/store';

export const jobPlanUpdateCrewReq = createAction(
  '[JobPlanCrewReq]: Job Plan Update Crew Req',
  props<{ crewReqs: JobPlanCrewRequirement[] }>()
);

export const {
  initialState: jobPlanCrewReqInitialState,
  selectors: jobPlanCrewReqSelectors,
  facade: JobPlanCrewReqFacadeBase,
} = buildState(JobPlanCrewRequirement);

const _jobPlanCrewReqReducer = createReducer(
  jobPlanCrewReqInitialState,
  on(jobPlanUpdateCrewReq, (state, { crewReqs }) => {
    if (crewReqs.length === 0) {
      return state;
    }
    const jobPlanId = crewReqs[0].jobPlan;
    const newIds = crewReqs.map(er => er.id);

    const idsToKeep = state.ids.filter(
      id => state.entities[id].jobPlan !== jobPlanId
    );

    const entitiesToKeep = idsToKeep.reduce((obj, id) => {
      obj[id] = state.entities[id];
      return obj;
    }, {});
    const newEntities = crewReqs.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});
    state = {
      ...state,
      ids: [...idsToKeep, ...newIds],
      entities: {
        ...entitiesToKeep,
        ...newEntities,
      },
    };
    return state;
  })
);

export function jobPlanCrewReqReducer(state, action) {
  return _jobPlanCrewReqReducer(state, action);
}
