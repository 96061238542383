import { MaterialCategory } from './material-category.model';
import { MaterialVendor } from './material-vendor.model';
import { User } from './user.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobMaterial' })
export class JobMaterial {
  @Key id?: string;
  job: string;
  extraFieldLog: string;
  employee: string;
  vendor: number;
  category: number;
  description: string;
  created?: string;
  date: string;
}

export interface JobMaterialVM {
  id?: string;
  job: string;
  extraFieldLog: string;
  employee: User;
  vendor: MaterialVendor;
  category: MaterialCategory;
  description: string;
  created?: Date;
  date: Date;
}
