import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import {
  Trucking,
  TruckingEquipLocation,
  UserTimecard,
  EquipmentMachine,
  TruckingMove,
} from '@app/models';
import { HttpClient } from '@angular/common/http';

interface CompletedMoveDestination {
  destType: string;
  number: string;
  name: string;
  address: string;
  city: string;
  state: string;
  lat: number;
  lng: number;
}

export interface MovedEquipment {
  id: string;
  employee: string;
  destinationJob: string;
  equip: number;
  status: string;
  pickup: string;
  dropoff: string;
  departingStatus: string;
  moveTime: string;
  moveType: string;
}

export interface CompletedMove {
  id: string;
  billable: boolean;
  billTo: string;
  minutes: number;
  hourlyRate: string;
  notes: string;
  untrackedDate: string;
  untrackedEmployee: string;
  exported: boolean;
  destination: CompletedMoveDestination;
  movedEquipment: MovedEquipment[];
}

export interface UpdateMoveBillingPayload {
  id: string;
  billable: boolean;
  hourlyRate: string;
}

export interface CompletedMoveResponse {
  count: number;
  next: string;
  previous: string;
  results: CompletedMove[];
}

export interface UntrackedMoveTime {
  id?: string;
  billable: boolean;
  billTo: string;
  minutes?: number;
  hourlyRate: string;
  notes: string;
  untrackedDate: string;
  untrackedEmployee: string;
}

export interface TruckingEquipLocResponse {
  jobEquipment: TruckingEquipLocation[];
  jobEquipmentMoves: any[];
  missing: number[];
}

export interface CreateRentalPayload {
  equipment: EquipmentMachine;
  destination: {
    job?: string;
    tempDrop?: true;
    request?: string;
  };
}

export interface CreateRentalResponse {
  equipment: EquipmentMachine;
  truckingMove: TruckingMove;
}

export interface PlanRentalReturnPayload {
  equip: number;
}

export interface RentalReturnPayload {
  moves: string[];
  returnDate: string;
}

export interface RentalReturnResponse {
  completedMoves: TruckingMove[];
  returnedEquipment: EquipmentMachine[];
}

@Injectable({
  providedIn: 'root',
})
export class TruckingService {
  constructor(private http: HttpClient, private api: ApiService) {}

  loadAll(): Observable<Trucking> {
    return this.http.get<Trucking>(`${this.api.url}/trucking`);
  }

  loadLocations(): Observable<TruckingEquipLocResponse> {
    return this.http.get<TruckingEquipLocResponse>(
      `${this.api.url}/trucking/machines/locations`
    );
  }

  createTimecard(payload: UserTimecard): Observable<UserTimecard> {
    return this.http.post<UserTimecard>(
      `${this.api.url}/trucking/timecards`,
      payload
    );
  }

  updateTimecard(payload: UserTimecard): Observable<UserTimecard> {
    return this.http.patch<UserTimecard>(
      `${this.api.url}/trucking/timecards/${payload.id}`,
      payload
    );
  }

  createRental(payload: CreateRentalPayload): Observable<CreateRentalResponse> {
    return this.http.post<CreateRentalResponse>(
      `${this.api.url}/trucking/rentals`,
      payload
    );
  }

  planRentalReturn(payload: PlanRentalReturnPayload): Observable<TruckingMove> {
    return this.http.put<TruckingMove>(
      `${this.api.url}/trucking/rentals/plan-return`,
      payload
    );
  }

  returnRental(payload: RentalReturnPayload): Observable<RentalReturnResponse> {
    return this.http.put<RentalReturnResponse>(
      `${this.api.url}/trucking/rentals/return`,
      payload
    );
  }

  completedMoves(offset = 0, limit = 20) {
    return this.http.get<CompletedMoveResponse>(
      `${this.api.url}/trucking/moves/complete`,
      {
        params: { limit: `${limit}`, offset: `${offset}` },
      }
    );
  }

  updateCompletedMoveBilling(
    payload: UpdateMoveBillingPayload
  ): Observable<CompletedMove> {
    return this.http.put<CompletedMove>(
      `${this.api.url}/trucking/moves/complete/${payload.id}`,
      payload
    );
  }

  createUntrackedMove(payload: UntrackedMoveTime) {
    return this.http.post<UntrackedMoveTime>(
      `${this.api.url}/trucking/untracked`,
      payload
    );
  }

  truckingMove(id: string): Observable<TruckingMove> {
    return this.http.get<TruckingMove>(`${this.api.url}/trucking/moves/${id}`);
  }
}
