import { Entity, Key } from '@briebug/ngrx-auto-entity';

export interface EquipmentUse {
  inUse: number;
  tentative: number;
  rentals: number;
  machines: number;
}

@Entity({ modelName: 'EquipmentUseDate' })
export class EquipmentUseDate {
  @Key date?: string;
  equip: { [key: number]: EquipmentUse };
}
