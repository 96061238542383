import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ClientIdService {
  private _clientId = '';
  constructor() {
    this._clientId = uuidv4();
  }

  get clientId() {
    return this._clientId;
  }
}
