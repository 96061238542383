import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Job } from '@app/models';
import { createAction, createReducer, on, props } from '@ngrx/store';

// Action to replace job entity, not by id, but
// by its job number.
export const jobReplaceByNumber = createAction(
  '[Job]: Job Replace by job number',
  props<{ job: Job }>()
);

export const {
  initialState: jobInitialState,
  selectors: jobSelectors,
  facade: JobFacadeBase,
} = buildState(Job);

const _jobReducer = createReducer(
  jobInitialState,
  on(jobReplaceByNumber, (state, { job }) => {
    if (!job) {
      return state;
    }
    const existingId = state.ids.find(
      id => state.entities[id].jobNumber === job.jobNumber
    );
    const idsToKeep = state.ids.filter(id => id !== existingId);
    const entitiesToKeep = idsToKeep.reduce((obj, id) => {
      obj[id] = state.entities[id];
      return obj;
    }, {});
    entitiesToKeep[job.id] = job;

    state = {
      ...state,
      ids: [...idsToKeep, job.id],
      entities: {
        ...entitiesToKeep,
      },
    };
    return state;
  })
);

export function jobReducer(state, action) {
  return _jobReducer(state, action);
}
