import { parseISO } from 'date-fns';
import { createSelector } from '@ngrx/store';
import { selectJobDateState } from './job-date.selectors';
import { selectJobDateEquipVMAll } from '../job-date-equipment.join-selectors';
import { selectJobDateCrewVMAll } from '../job-date-crew.join-selectors';
import { selectDetailedJobVM } from '../job.join-selectors';
import { jobDateEquipTimecardSelectors } from '../job-date-equip-timecard.state';
import { jobDateEquipOptTimecardSelectors } from '../job-date-equip-opt-timecard.state';
import { jobDateUntrackedEquipTimecardSelectors } from '../job-date-untracked-equip-tc.state';

export const selectJobDateVM = createSelector(
  selectJobDateState,
  selectJobDateEquipVMAll,
  selectJobDateCrewVMAll,
  selectDetailedJobVM,
  (jobDateState, equipment, crews, detailedJob) => {
    return {
      loaded: jobDateState.loaded,
      date:
        jobDateState && jobDateState.jobDate
          ? parseISO(jobDateState.jobDate.date)
          : null,
      job: detailedJob,
      siteEquipment: equipment,
      siteCrews: crews,
    };
  }
);

export const selectAllEqupTime = createSelector(
  jobDateEquipTimecardSelectors.selectAll,
  jobDateEquipOptTimecardSelectors.selectAll,
  jobDateUntrackedEquipTimecardSelectors.selectAll,
  (equip, opts, unt) => {
    return {
      equipmentTimecards: equip,
      equipmentOptTimecards: opts,
      equipmentUntrackedTimecards: unt,
    };
  }
);
