import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlanCrewAssignment } from '@app/models';

export const {
  initialState: jobPlanCrewAssignmentInitialState,
  selectors: jobPlanCrewAssignmentSelectors,
  facade: JobPlanCrewAssignmentFacadeBase,
} = buildState(JobPlanCrewAssignment);

export function jobPlanCrewAssignmentReducer(
  state = jobPlanCrewAssignmentInitialState
): IEntityState<JobPlanCrewAssignment> {
  return state;
}
