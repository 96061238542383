import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppState, FormStatusFacadeBase } from '@app/state';
import { Store, select } from '@ngrx/store';
import { FormStatus } from '@app/models';
import {
  selectFormStatusByEntityType,
  FormStatusByEntityType,
  FormStatusByEntityId,
} from '@app/state/form-status.join-selectors';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FormStatusFacade extends FormStatusFacadeBase {
  constructor(private store: Store<AppState>) {
    super(FormStatus, store);
  }

  get byEntityType$(): Observable<FormStatusByEntityType> {
    return this.store.pipe(select(selectFormStatusByEntityType));
  }

  get documentUploads(): Observable<FormStatusByEntityId> {
    return this.byEntityType$.pipe(
      map(byType => {
        if ('JobDocument' in byType) {
          return byType.JobMedia;
        } else {
          return {};
        }
      })
    );
  }

  get mediaUploads$(): Observable<FormStatusByEntityId> {
    return this.byEntityType$.pipe(
      map(byType => {
        if ('JobMedia' in byType) {
          return byType.JobMedia;
        } else {
          return {};
        }
      })
    );
  }
}
