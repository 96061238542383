import { Injectable } from '@angular/core';
import {
  ContractExtra,
  ContractExtraFieldLog,
  ContractExtraTotalTime,
  Job,
  JobDateEquipOptTimecard,
  JobDateEquipTimecard,
  JobMaterial,
  JobSubcontractor,
} from '@app/models';
import {
  CreateSuccess,
  DeleteByKeySuccess,
  LoadAllSuccess,
  LoadManySuccess,
  LoadSuccess,
  UpdateSuccess,
} from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, props, Store } from '@ngrx/store';
import { ContractExtraService } from '@services/api/contract-extra.service';
import { exhaustMap, map } from 'rxjs/operators';
import { AppState } from '.';

export const loadContractExtras = createAction('[Contract Extras] Load');
export const createContractExtra = createAction(
  '[Contract Extras] Create',
  props<{ payload: ContractExtra }>()
);
export const loadJobContractExtras = createAction(
  '[Contract Extras] Job Load',
  props<{ payload: string }>()
);
export const loadDetailedExtra = createAction(
  '[Contract Extras] Load Detailed Extra',
  props<{ payload: string }>()
);
export const assignExtra = createAction(
  '[Contract Extras] Assign Extra',
  props<{ payload: { extraId: string; assignTo: string } }>()
);

@Injectable()
export class ContractExtraEffects {
  loadContractExtras$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadContractExtras),
        exhaustMap(action =>
          this.contractExtrasService.loadAll().pipe(
            map(response => {
              this.store$.dispatch(
                new LoadAllSuccess(ContractExtra, response.contractExtras)
              );
              this.store$.dispatch(new LoadManySuccess(Job, response.jobs));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  loadJobContractExtras$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadJobContractExtras),
        exhaustMap(action =>
          this.contractExtrasService.loadByJob(action.payload).pipe(
            map(response => {
              this.store$.dispatch(
                new LoadManySuccess(ContractExtra, response.extras)
              );
              this.store$.dispatch(new LoadSuccess(Job, response.job));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  createContractExtra$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createContractExtra),
        exhaustMap(action =>
          this.contractExtrasService.create(action.payload).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateSuccess(ContractExtra, response.contractExtra)
              );
              this.store$.dispatch(new CreateSuccess(Job, response.job));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  assignExtra$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(assignExtra),
        exhaustMap(action =>
          this.contractExtrasService
            .assign(action.payload.extraId, action.payload.assignTo)
            .pipe(
              map(response => {
                this.store$.dispatch(
                  new LoadSuccess(ContractExtra, response.extra)
                );
                this.store$.dispatch(
                  new LoadManySuccess(ContractExtraFieldLog, response.fieldLogs)
                );
                this.store$.dispatch(
                  new DeleteByKeySuccess(ContractExtra, response.deletedExtraId)
                );
              })
            )
        )
      ),
    { dispatch: false }
  );

  loadDetailedExtra$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadDetailedExtra),
        exhaustMap(action =>
          this.contractExtrasService.loadExtraDetails(action.payload).pipe(
            map(response => {
              this.store$.dispatch(
                new LoadSuccess(ContractExtra, response.extra)
              );
              this.store$.dispatch(
                new LoadManySuccess(ContractExtraFieldLog, response.fieldLogs)
              );
              this.store$.dispatch(
                new LoadManySuccess(ContractExtraTotalTime, response.totalTime)
              );
              this.store$.dispatch(
                new LoadManySuccess(JobDateEquipTimecard, response.equip)
              );
              this.store$.dispatch(
                new LoadManySuccess(JobDateEquipOptTimecard, response.equipOpt)
              );
              this.store$.dispatch(
                new LoadManySuccess(JobMaterial, response.materials)
              );
              this.store$.dispatch(
                new LoadManySuccess(JobSubcontractor, response.subcontractors)
              );
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private contractExtrasService: ContractExtraService,
    private store$: Store<AppState>
  ) {}
}
