import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'FormStatus' })
export class FormStatus {
  @Key id?: string;
  fileSize: number;
  uploaded: number;
  message: string;
  error: boolean;
  entityType: string;
  entityId: string;
  data?: any;
}
