import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpBackend,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { JobDocument } from '@app/models';
import { filter, map, pairwise, take } from 'rxjs/operators';
import { S3UploadService, S3UploadPayload } from './s3-upload.service';
import { Update } from '@briebug/ngrx-auto-entity';
import { Store } from '@ngrx/store';
import { AppState, jobDocumentSelectors } from '@app/state';

export interface CreateJobDocumentPayload {
  jobDocument: JobDocument;
  file: File;
}

@Injectable({
  providedIn: 'root',
})
export class JobDocumentService {
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private s3Uploader: S3UploadService,
    private store$: Store<AppState>
  ) {}

  getLink(documentId: string): Observable<string> {
    return this.http
      .get<JobDocument>(`${this.api.url}/jobs/documents/${documentId}`)
      .pipe(map(result => result.document));
  }

  create(payload: JobDocument): Observable<JobDocument> {
    return this.http.post<JobDocument>(
      `${this.api.url}/jobs/documents`,
      payload
    );
  }

  uploadFile(payload: CreateJobDocumentPayload) {
    console.log(`Uploading to ${payload.jobDocument.uploadUrl}`);
    const s3Payload: S3UploadPayload = {
      url: payload.jobDocument.uploadUrl,
      file: payload.file,
      messages: {
        connected: 'Connected...',
        uploadProgress: `Uploading ${payload.file.name}...`,
        uploadProgressComplete: `Processing ${payload.file.name} on server...`,
      },
      entityType: 'JobDocument',
      entityId: payload.jobDocument.id,
      onComplete: () => {
        const jobDocument: JobDocument = {
          ...payload.jobDocument,
          fileExists: true,
        };
        this.store$.dispatch(new Update(JobDocument, jobDocument));
        return this.store$.select(jobDocumentSelectors.selectIsSaving).pipe(
          pairwise(),
          filter(([a, b]) => a === true && b === false),
          take(1)
        );
      },
    };
    return this.s3Uploader.upload(s3Payload);
  }

  continueUpload(id: string, file: File) {
    this.http
      .get<JobDocument>(`${this.api.url}/jobs/documents/${id}`)
      .subscribe(jobDocument => {
        this.uploadFile({
          jobDocument,
          file,
        });
      });
  }
}
