import { createReducer, Action, on } from '@ngrx/store';
import * as actions from './materials.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: null,
};

const materialsReducer = createReducer(
  initialState,
  on(actions.loadAllMaterials, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(actions.loadAllMaterialsSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return materialsReducer(state, action);
}
