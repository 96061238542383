import { JobRestorationMedia } from '@app/models';
import { map, concatMap } from 'rxjs/operators';
import { AppState } from '@app/state';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import {
  ImageUploadService,
  PhotoMeta,
} from '@services/image-upload/image-upload.service';
import { CreateSuccess, Update } from '@briebug/ngrx-auto-entity';
import {
  CreateJobRestorationMediaPayload,
  JobRestorationMediaService,
} from '@services/api/job-restoration-media.service';

export interface CreateJobRestorationMediaRequest {
  restorationTicket: string;
  employee: string;
  fileName: string;
  photo: PhotoMeta;
}

export const createJobRestorationMedia = createAction(
  '[Job Restoration Media] Create',
  props<{ payload: CreateJobRestorationMediaRequest }>()
);

export const uploadJobMedia = createAction(
  '[Job Incident Media] Upload',
  props<{ payload: CreateJobRestorationMediaPayload }>()
);

@Injectable()
export class JobRestorationMediaEffects {
  createRestorationMedia$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobRestorationMedia),
        map(action => action.payload),
        concatMap(payload => {
          const rm: JobRestorationMedia = {
            restorationTicket: payload.restorationTicket,
            employee: payload.employee,
            originalFileName: payload.fileName,
            imageExists: false,
          };
          return this.jobRestorationMediaService.create(rm).pipe(
            map(async response => {
              this.store$.dispatch(
                new CreateSuccess(JobRestorationMedia, response)
              );
              const photo = {
                ...payload.photo,
                id: response.id,
              };
              await this.imageUpload.addPhoto(photo);

              const newPayload: CreateJobRestorationMediaPayload = {
                jobRestorationMedia: response,
                image: await this.imageUpload.getPhotoFile(response.id),
              };
              this.store$.dispatch(uploadJobMedia({ payload: newPayload }));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          );
        })
      ),
    { dispatch: false }
  );

  uploadRestorationMedia$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadJobMedia),
        map(action => action.payload),
        concatMap(payload => {
          return this.jobRestorationMediaService.uploadFile(payload).pipe(
            map(completed => {
              if (completed) {
                const jobRestorationMedia: JobRestorationMedia = {
                  ...payload.jobRestorationMedia,
                  imageExists: true,
                };
                this.store$.dispatch(
                  new Update(JobRestorationMedia, jobRestorationMedia)
                );
              }
            })
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobRestorationMediaService: JobRestorationMediaService,
    private imageUpload: ImageUploadService,
    private store$: Store<AppState>
  ) {}
}
