import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-network-required',
  templateUrl: './network-required.page.html',
  styleUrls: ['./network-required.page.scss'],
})
export class NetworkRequiredPage implements OnInit {
  constructor() {}

  ngOnInit() {}
}
