import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { AppState } from '..';
import * as actions from './app-state.actions';

export interface State {
  activeBackgroundTaskId: string;
}

export const initialState: State = {
  activeBackgroundTaskId: null,
};

const appStateReducer = createReducer(
  initialState,
  on(actions.setBackgroundTaskId, (state, prop) => ({
    ...state,
    activeBackgroundTaskId: prop.activeBackgroundTaskId,
  })),
  on(actions.clearBackgroundTaskId, state => ({
    ...state,
    activeBackgroundTaskId: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return appStateReducer(state, action);
}

export const selectAppState = createFeatureSelector<State>('appState');
