import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { EquipmentTypeVM } from './equipment-type.model';

@Entity({ modelName: 'EquipmentClass' })
export class EquipmentClass {
  @Key id: number;
  name: string;
  delta: number;
}

export interface EquipmentClassVM {
  id: number;
  name: string;
  delta: number;
  equipmentTypes: EquipmentTypeVM[];
}
