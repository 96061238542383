import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { EquipmentOptionVM } from './equipment-option.model';

@Entity({ modelName: 'JobDateEquipOptTimecard' })
export class JobDateEquipOptTimecard {
  @Key id?: string;
  jeTimecard?: string;
  equipOption: number;
  minutes: number;
}

export interface JobDateEquipOptTimecardVM {
  id?: string;
  jeTimecard?: string;
  equipOption: EquipmentOptionVM;
  minutes: number;
  hours: number;
}
