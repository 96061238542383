import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { PhotoData } from '../camera-photos/camera-photos.service';
import { v4 as uuid } from 'uuid';

export interface PhotoSrcData {
  type: string;
  value: string | ArrayBuffer;
}

export interface PhotoMeta {
  id?: string;
  entityId?: string;
  deleteOnDestroy: boolean;
  file?: File;
  photoData?: PhotoData;
  src?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  photos: { [key: string]: PhotoMeta } = {};
  // tslint:disable-next-line:variable-name
  _currentUploads = new BehaviorSubject<{ [key: string]: PhotoMeta }>({});
  constructor() {}

  async addPhoto(photo: PhotoMeta) {
    let id = '';
    if ('id' in photo) {
      if (photo.file) {
        photo.src = (await this.readFile(photo)) as string;
      } else {
        photo.src = photo.photoData.webPath;
      }
      this.photos[photo.id] = photo;
      id = photo.id;
    } else {
      id = uuid();
      photo.id = id;
      this.photos[photo.id] = photo;
    }
    this._currentUploads.next(this.photos);
    return id;
  }

  get currentUploads$() {
    return this._currentUploads.asObservable();
  }

  getPhotoSrc(id: string): PhotoSrcData {
    const photo = this.photos[id];
    if (photo.photoData) {
      return {
        type: 'uri',
        value: photo.src,
      };
    } else {
      return {
        type: 'base64',
        value: photo.src,
      };
    }
  }

  protected async readFile(photo: PhotoMeta): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        resolve((e.target as FileReader).result);
      };

      reader.onerror = e => {
        console.error(`FileReader failed on file ${photo.file.name}.`);
        reject(e);
      };

      if (!photo.file) {
        return reject(
          'No file to read. Please provide a file using the [file] Input property.'
        );
      }

      reader.readAsDataURL(photo.file);
    });
  }

  async getPhotoFile(id: string): Promise<File> {
    const photo = this.photos[id];
    if (photo && 'file' in photo) {
      return photo.file;
    } else {
      const imageUri = photo.photoData.webPath;
      const blob = await fetch(imageUri).then(r => r.blob());
      const mimeType = 'image/jpeg';
      const filename = imageUri.substring(imageUri.lastIndexOf('/') + 1);
      return new File([blob], filename, { type: mimeType });
    }
  }
}
