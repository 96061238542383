import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlanManaged } from '@app/models';

export const {
  initialState: jobPlanManagedInitialState,
  selectors: jobPlanManagedSelectors,
  facade: JobPlanManagedFacadeBase,
} = buildState(JobPlanManaged);

export function jobPlanManagedReducer(
  state = jobPlanManagedInitialState
): IEntityState<JobPlanManaged> {
  return state;
}
