import { JobPlanService } from '@services/api/job-plan.service';
import { AppState } from './app.state';
import { Store, createAction, props } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  JobPlanCrewAssignment,
  Crew,
  JobPlanCrewRequirement,
  JobPlan,
} from '@app/models';
import { map, exhaustMap } from 'rxjs/operators';
import {
  CreateSuccess,
  CreateManySuccess,
  LoadSuccess,
  LoadManySuccess,
} from '@briebug/ngrx-auto-entity';

export interface DeleteAssignedPayload {
  crewReq: JobPlanCrewRequirement;
  crewAssign: JobPlanCrewAssignment;
}

export const createJobPlanCrewAssignment = createAction(
  '[Job Plan Crew Assignment] Create Crew Assignment',
  props<{ payload: JobPlanCrewAssignment }>()
);

export const createJobPlanCrewAssignments = createAction(
  '[Job Plan Crew Assignment] Create Crew Assignments',
  props<{ payload: JobPlanCrewAssignment[] }>()
);

export const deleteCrewAssignment = createAction(
  '[Job Plan Crew Assignment], Delete Crew Assignment',
  props<{ payload: DeleteAssignedPayload }>()
);

@Injectable()
export class JobPlanCrewAssignmentEffects {
  createJobPlanCrewAssign$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobPlanCrewAssignment),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.createCrewAssignment(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateSuccess(
                  JobPlanCrewAssignment,
                  response.crewAssignment
                )
              );
              this.store$.dispatch(new LoadSuccess(Crew, response.crew));
              this.store$.dispatch(
                new LoadSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  createJobPlansCrewAssign$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobPlanCrewAssignments),
        map(action => action.payload),
        exhaustMap(payload =>
          this.jobPlanService.createManyCrewAssignments(payload).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateManySuccess(
                  JobPlanCrewAssignment,
                  response.crewAssignments
                )
              );
              this.store$.dispatch(new LoadManySuccess(Crew, response.crews));
              this.store$.dispatch(
                new LoadSuccess(JobPlan, {
                  ...response.jobPlan,
                  details: 'loaded',
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobPlanService: JobPlanService,
    private store$: Store<AppState>
  ) {}
}
