import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { EquipmentOptionType } from '@app/models';

export const {
  initialState: equipOptTypeInitialState,
  selectors: equipOptTypeSelectors,
  facade: EquipOptTypeFacadeBase,
} = buildState(EquipmentOptionType);

export function equipOptTypeReducer(
  state = equipOptTypeInitialState
): IEntityState<EquipmentOptionType> {
  return state;
}
