import { createSelector } from '@ngrx/store';
import { equipOptionSelectors } from './equipment-option.state';
import { equipOptTypeSelectors } from './equipment-option-type.state';
import { EquipmentOptionVM } from '@app/models';

export const selectEquipOptVMAll = createSelector(
  equipOptionSelectors.selectAll,
  equipOptTypeSelectors.selectEntities,
  (opts, optTypes): EquipmentOptionVM[] => {
    return opts.map(opt => ({
      ...opt,
      optionType: opt.optionType in optTypes ? optTypes[opt.optionType] : null,
    }));
  }
);

export const selectEquipOptVMEntities = createSelector(
  selectEquipOptVMAll,
  opts => {
    const result: { [key: number]: EquipmentOptionVM } = {};
    opts.forEach(opt => {
      result[opt.id] = opt;
    });
    return result;
  }
);

export const selectEquipOptVMByEquip = createSelector(
  selectEquipOptVMAll,
  opts => {
    const result: { [key: number]: EquipmentOptionVM[] } = {};
    opts.forEach(opt => {
      if (!(opt.equipment in result)) {
        result[opt.equipment] = [];
      }
      result[opt.equipment].push(opt);
    });
    return result;
  }
);
