import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlanEquipReq } from '@app/models';
import { createAction, createReducer, on, props, Store } from '@ngrx/store';

export const jobPlanUpdateEquipReq = createAction(
  '[JobPlanEquipReq]: Job Plan Update Equip Req',
  props<{ equipReqs: JobPlanEquipReq[] }>()
);

export const {
  initialState: jobPlanEquipReqInitialState,
  selectors: jobPlanEquipReqSelectors,
  facade: JobPlanEquipReqFacadeBase,
} = buildState(JobPlanEquipReq);

const _jobPlanEquipReqReducer = createReducer(
  jobPlanEquipReqInitialState,
  on(jobPlanUpdateEquipReq, (state, { equipReqs }) => {
    if (equipReqs.length === 0) {
      return state;
    }
    const jobPlanId = equipReqs[0].jobPlan;
    const newIds = equipReqs.map(er => er.id);

    const idsToKeep = state.ids.filter(
      id =>
        !newIds.includes(id as string) &&
        state.entities[id].jobPlan !== jobPlanId
    );

    const entitiesToKeep = idsToKeep.reduce((obj, id) => {
      obj[id] = state.entities[id];
      return obj;
    }, {});
    const newEntities = equipReqs.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});
    state = {
      ...state,
      ids: [...idsToKeep, ...newIds],
      entities: {
        ...entitiesToKeep,
        ...newEntities,
      },
    };
    return state;
  })
);

export function jobPlanEquipReqReducer(state, action) {
  return _jobPlanEquipReqReducer(state, action);
}
