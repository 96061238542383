import { parseISO } from 'date-fns';
import { JobRestorationMediaVM } from '@app/models';
import { createSelector } from '@ngrx/store';
import { userSelectors } from './user.state';
import { jobRestorationMediaSelectors } from '.';

export const selectJobRestorationMediaVMAll = createSelector(
  jobRestorationMediaSelectors.selectAll,
  userSelectors.selectEntities,
  (restorationMedia, users): JobRestorationMediaVM[] => {
    return restorationMedia.map(
      (media): JobRestorationMediaVM => {
        return {
          ...media,
          thumbnails: media.thumbnails ? { ...media.thumbnails } : null,
          employee: media.employee in users ? users[media.employee] : null,
          created: media.created ? parseISO(media.created) : null,
        };
      }
    );
  }
);

export const selectRestorationMediaVMByTicket = createSelector(
  selectJobRestorationMediaVMAll,
  ticketMedia => {
    const result: { [key: string]: JobRestorationMediaVM[] } = {};
    ticketMedia.forEach(media => {
      if (!(media.restorationTicket in result)) {
        result[media.restorationTicket] = [];
      }
      result[media.restorationTicket].push(media);
    });
    return result;
  }
);
