import { selectEquipOptVMByEquip } from './equipment-option.join-selectors';
import { EquipmentMachineVM } from './../models/equipment-machine.model';
import { createSelector } from '@ngrx/store';
import { equipMachineSelectors } from './equipment-machine.state';
import { equipOptionSelectors } from './equipment-option.state';
import { equipModelSelectors } from './equipment-model.state';
import { equipTypeSelectors } from './equipment-type.state';
import {
  EquipmentMachineFlat,
  JobEquipment,
  EquipmentMachinesLocation,
} from '@app/models';
import { jobSelectors } from './job.state';
import { jobEquipmentSelectors } from './job-equipment.state';
import { selectFilteredEquip } from './equipment/equipment.selectors';

export const currentEquipMachineOpts = createSelector(
  equipMachineSelectors.selectCurrentEntityKey,
  equipOptionSelectors.selectAll,
  (currentMachineId, equipOpts) => {
    if (!currentMachineId) {
      return [];
    }
    return equipOpts.filter(eo => eo.equipment === currentMachineId);
  }
);

export const equipmentFlatSelector = createSelector(
  equipMachineSelectors.selectAll,
  equipModelSelectors.selectEntities,
  equipTypeSelectors.selectEntities,
  jobEquipmentSelectors.selectAll,
  (machines, models, types, jobEquip): EquipmentMachineFlat[] => {
    const jobEquipEntities: { [key: number]: JobEquipment } = {};
    jobEquip.forEach(je => (jobEquipEntities[je.equip] = je));
    return machines.map(machine => ({
      machine,
      rental: machine.rental,
      makeModel: !machine.rental ? models[machine.makeModel] : null,
      equipType: !machine.rental
        ? types[models[machine.makeModel].equipType]
        : types[machine.rentalType],
      // TODO: ADD OPTIONS
      options: [],
      jobEquipment:
        machine.id in jobEquipEntities ? jobEquipEntities[machine.id] : null,
    }));
  }
);

export const equipmentFlatEntitiesSelector = createSelector(
  equipmentFlatSelector,
  machines => {
    const equipEntitites: { [key: number]: EquipmentMachineFlat } = {};
    machines.forEach(machine => {
      equipEntitites[machine.machine.id] = machine;
    });
    return equipEntitites;
  }
);

export const equipFlatByLocTypeSelector = createSelector(
  equipmentFlatSelector,
  jobSelectors.selectEntities,
  (machines, jobs) => {
    const result: { [key: number]: EquipmentMachinesLocation[] } = {};
    for (const machine of machines) {
      const typeId = machine.equipType.id;
      const jobId = machine.jobEquipment ? machine.jobEquipment.job : null;
      if (!jobId) {
        continue;
      }
      if (!(typeId in result)) {
        result[typeId] = [];
      }
      let loc = result[typeId].find(eml =>
        eml ? false : eml.job.id === jobId
      );
      if (!loc) {
        loc = { job: jobs[jobId], machines: [] };
        result[typeId].push(loc);
      }
      loc.machines.push(machine);
    }
    // TODO: ORDER BY STATUS
    return result;
  }
);

export const filteredEquipByLocTypeSelector = createSelector(
  equipmentFlatSelector,
  jobSelectors.selectEntities,
  selectFilteredEquip,
  (equip, jobs, filters) => {
    const filteredType = filters.equipType;
    if (filteredType === null || filteredType === undefined) {
      return [];
    }
    const filteredEquip = equip.filter(e => e.equipType.id === filteredType);
    // TODO: Filter by option
    const res: EquipmentMachinesLocation[] = [];

    filteredEquip.forEach(machine => {
      const jobId = machine.jobEquipment ? machine.jobEquipment.job : null;
      if (jobId) {
        let loc = res.find(eml => (eml ? false : eml.job.id === jobId));
        if (!loc) {
          loc = { job: jobs[jobId], machines: [] };
          res.push(loc);
        }
        loc.machines.push(machine);
      }
    });
    return res;
  }
);

export const equipMachineVMAllSelector = createSelector(
  equipMachineSelectors.selectAll,
  equipModelSelectors.selectEntities,
  equipTypeSelectors.selectEntities,
  selectEquipOptVMByEquip,
  (machines, models, types, opts): EquipmentMachineVM[] => {
    return machines.map(
      (machine): EquipmentMachineVM => {
        const model =
          machine.makeModel in models ? models[machine.makeModel] : null;
        const machineType =
          model && model.equipType in types ? types[model.equipType] : null;
        return {
          ...machine,
          equipType: machineType,
          makeModel: model,
          options: machine.id in opts ? opts[machine.id] : [],
        };
      }
    );
  }
);

export const equipMachineVMEntitySelector = createSelector(
  equipMachineVMAllSelector,
  machines => {
    const result: { [key: string]: EquipmentMachineVM } = {};
    machines.forEach(machine => {
      result[machine.id] = machine;
    });
    return result;
  }
);
