import { UserVM, EquipmentMachine } from '@app/models';
import { Key, Entity } from '@briebug/ngrx-auto-entity';

export interface JobPlanEquipmentDate {
  date: string;
  employee: string;
  status: string;
}

export interface JobPlanEquipmentDateVM {
  date: Date;
  employee: UserVM;
  status: string;
}

@Entity({ modelName: 'JobPlanSiteEquipment' })
export class JobPlanSiteEquipment {
  @Key id: string;
  job: string;
  equip: number;
  equipRequirement: string;
  status: string;
  arrival: JobPlanEquipmentDate;
  departure: JobPlanEquipmentDate;
}

export class JobPlanEquipmentVM {
  id: string;
  job: string;
  equip: EquipmentMachine;
  equipRequirement: string;
  status: string;
  arrival: JobPlanEquipmentDateVM;
  departure: JobPlanEquipmentDateVM;
}
