import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { ContractExtraFieldLogVM } from './contract-extra-field-log.model';
import { Job } from './job.model';

@Entity({ modelName: 'ContractExtra' })
export class ContractExtra {
  @Key id?: string;
  job: string;
  name: string;
  description: string;
  assigned: boolean;
  complete: boolean;
  created?: string;
}

export interface ContractExtraVM {
  id?: string;
  job: Job;
  name: string;
  description: string;
  assigned: boolean;
  complete: boolean;
  created?: Date;
}

export interface ContractExtraFullVM extends ContractExtraVM {
  fieldLogs: ContractExtraFieldLogVM[];
}
