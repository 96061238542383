import { JobPlanLinkedEquipVM } from './job-plan-linked-equip.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { Job } from './job.model';
import {
  JobPlanCrewRequirementVM,
  JobPlanCrewRequirement,
} from './job-plan-crew-requirement.model';
import { JobPlanCrewAssignment } from './job-plan-crew-assignment.model';
import { format } from 'date-fns';
import { JobPlanEquipReqVM } from './job-plan-equip-req.model';
import { JobLocate } from './job-locate.model';
import { JobPlanTentativeVM } from './job-plan-tentative.model';
import { EmergencyTicket } from './emergency-ticket.model';
import { JobDateNote } from './job-date-note.model';
import { JobPlanAlarm, JobPlanAlarmVM } from '.';

export const jobPlanVMToJP = (jp: JobPlanVM): JobPlan => {
  return {
    ...jp,
    job: jp.job.id,
    start: jp.start ? format(jp.start, 'yyyy-MM-dd') : null,
    end: jp.end ? format(jp.end, 'yyyy-MM-dd') : null,
    completedDate: jp.completedDate
      ? format(jp.completedDate, 'yyyy-MM-dd')
      : null,
    datesStatus: jp.datesStatus.map(ds => ({
      ...ds,
      date: format(ds.date, 'yyyy-MM-dd'),
    })),
  };
};

@Entity({ modelName: 'JobPlan' })
export class JobPlan {
  @Key id?: string;
  job: string;
  trackEstimates: boolean;
  laborEstimate?: number;
  equipEstimate?: number;
  materialsEstimate?: number;
  waterPct: number;
  sewerPct: number;
  hourlyPct: number;
  locateRequired: number;
  start: string;
  end: string;
  completed?: boolean;
  completedDate?: string;
  details?: string;
  datesStatus?: { date: string; status: string; equipStatus: string }[];
  locateStatus?: string;
  onHold: boolean;
  canDelete: boolean;
  unreqEquip: boolean;
  noteCount: number;
}

export interface JobPlanVM {
  id?: string;
  job: Job;
  trackEstimates: boolean;
  laborEstimate?: number;
  equipEstimate?: number;
  materialsEstimate?: number;
  waterPct: number;
  sewerPct: number;
  hourlyPct: number;
  locateRequired: number;
  start: Date;
  end: Date;
  completed?: boolean;
  completedDate?: Date;
  details?: string;
  datesStatus?: { date: Date; status: string; equipStatus: string }[];
  tentative: JobPlanTentativeVM[];
  locateStatus?: string;
  emergencyTicket: EmergencyTicket;
  onHold: boolean;
  canDelete: boolean;
  unreqEquip: boolean;
  noteCount: number;
  alertCount: number;
  alarms: JobPlanAlarm[];
  dateNotes: JobDateNote[];
}

export interface JobPlanDetailsVM {
  crewRequirements: JobPlanCrewRequirementVM[];
  equipRequirements: JobPlanEquipReqVM[];
  linkedEquipment: JobPlanLinkedEquipVM[];
  locates: JobLocate[];
}

export interface CreateCrewReqPayload {
  crewReq: JobPlanCrewRequirement;
  crewAssignments: JobPlanCrewAssignment[];
}

export interface OverUsedEquipment {
  equipType: string;
  available: number;
  requests: string[];
  linked: string[];
  tentative: string[];
}
