import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'UserTimecard' })
export class UserTimecard {
  @Key id?: string;
  user: string;
  timecardType: string;
  clockIn: string;
  clockOut?: string;
  breakMinutes?: number;
  skipMorningBreak?: boolean;
  skipAfternoonBreak?: boolean;
  clockInActual: string;
  clockOutActual?: string;
  clockInLat: number;
  clockInLng: number;
  clockOutLat?: number;
  clockOutLng?: number;
  status?: string;
  date?: string;
  job?: string;
}
