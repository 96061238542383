import { User } from './user.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobIncident' })
export class JobIncident {
  @Key id?: string;
  job: string;
  title: string;
  employee: string;
  description: string;
  created?: string;
}

export interface JobIncidentVM {
  id?: string;
  job: string;
  title: string;
  employee: User;
  description: string;
  created?: Date;
}
