import { AppState } from '@app/state';
import { createReducer, Action, on, createFeatureSelector } from '@ngrx/store';
import * as actions from './job-planner.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: any;
  startDate: string;
  endDate: string;
  hide: {
    onHold: boolean;
    completed: boolean;
  };
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: null,
  startDate: null,
  endDate: null,
  hide: {
    onHold: false,
    completed: true,
  },
};

const jobPlannerReducer = createReducer(
  initialState,
  on(actions.loadJobPlanner, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
    startDate: null,
    endDate: null,
  })),
  on(actions.loadJobPlannerSuccess, (state, { dateRange }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    startDate: dateRange.minDate,
    endDate: dateRange.maxDate,
  })),
  on(actions.updateJobPlannerDates, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
    startDate: null,
    endDate: null,
  })),
  on(actions.updateJobPlannerDatesSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    startDate: payload.minDate,
    endDate: payload.maxDate,
  })),
  on(actions.setViewOptions, (state, { payload }) => ({
    ...state,
    hide: payload,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return jobPlannerReducer(state, action);
}

export const selectJobPlanner = createFeatureSelector<State>('jobPlanner');
