import { createAction, props } from '@ngrx/store';

export const loadAllMaterials = createAction('[Materials] Load All');
export const loadAllMaterialsSuccess = createAction(
  '[Materials API] Load All Success'
);
export const loadAllMaterialsError = createAction(
  '[Materials API] Load All Error',
  props<{ error: any }>()
);
