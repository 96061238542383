import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import {
  Job,
  JobNote,
  JobMedia,
  JobDocument,
  JobLocate,
  JobIncident,
  JobIncidentMedia,
  JobMaterial,
  JobSubcontractor,
  JobRestoration,
  JobSiteEquipment,
  JobPlan,
  JobExtra,
  JobPlanEquipReq,
  JobPlanNote,
  JobPlanAlarm,
  JobRestorationMedia,
} from '@app/models';
import { HttpClient } from '@angular/common/http';
import { JobAlert } from '@app/models/job-alert.model';
import { ResizeSummaryFormPayload } from '@components/job-planner/shared/forms/resize-summary-modal/resize-summary-modal.component';
import { JobPastEquipment } from '@app/models/job-past-equipment.model';

export interface JobDetails {
  job: Job;
  jobPlan: JobPlan;
  notes: JobNote[];
  alerts: JobAlert[];
  media: JobMedia[];
  documents: JobDocument[];
  locates: JobLocate[];
  incidents: JobIncident[];
  incidentMedia: JobIncidentMedia[];
  materials: JobMaterial[];
  subcontractors: JobSubcontractor[];
  extras: JobExtra[];
  restoration: JobRestoration[];
  restorationMedia: JobRestorationMedia[];
  siteEquipment: JobSiteEquipment[];
  pastEquipment: JobPastEquipment[];
}

export interface JobJobPlanDetails {
  jobPlan: JobPlan;
  managers: { id: string; manager: string }[];
  notes: JobPlanNote[];
  alarms: JobPlanAlarm[];
}

export interface JobRestorationResponse {
  tickets: JobRestoration[];
  jobs: Job[];
  media: JobRestorationMedia[];
}

export interface UnassignedEquip {
  id: string;
  machine: string;
}

export interface ActivateLocateRequest {
  locateId: string;
  payload: {
    activationTime: string;
  };
}

export interface PlanSelfServeRequest {
  equipReqId: string;
  payload: {
    equip: number;
  };
}

export interface LoadSelfServeRequest {
  equipReqId: string;
  payload: {
    pickupTime: string;
  };
}

export interface UnloadSelfServeRequest {
  equipReqId: string;
  payload: {
    dropoffTime: string;
    pickupTime?: string;
  };
}

export interface SelfServeReturnShopRequest {
  destination: string;
  truncateRelease: boolean;
  jobEquipment: string;
}

export interface SelfServeReturnShopResponse {
  delete: string;
  update: JobSiteEquipment;
}

export interface LinkEquipmentRequest {
  jobId: string;
  payload: {
    jobEquip: string;
  };
}

export interface LinkEquipmentResponse {
  jobPlan: JobPlan;
  equipReq: JobPlanEquipReq;
}

export interface JDSEquipReq {
  requirement: string;
  equip: string;
}

export interface JDSCrewMember {
  employee: string;
  foreman: boolean;
  vacation: boolean;
}

export interface JDSCrewReq {
  crewReq: string;
  crew: JDSCrewMember[];
  startTime: string;
}

export interface JobDateSummaryResponse {
  date: string;
  equipReqs: JDSEquipReq[];
  otherEquip: any[];
  crewReqs: JDSCrewReq[];
  vacation: string[];
}

export interface JobSearchDistance {
  id: string;
  jobNumber: string;
  name: string;
  address: string;
  city: string;
  state: string;
  lat: number;
  lng: number;
  distance: number;
  jobplan: string;
}

export interface JobSearchDistanceResponse {
  count: number;
  jobs: JobSearchDistance[];
}

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(private http: HttpClient, private api: ApiService) {}

  info(job: string): Observable<Job> {
    return this.http.get<Job>(`${this.api.url}/jobs/${job}`);
  }

  details(job: string): Observable<JobDetails> {
    return this.http.get<JobDetails>(`${this.api.url}/jobs/${job}/details`);
  }

  summaryForwardData(
    jobPlanId: string,
    date: string
  ): Observable<ResizeSummaryFormPayload> {
    const params = { original_date: date };
    return this.http.get<ResizeSummaryFormPayload>(
      `${this.api.url}/job-plans/${jobPlanId}/summary-extend`,
      { params }
    );
  }

  summaryForwardDataUpdate(
    jobPlanId: string,
    payload: ResizeSummaryFormPayload
  ): Observable<any> {
    return this.http.patch<any>(
      `${this.api.url}/job-plans/${jobPlanId}/summary-extend`,
      payload
    );
  }

  updateLatLng(
    job: string,
    location: { lat: number; lng: number }
  ): Observable<Job> {
    return this.http.put<Job>(`${this.api.url}/jobs/${job}/lat-lng`, location);
  }

  returnToShop(
    payload: SelfServeReturnShopRequest
  ): Observable<SelfServeReturnShopResponse> {
    return this.http.post<SelfServeReturnShopResponse>(
      `${this.api.url}/trucking/self-serve/return-to-shop`,
      payload
    );
  }

  activateLocate(request: ActivateLocateRequest): Observable<JobLocate> {
    return this.http.put<JobLocate>(
      `${this.api.url}/jobs/locates/${request.locateId}/activate`,
      request.payload
    );
  }

  planSelfServe(request: PlanSelfServeRequest): Observable<JobSiteEquipment> {
    return this.http.put<JobSiteEquipment>(
      `${this.api.url}/jobs/site-equipment/${request.equipReqId}/plan`,
      request.payload
    );
  }

  loadSelfServe(request: LoadSelfServeRequest): Observable<JobSiteEquipment> {
    return this.http.put<JobSiteEquipment>(
      `${this.api.url}/jobs/site-equipment/${request.equipReqId}/load`,
      request.payload
    );
  }

  unloadSelfServe(
    request: UnloadSelfServeRequest
  ): Observable<JobSiteEquipment> {
    return this.http.put<JobSiteEquipment>(
      `${this.api.url}/jobs/site-equipment/${request.equipReqId}/unload`,
      request.payload
    );
  }

  unassignedEquipment(id: string): Observable<UnassignedEquip[]> {
    return this.http.get<UnassignedEquip[]>(
      `${this.api.url}/jobs/${id}/unreqested-equipment`
    );
  }

  linkEquipment(
    request: LinkEquipmentRequest
  ): Observable<LinkEquipmentResponse> {
    return this.http.patch<LinkEquipmentResponse>(
      `${this.api.url}/jobs/${request.jobId}/link-equipment`,
      request.payload
    );
  }

  jobPlanDetails(id: string): Observable<JobJobPlanDetails> {
    return this.http.get<JobJobPlanDetails>(
      `${this.api.url}/jobs/${id}/job-plan`
    );
  }

  updateJobPlan(entity: JobPlan): Observable<JobPlan> {
    return this.http.put<JobPlan>(
      `${this.api.url}/job-plans/${entity.id}`,
      entity
    );
  }

  jobDateSummary(job: string, date: string) {
    const params = { date };
    return this.http.get<JobDateSummaryResponse>(
      `${this.api.url}/jobs/${job}/date/summary`,
      { params }
    );
  }

  nearbyJobs(job: string) {
    return this.http.get<JobSearchDistanceResponse>(
      `${this.api.url}/jobs/${job}/nearby`
    );
  }

  createAlarm(alarm: JobPlanAlarm) {
    return this.http.post<JobPlanAlarm>(
      `${this.api.url}/job-plans/alarms`,
      alarm
    );
  }

  deleteAlarm(alarm: JobPlanAlarm) {
    return this.http.delete(`${this.api.url}/job-plans/alarms/${alarm.id}`);
  }

  updateAlaram(alarm: JobPlanAlarm) {
    return this.http.put(`${this.api.url}/job-plans/alarms/${alarm.id}`, alarm);
  }

  loadActiveRestoration(): Observable<JobRestorationResponse> {
    return this.http.get<JobRestorationResponse>(
      `${this.api.url}/jobs/restoration`,
      {
        params: { exclude_status: 'CL' },
      }
    );
  }
}
