import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Materials } from '@app/models';

@Injectable({ providedIn: 'root' })
export class MaterialsService {
  constructor(private http: HttpClient, private api: ApiService) {}

  loadAll(): Observable<Materials> {
    return this.http.get<Materials>(`${this.api.url}/materials`);
  }
}
