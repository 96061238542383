import { JobPlanEquipReqVM } from './job-plan-equip-req.model';
import { JobPlanCrewAssignmentVM } from './job-plan-crew-assignment.model';
import { JobPlanCrewRequirementVM } from './job-plan-crew-requirement.model';
import { JobLocateVM } from '@app/models/job-locate.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { JobPlan } from './job-plan.model';
import { Job } from './job.model';

@Entity({ modelName: 'EmergencyTicket' })
export class EmergencyTicket {
  @Key id?: string;
  jobPlan: string;
  assigned: boolean;
  createdBy: string;
  contact: string;
  company: string;
  contactPhone: string;
  contactEmail: string;
  created: string;
  notes: string;
  estStartTime: string;
}

export class EmergencyTicketVM {
  ticket: EmergencyTicket;
  jobPlan: JobPlan;
  job: Job;
  locates: JobLocateVM[];
  crewRequirements: JobPlanCrewRequirementVM[];
  crewAssignments: JobPlanCrewAssignmentVM[];
  equipRequirements: JobPlanEquipReqVM[];
}
