<ion-content color="dark">
  <div class="message">
    <h2>
      The server is currently in Maintenance Mode.
    </h2>
    <p>
      Please check back in a few minutes, as maintenance should be done soon.
    </p>
    <p>This message will disappear once the server is out of maintenance.</p>
  </div>
  <div class="bg">
    <fa-icon [icon]="['fas', 'exclamation-triangle']" size="4x"></fa-icon>
  </div>
</ion-content>
