import { AlertController, ModalController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '@services/storage/storage.service';

interface VersionResponse {
  deployment: string;
}

const VERSION_KEY = 'version';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  intervalId: any;

  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private alertCtrl: AlertController
  ) {}

  async initVersionCheck() {
    this.checkVersion();
    this.intervalId = setInterval(() => {
      this.checkVersion();
    }, 10000);
  }

  async checkVersion() {
    const timeStamp = Math.floor(Date.now() / 1000);
    try {
      const resp = await this.http
        .get<VersionResponse>(`/assets/vdata.json?${timeStamp}`)
        .pipe(take(1))
        .toPromise();
      const keys = await this.storage.keys();
      if (!keys.includes(VERSION_KEY)) {
        await this.storage.set(VERSION_KEY, 'REPLACE');
      }
      const runningVersion = await this.storage.get(VERSION_KEY);
      if (runningVersion !== resp.deployment) {
        const alert = await this.alertCtrl.create({
          header: 'New Version Available',
          message:
            'A required update is available.  Hit "Update" below to install the latest version.',
          backdropDismiss: false,
          buttons: [
            {
              text: 'Update',
              handler: () => {
                this.update(resp.deployment);
              },
            },
          ],
        });
        await alert.present();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async update(newVersion: string) {
    await this.storage.set(VERSION_KEY, newVersion);
    window.location.replace('/');
  }
}
