import { JobDateEquipTimecardVM } from './job-date-equip-timecard.model';
import { JobFieldLogTotalTimeVM } from './job-field-log-total-time.model';
import { UserVM } from './user.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { JobDateTimecardVM } from './job-date-timecard.model';
import { JobDateUntrackedEquipTimecardVM } from './job-date-untracked-equip-tc.model';

@Entity({ modelName: 'JobFieldLog' })
export class JobFieldLog {
  @Key id?: string;
  job?: string;
  jobId?: string;
  date: string;
  nightPay?: boolean;
  unscheduled?: boolean;
  created?: string;
  completed: boolean;
  user: string;
  crewAssignment: string;
}

export interface JobFieldLogVM {
  id?: string;
  job?: string;
  jobId?: string;
  date: Date;
  created?: Date;
  nightPay?: boolean;
  unscheduled?: boolean;
  completed: boolean;
  user: UserVM;
  crewAssignment: string;
  timecards: JobDateTimecardVM[];
  totalTime: JobFieldLogTotalTimeVM;
  equipment: JobDateEquipTimecardVM[];
  untrackedEquip: JobDateUntrackedEquipTimecardVM[];
}

export interface FieldLogReqs {
  timecards: boolean;
  totalTime: boolean;
  equipment: boolean;
  costing: boolean;
  extras: boolean;
}

export const hourlyFLRDefaults: { [key: string]: FieldLogReqs } = {
  foreman: {
    timecards: true,
    totalTime: false,
    equipment: true,
    costing: true,
    extras: false,
  },
  nonForeman: {
    timecards: true,
    totalTime: false,
    equipment: true,
    costing: true,
    extras: false,
  },
};

export const repairFLRDefault: { [key: string]: FieldLogReqs } = {
  foreman: {
    timecards: true,
    totalTime: true,
    equipment: true,
    costing: true,
    extras: false,
  },
  nonForeman: {
    timecards: true,
    totalTime: false,
    equipment: false,
    costing: false,
    extras: false,
  },
};

export const contractFLRDefault: { [key: string]: FieldLogReqs } = {
  foreman: {
    timecards: true,
    totalTime: true,
    equipment: true,
    costing: false,
    extras: true,
  },
  nonForeman: {
    timecards: true,
    totalTime: false,
    equipment: false,
    costing: false,
    extras: false,
  },
};

export const timecardOnlyFLRDefault: FieldLogReqs = {
  timecards: true,
  totalTime: false,
  equipment: false,
  costing: false,
  extras: false,
};
