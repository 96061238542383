import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { UserRole } from '@app/models';

export const {
  initialState: userRoleInitialState,
  selectors: userRoleSelectors,
  facade: UserRoleFacadeBase,
} = buildState(UserRole);

export function userRoleReducer(
  state = userRoleInitialState
): IEntityState<UserRole> {
  return state;
}
