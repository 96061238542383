import { createAction, props } from '@ngrx/store';
import { UserMe, UserTimecard } from '@app/models';

export const loadUserMe = createAction('[User Me] Load');
export const loadUserMeSuccess = createAction(
  '[User Me API] Load Success',
  props<{ payload: UserMe }>()
);
export const loadUserMeError = createAction(
  '[User Me API] Load Error',
  props<{ error: any }>()
);
export const updateUserMeTimecard = createAction(
  '[User Me] Update Timecard',
  props<{ timecard: UserTimecard }>()
);
