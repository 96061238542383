import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { EquipRepairTicket } from '@app/models';

export const {
  initialState: equipRepairTicketInitialState,
  selectors: equipRepairTicketSelectors,
  facade: equipRepairTicketFacadeBase,
} = buildState(EquipRepairTicket);

export function equipRepairTicketReducer(
  state = equipRepairTicketInitialState
): IEntityState<EquipRepairTicket> {
  return state;
}
