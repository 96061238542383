import { createReducer, Action, on, createFeatureSelector } from '@ngrx/store';
import * as actions from './job-details.actions';
import { AppState } from '../app.state';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: any;
  job: string;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: null,
  job: null,
};

const jobDetailsReducer = createReducer(
  initialState,
  on(actions.loadJobDetails, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
    job: null,
  })),
  on(actions.loadJobDetailsSuccess, (state, { job }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    job,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return jobDetailsReducer(state, action);
}

export const selectJobDetails = createFeatureSelector<State>('jobDetails');
