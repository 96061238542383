import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlanLinkedEquip } from '@app/models';

export const {
  initialState: jobPlanLinkedEquipInitialState,
  selectors: jobPlanLinkedEquipSelectors,
  facade: JobPlanLinkedEquipFacadeBase,
} = buildState(JobPlanLinkedEquip);

export function jobPlanLinkedEquipReducer(
  state = jobPlanLinkedEquipInitialState
): IEntityState<JobPlanLinkedEquip> {
  return state;
}
