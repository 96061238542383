import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobDateNote' })
export class JobDateNote {
  @Key id?: string;
  job: string;
  date: string;
  note: string;
}

export interface JobDateNoteVM {
  id?: string;
  job: string;
  date: Date;
  note: string;
}
