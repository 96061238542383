import { Injectable } from '@angular/core';
import {
  ContractExtra,
  ContractExtraFieldLog,
  ContractExtraTotalTime,
  JobDateEquipOptTimecard,
  JobDateEquipTimecard,
  JobMaterial,
  JobSubcontractor,
} from '@app/models';
import {
  CreateManySuccess,
  CreateSuccess,
  LoadManySuccess,
  LoadSuccess,
} from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, props, Store } from '@ngrx/store';
import {
  ContractExtraFieldLogService,
  CreateFieldLogPayload,
} from '@services/api/contract-extra-field-log.service';
import { exhaustMap, map } from 'rxjs/operators';
import { AppState } from '.';

export const loadJobContractExtraDetails = createAction(
  '[Contract Extra Field Log] Load Job Contract Extra Details',
  props<{ payload: string }>()
);
export const createFullCEFieldLog = createAction(
  '[Contract Extra Field Log] Create Full',
  props<{ payload: CreateFieldLogPayload }>()
);

@Injectable()
export class ContractExtraFieldLogEffects {
  loadJobContractExtraDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadJobContractExtraDetails),
        exhaustMap(action =>
          this.fieldLogService.loadFromJob(action.payload).pipe(
            map(response => {
              console.log(response);
              this.store$.dispatch(
                new LoadManySuccess(ContractExtra, response.extras)
              );
              this.store$.dispatch(
                new LoadManySuccess(ContractExtraFieldLog, response.fieldLogs)
              );
              this.store$.dispatch(
                new LoadManySuccess(ContractExtraTotalTime, response.totalTime)
              );
              this.store$.dispatch(
                new LoadManySuccess(JobDateEquipTimecard, response.equip)
              );
              this.store$.dispatch(
                new LoadManySuccess(JobDateEquipOptTimecard, response.equipOpt)
              );
              this.store$.dispatch(
                new LoadManySuccess(JobMaterial, response.materials)
              );
              this.store$.dispatch(
                new LoadManySuccess(JobSubcontractor, response.subcontractors)
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  createFullCEFieldLog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createFullCEFieldLog),
        exhaustMap(action =>
          this.fieldLogService.createFull(action.payload).pipe(
            map(response => {
              this.store$.dispatch(
                new LoadSuccess(ContractExtra, response.contractExtra)
              );
              this.store$.dispatch(
                new CreateSuccess(ContractExtraFieldLog, response.fieldLog)
              );
              if ('totalTime' in response) {
                this.store$.dispatch(
                  new CreateSuccess(ContractExtraTotalTime, response.totalTime)
                );
              }
              this.store$.dispatch(
                new CreateManySuccess(JobDateEquipTimecard, response.equipTime)
              );
              this.store$.dispatch(
                new CreateManySuccess(
                  JobDateEquipOptTimecard,
                  response.equipOptTime
                )
              );
              this.store$.dispatch(
                new CreateManySuccess(JobMaterial, response.materials)
              );
              this.store$.dispatch(
                new CreateManySuccess(JobSubcontractor, response.subcontractors)
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private fieldLogService: ContractExtraFieldLogService,
    private store$: Store<AppState>
  ) {}
}
