import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { EquipmentMachineVM } from './equipment-machine.model';
import { JobDateEquipOptTimecardVM } from './job-date-equip-opt-timecard.model';

@Entity({ modelName: 'JobDateEquipTimecard' })
export class JobDateEquipTimecard {
  @Key id?: string;
  fieldLog?: string;
  extraFieldLog?: string;
  equip: number;
  minutes: number;
}

export interface JobDateEquipTimecardVM {
  id?: string;
  fieldLog?: string;
  extraFieldLog?: string;
  equip: EquipmentMachineVM;
  hours: number;
  minutes: number;
  optionTimecards: JobDateEquipOptTimecardVM[];
}
