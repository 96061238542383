import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

export interface UpdateStatus {
  step: number;
  totalSteps: number;
  title: string;
  subTitle?: string;
  progress?: number;
  indeterminate?: boolean;
}

@Component({
  selector: 'app-update-status',
  templateUrl: './update-status.component.html',
  styleUrls: ['./update-status.component.scss'],
})
export class UpdateStatusComponent implements OnInit {
  @Input() status: UpdateStatus;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}
}
