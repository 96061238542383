import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Crew } from '@app/models';

export const {
  initialState: crewInitialState,
  selectors: crewSelectors,
  facade: CrewFacadeBase,
} = buildState(Crew);

export function crewReducer(state = crewInitialState): IEntityState<Crew> {
  return state;
}
