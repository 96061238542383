import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobDateEquipTimecard } from '@app/models';

export const {
  initialState: jobDateEquipTimecardInitialState,
  selectors: jobDateEquipTimecardSelectors,
  facade: JobDateEquipTimecardFacadeBase,
} = buildState(JobDateEquipTimecard);

export function jobDateEquipTimecardReducer(
  state = jobDateEquipTimecardInitialState
): IEntityState<JobDateEquipTimecard> {
  return state;
}
