import { Key, Entity } from '@briebug/ngrx-auto-entity';

export const restorationTypeValueList = [
  { value: 'D1', label: 'Length' },
  { value: 'D2', label: 'Area' },
  { value: 'D3', label: 'Volume' },
  { value: 'NA', label: 'None' },
];

export const restorationTypeValueMap = {
  D1: 'Length',
  D2: 'Area',
  D3: 'Volume',
  NA: 'None',
};

@Entity({ modelName: 'RestorationType' })
export class RestorationType {
  @Key id?: number;
  name: string;
  category: number;
  valueType: 'NA' | 'D1' | 'D2' | 'D3';
  delta: number;
}
