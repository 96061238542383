import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { JobAlertVM } from './job-alert.model';
import { UserVM } from './user.model';

export interface JobEmployeeDetails {
  user: string;
  latest: string;
  numDays: number;
}

export interface JobEmployeeDetailsVM {
  user: UserVM;
  latest: Date;
  numDays: number;
}

@Entity({ modelName: 'Job' })
export class Job {
  @Key id: string;
  jobNumber: string;
  name: string;
  customer: string;
  address: string;
  city: string;
  state: string;
  jobType: string;
  active: boolean;
  contact: string;
  geocoordStatus: string;
  lat: number;
  lng: number;
  accuracy: string;
  accuracyType: string;
  unassigned: boolean;
  allEmployees: JobEmployeeDetails[];
  activeDates: string[];
}

export interface JobVM {
  id: string;
  jobNumber: string;
  name: string;
  customer: string;
  address: string;
  city: string;
  state: string;
  jobType: string;
  active: boolean;
  contact: string;
  geocoordStatus: string;
  lat: number;
  lng: number;
  accuracy: string;
  accuracyType: string;
  unassigned: boolean;
  allEmployees: JobEmployeeDetailsVM[];
  activeDates: Date[];
  alerts: JobAlertVM[];
}
