import { ShopLocation } from './../../models/shop-location.model';
import { loadUserMeSuccess } from './../user-me/user-me.actions';
import {
  EquipmentClass,
  EquipmentType,
  EquipmentModel,
  EquipmentMachine,
  EquipmentOption,
  EquipmentOptionType,
  EquipmentUntrackedClass,
  EquipmentUntrackedMachine,
  MaterialVendor,
  MaterialCategory,
  RestorationCategory,
  RestorationVendor,
  RestorationType,
  SubcontractorVendor,
  SubcontractorCategory,
  User,
  UserRole,
  UserMe,
  Job,
} from '@app/models';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
  LoadAllSuccess,
  LoadManySuccess,
  LoadSuccess,
} from '@briebug/ngrx-auto-entity';
import {
  loadAllBaseData,
  loadAllBaseDataSuccess,
  loadAllBaseDataError,
} from './base-data.actions';
import { exhaustMap, catchError, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseDataService } from '@services/api/base-data.service';
import { loadMultipleTimeCardsSuccess } from '../multiple-timecards/multiple-timecards.actions';

@Injectable()
export class BaseDataEffects {
  loadAllBaseData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllBaseData),
      exhaustMap(() =>
        this.baseDataService.loadAll().pipe(
          map(baseData => {
            // Equipment
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentClass, baseData.equipmentClasses)
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentType, baseData.equipmentTypes)
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentModel, baseData.equipmentModels)
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentMachine, baseData.equipmentMachines)
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentOption, baseData.equipmentOptions)
            );
            this.store$.dispatch(
              new LoadAllSuccess(
                EquipmentOptionType,
                baseData.equipmentOptionTypes
              )
            );
            this.store$.dispatch(
              new LoadAllSuccess(
                EquipmentUntrackedClass,
                baseData.untrackedClasses
              )
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentUntrackedMachine, baseData.untracked)
            );

            // Users
            this.store$.dispatch(new LoadAllSuccess(User, baseData.users));
            this.store$.dispatch(
              new LoadAllSuccess(UserRole, baseData.userRoles)
            );

            if (baseData.activeTimecardJobs) {
              this.store$.dispatch(
                new LoadManySuccess(Job, baseData.activeTimecardJobs)
              );
            }

            // UserMe
            const um: UserMe = {
              user: baseData.user,
              incompleteCount: baseData.userIncomplete,
              activeTimecard:
                baseData.activeTimecards.length === 1
                  ? baseData.activeTimecards[0]
                  : null,
              invalidRanges: baseData.invalidRanges,
              minDate: baseData.minDate,
            };
            this.store$.dispatch(loadUserMeSuccess({ payload: um }));
            this.store$.dispatch(
              loadMultipleTimeCardsSuccess({
                payload: baseData.multipleTimecards
                  ? baseData.activeTimecards
                  : [],
              })
            );

            // Materials
            this.store$.dispatch(
              new LoadAllSuccess(MaterialCategory, baseData.materialCategories)
            );
            this.store$.dispatch(
              new LoadAllSuccess(MaterialVendor, baseData.materialVendors)
            );
            // Subs
            this.store$.dispatch(
              new LoadAllSuccess(
                SubcontractorCategory,
                baseData.subcontractorCategories
              )
            );
            this.store$.dispatch(
              new LoadAllSuccess(
                SubcontractorVendor,
                baseData.subcontractorVendors
              )
            );
            // Restoration
            this.store$.dispatch(
              new LoadAllSuccess(
                RestorationCategory,
                baseData.restorationCategories
              )
            );
            this.store$.dispatch(
              new LoadAllSuccess(RestorationVendor, baseData.restorationVendors)
            );
            this.store$.dispatch(
              new LoadAllSuccess(RestorationType, baseData.restorationTypes)
            );
            // Shop
            this.store$.dispatch(
              new LoadAllSuccess(ShopLocation, baseData.shopLocations)
            );
            return loadAllBaseDataSuccess();
          }),
          catchError(error => {
            return of(loadAllBaseDataError({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private baseDataService: BaseDataService,
    private store$: Store<AppState>
  ) {}
}
