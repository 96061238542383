import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import {
  JobPlanManager,
  JobPlan,
  JobPlanManaged,
  Job,
  JobPlanTentative,
  EmergencyTicket,
  JobDateNote,
  JobPlanAlarm,
  EquipmentUseDate,
} from '@app/models';
import { Observable } from 'rxjs';
import { JobAlert } from '@app/models/job-alert.model';

export interface JobPlannerDateParams {
  minDate: string;
  maxDate: string;
}

export interface JobPlannerExtendDatesParams extends JobPlannerDateParams {
  existingMin?: string;
  existingMax?: string;
}

export interface JobPlannerResponse {
  managers: JobPlanManager[];
  jobPlans: JobPlan[];
  jobPlanManaged: JobPlanManaged[];
  jobs: Job[];
  tentative: JobPlanTentative[];
  emergencyTickets: EmergencyTicket[];
  jobDateNotes: JobDateNote[];
  jobAlerts: JobAlert[];
  jobAlarms: JobPlanAlarm[];
  equipUse: EquipmentUseDate[];
}

@Injectable({
  providedIn: 'root',
})
export class JobPlannerService {
  constructor(private http: HttpClient, private api: ApiService) {}

  load(dateParams: JobPlannerDateParams): Observable<JobPlannerResponse> {
    return this.http.get<JobPlannerResponse>(`${this.api.url}/job-plans`, {
      params: { min_date: dateParams.minDate, max_date: dateParams.maxDate },
    });
  }

  extendDates(
    dateParams: JobPlannerExtendDatesParams
  ): Observable<JobPlannerResponse> {
    const params = {
      min_date: dateParams.minDate,
      max_date: dateParams.maxDate,
    };
    if (dateParams.existingMin) {
      params['existing_min'] = dateParams.existingMin;
    } else if (dateParams.existingMax) {
      params['existing_max'] = dateParams.existingMax;
    }
    return this.http.get<JobPlannerResponse>(`${this.api.url}/job-plans`, {
      params,
    });
  }
}
