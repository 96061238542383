import { parseISO } from 'date-fns';
import { createSelector } from '@ngrx/store';
import { jobRestorationSelectors } from './job-restoration.state';
import { userSelectors } from './user.state';
import { restorationCategorySelectors } from './restoration-category.state';
import { restorationVendorSelectors } from './restoration-vendor.state';
import { JobRestorationTicketVM, JobRestorationVM } from '@app/models';
import { restorationTypeSelectors } from './restoration-type.state';
import { jobSelectors } from '.';
import { selectJobRestorationMediaVMAll } from './job-restoration-media.join-selectors';

export const selectJobRestorationVMAll = createSelector(
  jobRestorationSelectors.selectAll,
  restorationCategorySelectors.selectEntities,
  restorationVendorSelectors.selectEntities,
  restorationTypeSelectors.selectEntities,
  userSelectors.selectEntities,
  (restorations, categories, vendors, types, users): JobRestorationVM[] => {
    return restorations.map(
      (restoration): JobRestorationVM => {
        return {
          ...restoration,
          category:
            restoration.category in categories
              ? categories[restoration.category]
              : null,
          vendor:
            restoration.vendor in vendors ? vendors[restoration.vendor] : null,
          restorationType:
            restoration.restorationType in types
              ? types[restoration.restorationType]
              : null,
          employee:
            restoration.employee in users ? users[restoration.employee] : null,
          created: restoration.created ? parseISO(restoration.created) : null,
          date: parseISO(restoration.date),
          finishByDate: restoration.date ? parseISO(restoration.date) : null,
        };
      }
    );
  }
);

export const selectJobRestorationTicketsAll = createSelector(
  selectJobRestorationVMAll,
  selectJobRestorationMediaVMAll,
  jobSelectors.selectEntities,
  (tickets, media, jobs): JobRestorationTicketVM[] => {
    return tickets.map(ticket => {
      return {
        ...ticket,
        job: ticket.job in jobs ? jobs[ticket.job] : null,
        media: media.filter(row => row.restorationTicket === ticket.id),
      };
    });
  }
);

export const selectedJobRestorationTicket = createSelector(
  selectJobRestorationTicketsAll,
  jobRestorationSelectors.selectCurrentEntityKey,
  (tickets, selected) => {
    return tickets.find(ticket => ticket.id === selected);
  }
);
