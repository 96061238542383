import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
  LoadAllSuccess,
  LoadManySuccess,
  ofEntityType,
  EntityActionTypes,
  LoadSuccess,
  DeleteManySuccess,
} from '@briebug/ngrx-auto-entity';
import {
  loadAllTrucking,
  loadAllTruckingSuccess,
  loadAllTruckingError,
  loadTruckingEquipLocs,
  loadTruckingEquipLocsSuccess,
  loadTruckingEquipLocsError,
  createTruckingTimecard,
  createTruckingTimecardError,
  updateTruckingTimecard,
  createTruckingRental,
  createTruckingRentalSuccess,
  createTruckingRentalError,
  planRentalReturn,
  planRentalReturnError,
  rentalReturn,
} from './trucking.actions';
import { exhaustMap, mergeMap, catchError, map } from 'rxjs/operators';
import { TruckingService } from '@services/api/trucking.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import {
  Job,
  TruckingRequirement,
  TruckingMove,
  TruckingEquipLocation,
  EquipmentMachine,
  EquipRepairTicket,
} from '@app/models';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { updateUserMeTimecard } from '../user-me/user-me.actions';

@Injectable()
export class TruckingEffects {
  loadAllTrucking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllTrucking),
      exhaustMap(() =>
        this.truckingService.loadAll().pipe(
          map(trucking => {
            this.store$.dispatch(new LoadManySuccess(Job, trucking.jobs));
            this.store$.dispatch(
              new LoadAllSuccess(
                TruckingRequirement,
                trucking.truckingRequirements
              )
            );
            this.store$.dispatch(
              new LoadAllSuccess(TruckingMove, trucking.truckingMoves)
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipRepairTicket, trucking.repairRequirements)
            );
            return loadAllTruckingSuccess();
          }),
          catchError(error => {
            return of(loadAllTruckingError({ error }));
          })
        )
      )
    )
  );

  loadTruckingEquipLocs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTruckingEquipLocs),
      exhaustMap(() =>
        this.truckingService.loadLocations().pipe(
          map(res => {
            this.store$.dispatch(
              new LoadAllSuccess(TruckingEquipLocation, res.jobEquipment)
            );
            return loadTruckingEquipLocsSuccess();
          }),
          catchError(error => {
            return of(loadTruckingEquipLocsError({ error }));
          })
        )
      )
    )
  );

  deleteMove$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(
        TruckingMove,
        EntityActionTypes.DeleteSuccess,
        EntityActionTypes.CreateSuccess
      ),
      map(() => loadAllTrucking())
    )
  );

  createTimecard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createTruckingTimecard),
      mergeMap(action =>
        this.truckingService.createTimecard(action.payload).pipe(
          map(response => updateUserMeTimecard({ timecard: response })),
          catchError(error => of(createTruckingTimecardError({ error })))
        )
      )
    )
  );

  updateTimecard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTruckingTimecard),
      mergeMap(action =>
        this.truckingService.updateTimecard(action.payload).pipe(
          map(response => {
            if (response.clockOut) {
              return updateUserMeTimecard({ timecard: null });
            } else {
              return updateUserMeTimecard({ timecard: response });
            }
          }),
          catchError(error => of(createTruckingTimecardError({ error })))
        )
      )
    )
  );

  createTruckingRental$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createTruckingRental),
      exhaustMap(action =>
        this.truckingService.createRental(action.payload).pipe(
          map(res => {
            this.store$.dispatch(
              new LoadSuccess(TruckingMove, res.truckingMove)
            );
            this.store$.dispatch(
              new LoadSuccess(EquipmentMachine, res.equipment)
            );
            return createTruckingRentalSuccess({ payload: res });
          }),
          catchError(error => {
            return of(createTruckingRentalError({ error }));
          })
        )
      )
    )
  );

  planRentalReturn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(planRentalReturn),
      exhaustMap(action =>
        this.truckingService.planRentalReturn(action.payload).pipe(
          map(res => {
            this.store$.dispatch(new LoadSuccess(TruckingMove, res));
            this.store$.dispatch(loadTruckingEquipLocs());
            return loadAllTrucking();
          }),
          catchError(error => {
            return of(planRentalReturnError({ error }));
          })
        )
      )
    )
  );

  rentalReturn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rentalReturn),
      exhaustMap(action =>
        this.truckingService.returnRental(action.payload).pipe(
          map(res => {
            this.store$.dispatch(
              new DeleteManySuccess(TruckingMove, res.completedMoves)
            );
            this.store$.dispatch(
              new DeleteManySuccess(EquipmentMachine, res.returnedEquipment)
            );
            this.store$.dispatch(loadTruckingEquipLocs());
            return loadAllTrucking();
          }),
          catchError(error => {
            return of(planRentalReturnError({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private truckingService: TruckingService,
    private store$: Store<AppState>
  ) {}
}
