import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { map, exhaustMap } from 'rxjs/operators';
import { createAction, props, Store } from '@ngrx/store';
import {
  JobDocumentService,
  CreateJobDocumentPayload,
} from '@services/api/job-document.service';
import { AppState } from './app.state';
import { CreateSuccess, Update } from '@briebug/ngrx-auto-entity';
import { JobDocument } from '@app/models';
import { v4 as uuid } from 'uuid';
import { EMPTY } from 'rxjs';

export const createJobDocument = createAction(
  '[Job Document] Create',
  props<{ payload: CreateJobDocumentPayload }>()
);

export const uploadJobDocument = createAction(
  '[Job Document] Upload',
  props<{ payload: CreateJobDocumentPayload }>()
);

@Injectable()
export class JobDocumentEffects {
  createDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobDocument),
        map(action => action.payload),
        exhaustMap(payload => {
          const progressId = uuid();
          return this.jobDocService.create(payload.jobDocument).pipe(
            map(response => {
              this.store$.dispatch(new CreateSuccess(JobDocument, response));
              const newPayload: CreateJobDocumentPayload = {
                jobDocument: response,
                file: payload.file,
              };
              this.store$.dispatch(uploadJobDocument({ payload: newPayload }));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          );
        })
      ),
    { dispatch: false }
  );

  uploadDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadJobDocument),
        map(action => action.payload),
        exhaustMap(payload => {
          const progressId = uuid();
          this.jobDocService.uploadFile(payload);
          return EMPTY;
          // .pipe(
          //   map(completed => {
          //     if (completed) {
          //       const jobDocument: JobDocument = {
          //         ...payload.jobDocument,
          //         fileExists: true,
          //       };
          //       this.store$.dispatch(new Update(JobDocument, jobDocument));
          //     }
          //   })
          // );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobDocService: JobDocumentService,
    private store$: Store<AppState>
  ) {}
}
