import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { EquipmentType } from '@app/models';

export const {
  initialState: equipTypeInitialState,
  selectors: equipTypeSelectors,
  facade: EquipTypeFacadeBase,
} = buildState(EquipmentType);

export function equipTypeReducer(
  state = equipTypeInitialState
): IEntityState<EquipmentType> {
  return state;
}
