import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { User } from '@app/models';

export const {
  initialState: userInitialState,
  selectors: userSelectors,
  facade: UserFacadeBase,
} = buildState(User);

export function userReducer(state = userInitialState): IEntityState<User> {
  return state;
}
