import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { TruckingEquipLocation } from '@app/models';

export const {
  initialState: truckingEquipLocationInitialState,
  selectors: truckingEquipLocationSelectors,
  facade: TruckingEquipLocationFacadeBase,
} = buildState(TruckingEquipLocation);

export function truckingEquipLocationReducer(
  state = truckingEquipLocationInitialState
): IEntityState<TruckingEquipLocation> {
  return state;
}
