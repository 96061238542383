import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { EquipmentOptionType } from './equipment-option-type.model';
import { OptionSet } from '@app/state/equipment-type.join-selectors';

export const TransportTypeMap = {
  TR: 'Trucking',
  MS: 'Self-Serve',
};

export type TransportTypeOptions = 'TR' | 'MS';

export const BillingTypeMap = {
  HR: 'Hourly',
  DY: 'Daily',
};

export type BillingTypeOptions = 'HR' | 'DY';

@Entity({ modelName: 'EquipmentType' })
export class EquipmentType {
  @Key id: number;
  name: string;
  delta: number;
  equipClass: number;
  showInTrucking: boolean;
  ignoreUsageCount: boolean;
  transportType: TransportTypeOptions;
  rentalNumber: string;
  defaultBillingType: BillingTypeOptions;
}

export class EquipmentTypeVM {
  id: number;
  name: string;
  delta: number;
  equipClass: number;
  showInTrucking: boolean;
  ignoreUsageCount: boolean;
  transportType: TransportTypeOptions;
  rentalNumber: string;
  defaultBillingType: BillingTypeOptions;
  availableOptions: OptionSet;
}
