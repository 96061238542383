import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import {
  Job,
  JobPlan,
  EmergencyTicket,
  JobLocate,
  JobPlanCrewRequirement,
  JobPlanCrewAssignment,
  Crew,
  JobPlanEquipReq,
} from '@app/models';

export interface LoadAllEmergTickets {
  jobs: Job[];
  jobPlans: JobPlan[];
  emergencyTickets: EmergencyTicket[];
  locates: JobLocate[];
  crewRequirements: JobPlanCrewRequirement[];
  crewAssignments: JobPlanCrewAssignment[];
  crews: Crew[];
  equipRequirements: JobPlanEquipReq[];
}

export interface CreateEmergencyTicketPayload {
  ticket: {
    contact: string;
    company: string;
    contactPhone: string;
    contactEmail: string;
    notes: string;
    estStartTime: string;
  };
  location: {
    name: string;
    address: string;
    city: string;
  };
}

export interface UpdateEmergencyTicketPayload {
  ticket: EmergencyTicket;
  location: {
    name: string;
    address: string;
    city: string;
  };
}

export interface DeleteEmergencyTicketPayload {
  job: Job;
  jobPlan: JobPlan;
  emergencyTicket: EmergencyTicket;
}

export interface CreateEmergencyTicketResponse {
  job: Job;
  jobPlan: JobPlan;
  emergencyTicket: EmergencyTicket;
}

export interface EmergencyTicketSimpleResponse {
  jobs: Job[];
  jobPlans: JobPlan[];
  emergencyTickets: EmergencyTicket[];
}

export interface UpdateEmergencyTicketResponse {
  job: Job;
  jobPlan: JobPlan;
  emergencyTicket: EmergencyTicket;
  crewRequirements: JobPlanCrewRequirement[];
  crewAssignments: JobPlanCrewAssignment[];
  equipRequirements: JobPlanEquipReq[];
}

export interface CreateEmergencyCrewRequest {
  id: string;
  payload: CreateEmergencyCrewPayload;
}

export interface CreateEmergencyCrewPayload {
  crew: {
    foreman: string;
    others: string[];
  };
}

export interface DeleteEmergencyCrewRequest {
  crewRequirement: JobPlanCrewRequirement;
  crewAssignment: JobPlanCrewAssignment;
}

export interface CreateEmergencyCrewResponse {
  crewReq: JobPlanCrewRequirement;
  crewAssign: JobPlanCrewAssignment;
  crew: Crew;
}

@Injectable({
  providedIn: 'root',
})
export class EmergencyTicketService {
  constructor(private http: HttpClient, private api: ApiService) {}

  loadAll(): Observable<LoadAllEmergTickets> {
    return this.http.get<LoadAllEmergTickets>(
      `${this.api.url}/job-plans/emergency`
    );
  }

  loadPlanner(): Observable<EmergencyTicketSimpleResponse> {
    return this.http.get<EmergencyTicketSimpleResponse>(
      `${this.api.url}/job-plans/emergency/list-for-planner`
    );
  }

  createTicket(
    payload: CreateEmergencyTicketPayload
  ): Observable<CreateEmergencyTicketResponse> {
    return this.http.post<CreateEmergencyTicketResponse>(
      `${this.api.url}/job-plans/emergency`,
      payload
    );
  }

  updateTicket(
    payload: UpdateEmergencyTicketPayload
  ): Observable<UpdateEmergencyTicketResponse> {
    return this.http.put<UpdateEmergencyTicketResponse>(
      `${this.api.url}/job-plans/emergency/${payload.ticket.id}`,
      payload
    );
  }

  createCrew(
    payload: CreateEmergencyCrewRequest
  ): Observable<CreateEmergencyCrewResponse> {
    return this.http.post<CreateEmergencyCrewResponse>(
      `${this.api.url}/job-plans/emergency/${payload.id}/crew`,
      payload.payload
    );
  }

  deleteCrew(payload: DeleteEmergencyCrewRequest) {
    return this.http.delete(
      `${this.api.url}/job-plans/emergency/crews/${payload.crewRequirement.id}`
    );
  }

  deleteTicket(payload: DeleteEmergencyTicketPayload) {
    return this.http.delete(
      `${this.api.url}/job-plans/emergency/${payload.emergencyTicket.id}`
    );
  }
}
