import { EquipmentUseDate } from '@app/models/equipment-use.model';
import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';

export const {
  initialState: equipmentUseDateInitialState,
  selectors: equipmentUseDateSelectors,
  facade: EquipmentUseDateFacadeBase,
} = buildState(EquipmentUseDate);

export function equipmentUseDateReducer(
  state = equipmentUseDateInitialState
): IEntityState<EquipmentUseDate> {
  return state;
}
