import { parseISO } from 'date-fns';
import { createSelector } from '@ngrx/store';
import { jobSubcontractorSelectors } from './job-subcontractor.state';
import { userSelectors } from './user.state';
import { subcontractorCategorySelectors } from './subcontractor-category.state';
import { subcontractorVendorSelectors } from './subcontractor-vendor.state';
import { JobSubcontractorVM } from '@app/models';

export const selectJobSubcontractorVMAll = createSelector(
  jobSubcontractorSelectors.selectAll,
  subcontractorCategorySelectors.selectEntities,
  subcontractorVendorSelectors.selectEntities,
  userSelectors.selectEntities,
  (subcontractors, categories, vendors, users): JobSubcontractorVM[] => {
    return subcontractors.map(
      (subcontractor): JobSubcontractorVM => {
        return {
          ...subcontractor,
          category:
            subcontractor.category in categories
              ? categories[subcontractor.category]
              : null,
          vendor:
            subcontractor.vendor in vendors
              ? vendors[subcontractor.vendor]
              : null,
          employee:
            subcontractor.employee in users
              ? users[subcontractor.employee]
              : null,
          created: subcontractor.created
            ? parseISO(subcontractor.created)
            : null,
          date: parseISO(subcontractor.date),
        };
      }
    );
  }
);

export const selectJobSubcontractorVMByExtra = createSelector(
  selectJobSubcontractorVMAll,
  subs => {
    const result: { [key: string]: JobSubcontractorVM[] } = {};
    subs
      .filter(sub => sub.extraFieldLog)
      .forEach(sub => {
        if (!(sub.extraFieldLog in result)) {
          result[sub.extraFieldLog] = [];
        }
        result[sub.extraFieldLog].push(sub);
      });
    return result;
  }
);
