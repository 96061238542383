import { Injectable } from '@angular/core';
import {
  IAutoEntityService,
  IEntityInfo,
  LoadAll,
  LoadAllSuccess,
  LoadMany,
} from '@briebug/ngrx-auto-entity';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import {
  JobPlan,
  JobPlanManager,
  Job,
  JobPlanManaged,
  JobPlanCrewRequirement,
  JobPlanVM,
  JobPlanCrewAssignment,
  Crew,
  JobPlanEquipReq,
  JobLocate,
  EmergencyTicket,
  JobPlanNote,
  JobPlanSiteEquipment,
  JobPlanLinkedEquip,
  EquipmentUseDate,
  OverUsedEquipment,
} from '@app/models';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/state';
import { selectPlannerDateRange } from '@app/state/job-planner/job-planner.selectors';
import { take } from 'rxjs/operators';
import format from 'date-fns/format';

export interface CreateJobPlanPayload {
  manager: string;
  jobPlan: JobPlan;
}

export interface CreateJobPlanResponse {
  jobPlan: JobPlan;
  jobPlanManaged: JobPlanManaged;
  job: Job;
  manager?: JobPlanManager;
}

export interface UncompleteJobPlanResponse {
  jobPlan: JobPlan;
  jobPlanManaged: JobPlanManaged[];
  job: Job;
}

export interface CreateCrewReqResponse {
  crewReq: JobPlanCrewRequirement;
  jobPlan: JobPlan;
}

export interface UpdateCrewReqResponse {
  crewReq: JobPlanCrewRequirement;
  jobPlan: JobPlan;
}

export interface JobPlanDetailsResponse {
  crewRequirements: JobPlanCrewRequirement[];
  crewAssignments: JobPlanCrewAssignment[];
  crews: Crew[];
  equipmentRequirements: JobPlanEquipReq[];
  jobEquipment: JobPlanSiteEquipment[];
  linkedEquipment: JobPlanLinkedEquip[];
  locates: JobLocate[];
}

export interface CreateManyCrewAssignmentsResponse {
  crewAssignments: JobPlanCrewAssignment[];
  crews: Crew[];
  jobPlan: JobPlan;
}

export interface CreateCrewAssignmentResponse {
  crewAssignment: JobPlanCrewAssignment;
  crew: Crew;
  jobPlan: JobPlan;
}

export interface DeleteCrewReqResponse {
  jobPlan: JobPlan;
}

export interface CreateManyEquipReqsResponse {
  equipmentRequests: JobPlanEquipReq[];
  jobPlan: JobPlan;
}

export interface UpdateEquipReqResponse {
  equipmentRequest: JobPlanEquipReq;
  jobPlan: JobPlan;
}

export interface DeleteEquipReqResponse {
  jobPlan: JobPlan;
}

export interface LinkEmergencyJobPayload {
  job: string;
  manager: string;
  waterPct: number;
  sewerPct: number;
  hourlyPct: number;
}

export interface LinkEmergencyJobRequest {
  ticketId: string;
  repJob: Job;
  payload: LinkEmergencyJobPayload;
}

export interface LinkEmergencyJobResponse {
  jobPlan: JobPlan;
  jobPlanManaged: JobPlanManaged;
  job: Job;
  ticket: EmergencyTicket;
}

export interface DeleteJobPlanRequest {
  id: string;
  payload: {
    manager: string;
  };
}

export interface DeleteJobPlanResponse {
  jobPlanDeleted: JobPlan;
  jobPlanManagedDeleted: JobPlanManaged;
  job: Job;
}

export interface LinkableRequest {
  id: string;
  equipmentType: number;
  jobPlan: string;
  filled: boolean;
  lastDate: string;
  currentEquip: { machine: number; status: string }[];
}

export interface LinkableRequestsResponse {
  equipRequests: LinkableRequest[];
}

export interface CreateLinkedEquipResponse {
  linkedEquipment: JobPlanLinkedEquip;
  equipReq: JobPlanEquipReq;
  jobPlan: JobPlan;
}

export interface DeleteLinkedEquipResponse {
  jobPlan: JobPlan;
}

export interface JobPlanSummaryResponse {
  jobPlan: JobPlan;
  job: Job;
}

export interface AddJobPlanManagerPayload {
  jobPlanId: string;
  managerId: string;
  uncomplete: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class JobPlanService implements IAutoEntityService<any> {
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private store$: Store<AppState>
  ) {}

  summary(payload: string): Observable<JobPlanSummaryResponse> {
    return this.http.get<JobPlanSummaryResponse>(
      `${this.api.url}/job-plans/${payload}/summary`
    );
  }

  managedBy(payload: string): Observable<JobPlanManaged[]> {
    return this.http.get<JobPlanManaged[]>(
      `${this.api.url}/job-plans/${payload}/managed-by`
    );
  }

  createWithManager(
    payload: CreateJobPlanPayload
  ): Observable<CreateJobPlanResponse> {
    return this.http.post<CreateJobPlanResponse>(
      `${this.api.url}/job-plans`,
      payload
    );
  }

  addManager(
    payload: AddJobPlanManagerPayload
  ): Observable<CreateJobPlanResponse> {
    return this.http.put<CreateJobPlanResponse>(
      `${this.api.url}/job-plans/${payload.jobPlanId}/add-manager`,
      {
        manager: payload.managerId,
        uncomplete: payload.uncomplete,
      }
    );
  }

  async refreshEquipUsage() {
    const dateRange = await this.store$
      .pipe(
        select(selectPlannerDateRange),
        take(1)
      )
      .toPromise();
    this.http
      .get<{ equipUse: EquipmentUseDate[] }>(
        `${this.api.url}/job-plans/equip-usage`,
        {
          params: {
            min_date: dateRange.startDate,
            max_date: dateRange.endDate,
          },
        }
      )
      .subscribe(resp => {
        this.store$.dispatch(
          new LoadAllSuccess(EquipmentUseDate, resp.equipUse)
        );
      });
  }

  uncomplete(payload: {
    jobPlanId: string;
  }): Observable<UncompleteJobPlanResponse> {
    return this.http.put<UncompleteJobPlanResponse>(
      `${this.api.url}/job-plans/${payload.jobPlanId}/uncomplete`,
      {}
    );
  }

  linkEmergencyJob(
    request: LinkEmergencyJobRequest
  ): Observable<LinkEmergencyJobResponse> {
    return this.http.patch<LinkEmergencyJobResponse>(
      `${this.api.url}/job-plans/emergency/${request.ticketId}/link`,
      request.payload
    );
  }

  update(entityInfo: IEntityInfo, entity: JobPlan): Observable<JobPlan> {
    return this.http.patch<JobPlan>(
      `${this.api.url}/job-plans/${entity.id}`,
      entity
    );
  }

  deleteManaged(
    request: DeleteJobPlanRequest
  ): Observable<DeleteJobPlanResponse> {
    return this.http.patch<DeleteJobPlanResponse>(
      `${this.api.url}/job-plans/${request.id}/delete-managed`,
      request.payload
    );
  }

  replace(entityInfo: IEntityInfo, entity: JobPlan): Observable<JobPlan> {
    return this.http.put<JobPlan>(
      `${this.api.url}/job-plans/${entity.id}`,
      entity
    );
  }

  getDetails(entity: JobPlan | JobPlanVM): Observable<JobPlanDetailsResponse> {
    return this.http.get<JobPlanDetailsResponse>(
      `${this.api.url}/job-plans/${entity.id}`
    );
  }

  createCrewReq(
    entity: JobPlanCrewRequirement
  ): Observable<CreateCrewReqResponse> {
    return this.http.post<CreateCrewReqResponse>(
      `${this.api.url}/job-plans/crew-requirements`,
      entity
    );
  }

  updateCrewReq(
    entity: JobPlanCrewRequirement
  ): Observable<UpdateCrewReqResponse> {
    return this.http.put<UpdateCrewReqResponse>(
      `${this.api.url}/job-plans/crew-requirements/${entity.id}`,
      entity
    );
  }

  deleteCrewReq(
    entity: JobPlanCrewRequirement
  ): Observable<DeleteCrewReqResponse> {
    return this.http.delete<DeleteCrewReqResponse>(
      `${this.api.url}/job-plans/crew-requirements/${entity.id}`
    );
  }

  createCrewAssignment(
    entity: JobPlanCrewAssignment
  ): Observable<CreateCrewAssignmentResponse> {
    return this.http.post<CreateCrewAssignmentResponse>(
      `${this.api.url}/job-plans/crew-assignments`,
      entity
    );
  }

  createManyCrewAssignments(
    entity: JobPlanCrewAssignment[]
  ): Observable<CreateManyCrewAssignmentsResponse> {
    return this.http.post<CreateManyCrewAssignmentsResponse>(
      `${this.api.url}/job-plans/crew-assignments`,
      entity
    );
  }

  deleteCrewAssignment(entity: JobPlanCrewAssignment): Observable<JobPlan> {
    return this.http.delete<JobPlan>(
      `${this.api.url}/job-plans/crew-assignments/${entity.id}`
    );
  }

  createManyEquipReqs(
    entities: JobPlanEquipReq[]
  ): Observable<CreateManyEquipReqsResponse> {
    return this.http.post<CreateManyEquipReqsResponse>(
      `${this.api.url}/job-plans/equipment-requirements`,
      entities
    );
  }

  updateEquipReq(entity: JobPlanEquipReq): Observable<UpdateEquipReqResponse> {
    return this.http.put<UpdateEquipReqResponse>(
      `${this.api.url}/job-plans/equipment-requirements/${entity.id}`,
      entity
    );
  }

  deleteEquipReq(entity: JobPlanEquipReq): Observable<DeleteEquipReqResponse> {
    return this.http.delete<DeleteEquipReqResponse>(
      `${this.api.url}/job-plans/equipment-requirements/${entity.id}`
    );
  }

  getNotes(id: string): Observable<JobPlanNote[]> {
    return this.http.get<JobPlanNote[]>(
      `${this.api.url}/job-plans/${id}/notes`
    );
  }

  createNote(note: JobPlanNote): Observable<JobPlanNote> {
    return this.http.post<JobPlanNote>(`${this.api.url}/job-plans/notes`, note);
  }

  updateNote(note: JobPlanNote): Observable<JobPlanNote> {
    return this.http.put<JobPlanNote>(
      `${this.api.url}/job-plans/notes/${note.id}`,
      note
    );
  }

  deleteNote(note: JobPlanNote): Observable<any> {
    return this.http.delete(`${this.api.url}/job-plans/notes/${note.id}`);
  }

  linkableRequests(jobPlan: string): Observable<LinkableRequestsResponse> {
    return this.http.get<LinkableRequestsResponse>(
      `${this.api.url}/job-plans/${jobPlan}/linkable-equipment`
    );
  }

  createLinkedEquip(
    linkedReq: JobPlanLinkedEquip
  ): Observable<CreateLinkedEquipResponse> {
    return this.http.post<CreateLinkedEquipResponse>(
      `${this.api.url}/job-plans/linked-equipment`,
      linkedReq
    );
  }

  updateLinkedEquip(
    linkedReq: JobPlanLinkedEquip
  ): Observable<CreateLinkedEquipResponse> {
    return this.http.patch<CreateLinkedEquipResponse>(
      `${this.api.url}/job-plans/linked-equipment/${linkedReq.id}`,
      linkedReq
    );
  }

  deleteLinkedEquip(id: string): Observable<DeleteLinkedEquipResponse> {
    return this.http.delete<DeleteLinkedEquipResponse>(
      `${this.api.url}/job-plans/linked-equipment/${id}`
    );
  }

  equipUseDate(date: string): Observable<OverUsedEquipment[]> {
    return this.http.get<OverUsedEquipment[]>(
      `${this.api.url}/job-plans/equip-usage/${date}/over-used`
    );
  }
}
