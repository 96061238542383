import { createSelector } from '@ngrx/store';
import { selectJobPlanner } from './job-planner.reducer';

export const selectPlannerViewOptions = createSelector(
  selectJobPlanner,
  jobPlanner => jobPlanner.hide
);

export const selectJobPlannerLoading = createSelector(
  selectJobPlanner,
  state => state.loading
);

export const selectJobPlannerLoadStatus = createSelector(
  selectJobPlanner,
  state => ({
    loading: state.loading,
    loaded: state.loaded,
  })
);

export const selectPlannerDateRange = createSelector(
  selectJobPlanner,
  jobPlanner => ({
    startDate: jobPlanner.startDate,
    endDate: jobPlanner.endDate,
  })
);
