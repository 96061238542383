import { ContractExtraTotalTime } from '@app/models';
import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';

export const {
  initialState: contractExtraTotalTimeInitialState,
  selectors: contractExtraTotalTimeSelectors,
  facade: ContractExtraTotalTimeFacadeBase,
} = buildState(ContractExtraTotalTime);

export function contractExtraTotalTimeReducer(
  state = contractExtraTotalTimeInitialState
): IEntityState<ContractExtraTotalTime> {
  return state;
}
