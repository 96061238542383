import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { ImportStatus } from '@app/models';

export const {
  initialState: importStatusInitialState,
  selectors: importStatusSelectors,
  facade: ImportStatusFacadeBase,
} = buildState(ImportStatus);

export function importStatusReducer(
  state = importStatusInitialState
): IEntityState<ImportStatus> {
  return state;
}
