import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobLocate } from '@app/models';

export const {
  initialState: jobLocateInitialState,
  selectors: jobLocateSelectors,
  facade: JobLocateFacadeBase,
} = buildState(JobLocate);

export function jobLocateReducer(
  state = jobLocateInitialState
): IEntityState<JobLocate> {
  return state;
}
