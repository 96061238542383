import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { Crew, CrewVM } from './crew.model';
import { EquipmentType } from './equipment-type.model';

export interface TentativeCrewReq {
  id?: number;
  jobPlanTentative?: string;
  flexCrew: boolean;
  foreman: boolean;
  nonForeman: number;
}

export interface TentativeEquipReq {
  id?: number;
  jobPlanTentative?: string;
  equipmentType: number;
}

export interface TentativeEquipReqVM {
  id?: number;
  jobPlanTentative?: string;
  equipmentType: EquipmentType;
}

@Entity({ modelName: 'JobPlanTentative' })
export class JobPlanTentative {
  @Key id?: string;
  jobPlan: string;
  start: string;
  end: string;
  assignedCrew?: Crew;
  crewReq?: TentativeCrewReq;
  equipReqs?: TentativeEquipReq[];
}

export interface JobPlanTentativeVM {
  id?: string;
  jobPlan: string;
  start: Date;
  end: Date;
  assignedCrew?: CrewVM;
  crewReq?: TentativeCrewReq;
  equipReqs?: TentativeEquipReqVM[];
}
