<ion-app>
  <ion-router-outlet></ion-router-outlet>
  <ion-nav [root]="netReqPage" *ngIf="!isConnected"></ion-nav>
  <ion-nav
    [root]="maintenancePage"
    *ngIf="isConnected && maintenanceMode"
  ></ion-nav>
</ion-app>
<app-upload-progress
  [position]="{ bottom: 100, right: 10 }"
></app-upload-progress>
<app-update-status
  *ngIf="updateAvailable"
  [status]="updateStatus"
></app-update-status>
