import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { BaseData } from '@app/models/base-data.model';

@Injectable({
  providedIn: 'root',
})
export class BaseDataService {
  constructor(private http: HttpClient, private api: ApiService) {}

  loadAll(): Observable<BaseData> {
    return this.http.get<BaseData>(`${this.api.url}/base-data`);
  }
}
