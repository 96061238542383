import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobNote } from '@app/models';

export const {
  initialState: jobNoteInitialState,
  selectors: jobNoteSelectors,
  facade: JobNoteFacadeBase,
} = buildState(JobNote);

export function jobNoteReducer(
  state = jobNoteInitialState
): IEntityState<JobNote> {
  return state;
}
