import { createAction, props } from '@ngrx/store';
import { EquipTypeFilter } from './equipment.reducer';

export const loadAllEquipment = createAction('[Equipment] Load All');
export const loadAllEquipmentSuccess = createAction(
  '[Equipment API] Load All Success'
);
export const loadAllEquipmentError = createAction(
  '[Equipment API] Load All Error',
  props<{ error: any }>()
);

export const loadEquipmentLocations = createAction(
  '[Equipment] Load Locations'
);
export const loadEquipmentLocationsSuccess = createAction(
  '[Equipment] Load Locations Success'
);
export const loadEquipmentLocationsError = createAction(
  '[Equipment API] Load Locations Error',
  props<{ error: any }>()
);

export const setEquipmentTypeFilter = createAction(
  '[Equipment] Set EquipmentType Filter',
  props<EquipTypeFilter>()
);
