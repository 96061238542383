import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { RestorationType } from '@app/models';

export const {
  initialState: restorationTypeInitialState,
  selectors: restorationTypeSelectors,
  facade: RestorationTypeFacadeBase,
} = buildState(RestorationType);

export function restorationTypeReducer(
  state = restorationTypeInitialState
): IEntityState<RestorationType> {
  return state;
}
