import { User } from './user.model';
import { JobMediaThumbnails } from './job-media.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { AttachedMediaVM } from '.';

@Entity({ modelName: 'JobRestorationMedia' })
export class JobRestorationMedia {
  @Key id?: string;
  restorationTicket: string;
  employee: string;
  image?: string;
  thumbnails?: JobMediaThumbnails;
  originalFileName: string;
  imageExists: boolean;
  uploadUrl?: string;
  width?: number;
  height?: number;
  created?: string;
}

export interface JobRestorationMediaVM extends AttachedMediaVM {
  id?: string;
  restorationTicket: string;
  employee: User;
  image?: string;
  thumbnails?: JobMediaThumbnails;
  originalFileName: string;
  imageExists: boolean;
  uploadUrl?: string;
  created?: Date;
}
