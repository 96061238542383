import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { User } from './user.model';

@Entity({ modelName: 'JobNote' })
export class JobNote {
  @Key id?: string;
  job: string;
  employee: string;
  note: string;
  created?: string;
}

export class JobNoteVM {
  id?: string;
  job: string;
  employee: User;
  note: string;
  created?: Date;
}
