import { createEffect, ofType, Actions } from '@ngrx/effects';
import { LoadAllSuccess } from '@briebug/ngrx-auto-entity';
import {
  loadAllSubcontractors,
  loadAllSubcontractorsSuccess,
  loadAllSubcontractorsError,
} from './subcontractors.actions';
import { exhaustMap, mergeMap, catchError, map } from 'rxjs/operators';
import { SubcontractorsService } from '@services/api/subcontractors.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import {
  SubcontractorCategory,
  SubcontractorVendor,
  Vendor,
} from '@app/models';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SubcontractorsEffects {
  loadAllSubcontractors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllSubcontractors),
      exhaustMap(() =>
        this.subcontractorsService.loadAll().pipe(
          map(subcontractors => {
            this.store$.dispatch(
              new LoadAllSuccess(
                SubcontractorCategory,
                subcontractors.subcontractorCategories
              )
            );
            this.store$.dispatch(
              new LoadAllSuccess(
                SubcontractorVendor,
                subcontractors.subcontractorVendors
              )
            );
            this.store$.dispatch(
              new LoadAllSuccess(Vendor, subcontractors.vendors)
            );
            return loadAllSubcontractorsSuccess();
          }),
          catchError(error => {
            return of(loadAllSubcontractorsError({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private subcontractorsService: SubcontractorsService,
    private store$: Store<AppState>
  ) {}
}
