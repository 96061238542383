import { createSelector } from '@ngrx/store';
import { userSelectors } from './user.state';
import { userRoleSelectors } from './user-role.state';
import { UserVM } from '@app/models';

export const selectUserMenu = createSelector(
  userSelectors.selectAll,
  userRoleSelectors.selectEntities,
  (users, roles): UserVM[] => {
    return users
      .filter(user => user.active)
      .map(user => ({
        ...user,
        primaryRole: user.primaryRole in roles ? roles[user.primaryRole] : null,
      }));
  }
);

export const selectUsersInactive = createSelector(
  userSelectors.selectAll,
  userRoleSelectors.selectEntities,
  (users, roles): UserVM[] => {
    return users
      .filter(user => !user.active)
      .map(user => ({
        ...user,
        primaryRole: user.primaryRole in roles ? roles[user.primaryRole] : null,
      }));
  }
);

export const selectUsersVM = createSelector(
  userSelectors.selectAll,
  userRoleSelectors.selectEntities,
  (users, roles): UserVM[] => {
    return users.map(user => ({
      ...user,
      primaryRole: user.primaryRole in roles ? roles[user.primaryRole] : null,
    }));
  }
);

export const selectUserVMEntities = createSelector(
  selectUserMenu,
  (users): { [key: string]: UserVM } => {
    const payload: { [key: string]: UserVM } = {};
    users.forEach(user => {
      payload[user.id] = user;
    });
    return payload;
  }
);

export const selectAllUserVMEntities = createSelector(
  selectUsersVM,
  (users): { [key: string]: UserVM } => {
    const payload: { [key: string]: UserVM } = {};
    users.forEach(user => {
      payload[user.id] = user;
    });
    return payload;
  }
);
