import { Injectable } from '@angular/core';
import { AppState, JobPlanEquipReqFacadeBase } from '@app/state';
import { Store } from '@ngrx/store';
import { JobPlanEquipReq } from '@app/models';

@Injectable({ providedIn: 'root' })
export class JobPlanEquipReqFacade extends JobPlanEquipReqFacadeBase {
  constructor(private store: Store<AppState>) {
    super(JobPlanEquipReq, store);
  }
}
