import { EquipmentType, JobPlanEquipReqVM, Job } from '@app/models';
import { Entity, Key } from '@briebug/ngrx-auto-entity';

export interface JobPlanLinkedEquipDate {
  date: string;
  startTime: string;
  endTime: string;
}

export interface JobPlanLinkedEquipDateVM {
  date: Date;
  startTime: Date;
  endTime: Date;
  status: string;
}

export interface JobPlanLinkedEquipDateStatus {
  date: string;
  status: string;
}

export interface JobPlanLinkedEquipDateStatusVM {
  date: Date;
  status: string;
}

@Entity({ modelName: 'JobPlanLinkedEquip' })
export class JobPlanLinkedEquip {
  @Key id?: string;
  equipmentType: number;
  jobPlan: string;
  equipmentReq: string;
  dates: JobPlanLinkedEquipDate[];
  datesStatus?: JobPlanLinkedEquipDateStatus[];
}

export interface JobPlanLinkedEquipVM {
  id?: string;
  equipmentType: EquipmentType;
  jobPlan: string;
  equipmentReq: JobPlanEquipReqVM;
  reqJob: Job;
  dates: JobPlanLinkedEquipDateVM[];
  datesStatus: JobPlanLinkedEquipDateStatusVM[];
}
