import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobPlanAlarm' })
export class JobPlanAlarm {
  @Key id?: string;
  jobPlan: string;
  description: string;
  date: string;
}

export interface JobPlanAlarmVM {
  id?: string;
  jobPlan: string;
  description: string;
  date: Date;
}
