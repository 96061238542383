import { IEntityState } from '@briebug/ngrx-auto-entity';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import {
  ContractExtra,
  EquipmentClass,
  EquipmentType,
  EquipmentModel,
  EquipmentMachine,
  EquipmentOptionType,
  EquipmentOption,
  EquipmentUntrackedClass,
  EquipmentUntrackedMachine,
  User,
  UserRole,
  ImportStatus,
  JobEquipment,
  MaterialCategory,
  MaterialVendor,
  SubcontractorCategory,
  SubcontractorVendor,
  Vendor,
  RestorationCategory,
  RestorationType,
  RestorationVendor,
  Job,
  JobPlanEquipReq,
  TruckingRequirement,
  TruckingMove,
  TruckingEquipLocation,
  JobPlan,
  JobPlanManager,
  JobPlanManaged,
  JobPlanCrewRequirement,
  JobPlanCrewAssignment,
  Crew,
  JobLocate,
  JobPlanTentative,
  JobDocument,
  JobMedia,
  JobNote,
  FormStatus,
  JobIncident,
  JobIncidentMedia,
  JobMaterial,
  JobSubcontractor,
  JobRestoration,
  JobSiteEquipment,
  JobDateCrew,
  JobDateEquipment,
  JobDateEquipTimecard,
  JobDateEquipOptTimecard,
  JobDateTimecard,
  JobDateUntrackedEquipTimecard,
  JobFieldLog,
  JobFieldLogTotalTime,
  EmergencyTicket,
  JobExtra,
  ShopLocation,
  JobPlanSiteEquipment,
  JobPlanLinkedEquip,
  JobDateNote,
  EquipRepairTicket,
  ContractExtraFieldLog,
  ContractExtraTotalTime,
  JobPlanAlarm,
  JobRestorationMedia,
  EquipmentUseDate,
  UserTimecard,
  JobPastEquipment,
} from '@app/models';
import { equipClassReducer } from './equipment-class.state';
import { equipTypeReducer } from './equipment-type.state';
import { equipModelReducer } from './equipment-model.state';
import { equipMachineReducer } from './equipment-machine.state';
import { equipOptTypeReducer } from './equipment-option-type.state';
import { equipOptionReducer } from './equipment-option.state';
import * as AppStateState from './app-state/app-state-reducer';
import * as BaseDataState from './base-data/base-data.reducer';
import * as EquipState from './equipment/equipment.reducer';
import * as JobDetailsState from './job-details/job-details.reducer';
import * as JobDateState from './job-date/job-date.reducer';
import * as JobPlannerState from './job-planner/job-planner.reducer';
import * as MaterialsState from './materials/materials.reducer';
import * as MultipleTimeCards from './multiple-timecards/multiple-timecards.reducer';
import * as RestorationState from './restoration/restoration.reducer';
import * as SubcontractorsState from './subcontractors/subcontractors.reducer';
import * as TruckingState from './trucking/trucking.reducer';
import * as UserMe from './user-me/user-me.reducer';
import { equipUntrackedClassReducer } from './equipment-untracked-class.state';
import { equipUntrackedMachineReducer } from './equipment-untracked-machine.state';
import { userReducer } from './user.state';
import { shopLocationReducer } from './shop-location.state';
import { userRoleReducer } from './user-role.state';
import { importStatusReducer } from './import-status.state';
import { materialCategoryReducer } from './material-category.state';
import { materialVendorReducer } from './material-vendor.state';
import { subcontractorCategoryReducer } from './subcontractor-category.state';
import { subcontractorVendorReducer } from './subcontractor-vendor.state';
import { vendorReducer } from './vendor.state';
import { restorationCategoryReducer } from './restoration-category.state';
import { restorationTypeReducer } from './restoration-type.state';
import { restorationVendorReducer } from './restoration-vendor.state';
import { jobEquipmentReducer } from './job-equipment.state';
import { jobReducer } from './job.state';
import { jobPlanEquipReqReducer } from './job-plan-equip-req.state';
import { truckingRequirementReducer } from './trucking-requirement.state';
import { truckingMoveReducer } from './trucking-move.state';
import { truckingEquipLocationReducer } from './trucking-equip-location.state';
import { jobPlanReducer } from './job-plan.state';
import { jobPlanManagerReducer } from './job-plan-manager.state';
import { jobPlanManagedReducer } from './job-plan-managed.state';
import { jobPlanCrewReqReducer } from './job-plan-crew-req.state';
import { jobPlanCrewAssignmentReducer } from './job-plan-crew-assignment.state';
import { crewReducer } from './crew.state';
import { jobLocateReducer } from './job-locate.state';
import { jobPlanTentativeReducer } from './job-plan-tentative.state';
import { jobMediaReducer } from './job-media.state';
import { jobDocumentReducer } from './job-document.state';
import { jobNoteReducer } from './job-note.state';
import { formStatusReducer } from './form-status.state';
import { jobIncidentReducer } from './job-incident.state';
import { jobIncidentMediaReducer } from './job-incident-media.state';
import { jobMaterialReducer } from './job-material.state';
import { jobSubcontractorReducer } from './job-subcontractor.state';
import { jobRestorationReducer } from './job-restoration.state';
import { jobSiteEquipmentReducer } from './job-site-equipment.state';
import { jobDateCrewReducer } from './job-date-crew.state';
import { jobDateEquipmentReducer } from './job-date-equipment.state';
import { jobDateEquipTimecardReducer } from './job-date-equip-timecard.state';
import { jobDateEquipOptTimecardReducer } from './job-date-equip-opt-timecard.state';
import { jobDateTimecardReducer } from './job-date-timecard.state';
import { jobDateUntrackedEquipTimecardReducer } from './job-date-untracked-equip-tc.state';
import { jobFieldLogReducer } from './job-field-log.state';
import { jobFieldLogTotalTimeReducer } from './job-field-log-total-time.state';
import { emergencyTicketReducer } from './emergency-ticket.state';
import { jobExtraReducer } from './job-extra.state';
import { jobPlanSiteEquipmentReducer } from './job-plan-site-equipment.state';
import { jobPlanLinkedEquipReducer } from './job-plan-linked-equip.state';
import { jobDateNoteReducer } from './job-date-note.state';
import { equipRepairTicketReducer } from './equip-repair-ticket.state';
import { contractExtraReducer } from './contract-extra.state';
import { contractExtraFieldLogReducer } from './contract-extra-field-log.state';
import { contractExtraTotalTimeReducer } from './contract-extra-total-time.state';
import { JobAlert } from '@app/models/job-alert.model';
import { jobAlertReducer } from './job-alert.state';
import { jobPlanAlarmReducer } from './job-plan-alarm.state';
import { jobRestorationMediaReducer } from './job-restoration-media.state';
import { equipmentUseDateReducer } from './equipment-use-date.state';
import { userTimecardReducer } from './user-timecards.state';
import { jobPastEquipmentReducer } from './job-past-equipment.state';

export interface IAppState {
  appState: AppStateState.State;
  baseData: BaseDataState.State;
  contractExtra: IEntityState<ContractExtra>;
  contractExtraFieldLog: IEntityState<ContractExtraFieldLog>;
  contractExtraTotalTime: IEntityState<ContractExtraTotalTime>;
  crew: IEntityState<Crew>;
  emergencyTicket: IEntityState<EmergencyTicket>;
  equipRepairTicket: IEntityState<EquipRepairTicket>;
  equipmentClass: IEntityState<EquipmentClass>;
  equipmentType: IEntityState<EquipmentType>;
  equipmentModel: IEntityState<EquipmentModel>;
  equipmentMachine: IEntityState<EquipmentMachine>;
  equipmentOptionType: IEntityState<EquipmentOptionType>;
  equipmentOption: IEntityState<EquipmentOption>;
  equipmentUntrackedClass: IEntityState<EquipmentUntrackedClass>;
  equipmentUntrackedMachine: IEntityState<EquipmentUntrackedMachine>;
  equipmentUseDate: IEntityState<EquipmentUseDate>;
  equipment: EquipState.State;
  formStatus: IEntityState<FormStatus>;
  importStatus: IEntityState<ImportStatus>;
  job: IEntityState<Job>;
  jobDetails: JobDetailsState.State;
  jobDocument: IEntityState<JobDocument>;
  jobIncident: IEntityState<JobIncident>;
  jobIncidentMedia: IEntityState<JobIncidentMedia>;
  jobMedia: IEntityState<JobMedia>;
  jobNote: IEntityState<JobNote>;
  jobAlert: IEntityState<JobAlert>;
  jobDateNote: IEntityState<JobDateNote>;
  jobEquipment: IEntityState<JobEquipment>;
  jobLocate: IEntityState<JobLocate>;
  jobMaterial: IEntityState<JobMaterial>;
  jobSubcontractor: IEntityState<JobSubcontractor>;
  jobRestoration: IEntityState<JobRestoration>;
  jobRestorationMedia: IEntityState<JobRestorationMedia>;
  jobExtra: IEntityState<JobExtra>;
  jobSiteEquipment: IEntityState<JobSiteEquipment>;
  jobDate: JobDateState.State;
  jobDateCrew: IEntityState<JobDateCrew>;
  jobDateEquipment: IEntityState<JobDateEquipment>;
  jobDateEquipTimecard: IEntityState<JobDateEquipTimecard>;
  jobDateEquipOptTimecard: IEntityState<JobDateEquipOptTimecard>;
  jobDateTimecard: IEntityState<JobDateTimecard>;
  jobDateUntrackedEquipTimecard: IEntityState<JobDateUntrackedEquipTimecard>;
  jobFieldLog: IEntityState<JobFieldLog>;
  jobFieldLogTotalTime: IEntityState<JobFieldLogTotalTime>;
  jobPastEquipment: IEntityState<JobPastEquipment>;
  jobPlanner: JobPlannerState.State;
  jobPlan: IEntityState<JobPlan>;
  jobPlanAlarm: IEntityState<JobPlanAlarm>;
  jobPlanManager: IEntityState<JobPlanManager>;
  jobPlanManaged: IEntityState<JobPlanManaged>;
  jobPlanCrewRequirement: IEntityState<JobPlanCrewRequirement>;
  jobPlanCrewAssignment: IEntityState<JobPlanCrewAssignment>;
  jobPlanEquipReq: IEntityState<JobPlanEquipReq>;
  jobPlanLinkedEquip: IEntityState<JobPlanLinkedEquip>;
  jobPlanSiteEquipment: IEntityState<JobPlanSiteEquipment>;
  jobPlanTentative: IEntityState<JobPlanTentative>;
  materials: MaterialsState.State;
  materialCategory: IEntityState<MaterialCategory>;
  materialVendor: IEntityState<MaterialVendor>;
  multipleTimeCards: MultipleTimeCards.State;
  restoration: RestorationState.State;
  restorationCategory: IEntityState<RestorationCategory>;
  restorationType: IEntityState<RestorationType>;
  restorationVendor: IEntityState<RestorationVendor>;
  subcontractors: SubcontractorsState.State;
  subcontractorCategory: IEntityState<SubcontractorCategory>;
  subcontractorVendor: IEntityState<SubcontractorVendor>;
  shopLocation: IEntityState<ShopLocation>;
  trucking: TruckingState.State;
  truckingEquipLocation: IEntityState<TruckingEquipLocation>;
  truckingMove: IEntityState<TruckingMove>;
  truckingRequirement: IEntityState<TruckingRequirement>;
  user: IEntityState<User>;
  userMe: UserMe.State;
  userRole: IEntityState<UserRole>;
  userTimecard: IEntityState<UserTimecard>;
  vendor: IEntityState<Vendor>;
}

export type AppState = IAppState;

export const appReducer: ActionReducerMap<AppState> = {
  appState: AppStateState.reducer,
  baseData: BaseDataState.reducer,
  contractExtra: contractExtraReducer,
  contractExtraFieldLog: contractExtraFieldLogReducer,
  contractExtraTotalTime: contractExtraTotalTimeReducer,
  crew: crewReducer,
  emergencyTicket: emergencyTicketReducer,
  equipRepairTicket: equipRepairTicketReducer,
  equipmentClass: equipClassReducer,
  equipmentType: equipTypeReducer,
  equipmentModel: equipModelReducer,
  equipmentMachine: equipMachineReducer,
  equipmentOptionType: equipOptTypeReducer,
  equipmentOption: equipOptionReducer,
  equipmentUntrackedClass: equipUntrackedClassReducer,
  equipmentUntrackedMachine: equipUntrackedMachineReducer,
  equipmentUseDate: equipmentUseDateReducer,
  equipment: EquipState.reducer,
  formStatus: formStatusReducer,
  importStatus: importStatusReducer,
  job: jobReducer,
  jobDetails: JobDetailsState.reducer,
  jobDocument: jobDocumentReducer,
  jobMedia: jobMediaReducer,
  jobNote: jobNoteReducer,
  jobAlert: jobAlertReducer,
  jobDateNote: jobDateNoteReducer,
  jobEquipment: jobEquipmentReducer,
  jobIncident: jobIncidentReducer,
  jobIncidentMedia: jobIncidentMediaReducer,
  jobLocate: jobLocateReducer,
  jobMaterial: jobMaterialReducer,
  jobSubcontractor: jobSubcontractorReducer,
  jobExtra: jobExtraReducer,
  jobRestoration: jobRestorationReducer,
  jobRestorationMedia: jobRestorationMediaReducer,
  jobSiteEquipment: jobSiteEquipmentReducer,
  jobDate: JobDateState.reducer,
  jobDateCrew: jobDateCrewReducer,
  jobDateEquipment: jobDateEquipmentReducer,
  jobDateEquipTimecard: jobDateEquipTimecardReducer,
  jobDateEquipOptTimecard: jobDateEquipOptTimecardReducer,
  jobDateTimecard: jobDateTimecardReducer,
  jobDateUntrackedEquipTimecard: jobDateUntrackedEquipTimecardReducer,
  jobFieldLog: jobFieldLogReducer,
  jobFieldLogTotalTime: jobFieldLogTotalTimeReducer,
  jobPastEquipment: jobPastEquipmentReducer,
  jobPlanner: JobPlannerState.reducer,
  jobPlan: jobPlanReducer,
  jobPlanAlarm: jobPlanAlarmReducer,
  jobPlanManager: jobPlanManagerReducer,
  jobPlanManaged: jobPlanManagedReducer,
  jobPlanCrewRequirement: jobPlanCrewReqReducer,
  jobPlanCrewAssignment: jobPlanCrewAssignmentReducer,
  jobPlanEquipReq: jobPlanEquipReqReducer,
  jobPlanLinkedEquip: jobPlanLinkedEquipReducer,
  jobPlanSiteEquipment: jobPlanSiteEquipmentReducer,
  jobPlanTentative: jobPlanTentativeReducer,
  materials: MaterialsState.reducer,
  materialCategory: materialCategoryReducer,
  materialVendor: materialVendorReducer,
  multipleTimeCards: MultipleTimeCards.reducer,
  restoration: RestorationState.reducer,
  restorationCategory: restorationCategoryReducer,
  restorationType: restorationTypeReducer,
  restorationVendor: restorationVendorReducer,
  subcontractors: SubcontractorsState.reducer,
  subcontractorCategory: subcontractorCategoryReducer,
  subcontractorVendor: subcontractorVendorReducer,
  shopLocation: shopLocationReducer,
  trucking: TruckingState.reducer,
  truckingEquipLocation: truckingEquipLocationReducer,
  truckingMove: truckingMoveReducer,
  truckingRequirement: truckingRequirementReducer,
  user: userReducer,
  userMe: UserMe.reducer,
  userRole: userRoleReducer,
  userTimecard: userTimecardReducer,
  vendor: vendorReducer,
};

export const appMetaReducers: Array<
  MetaReducer<AppState>
> = !environment.production ? [] : [];
