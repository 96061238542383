import { ApiService } from '../api/api.service';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserTokenData } from '@services/api/user-menu.service';
import { StorageService } from '@services/storage/storage.service';

const TOKEN_KEY = 'authToken';
const FIRST_LOGIN_KEY = 'firstLogin';
const BASE_URL_KEY = 'baseApiUrl';
const BASE_WS_KEY = 'baseWsUrl';
const ACTIVE_USER_KEY = 'user';
const GATEWAY_URL = environment.gatewayUrl; // Gateway URL changes depending on build target
const UPDATE_URL_KEY = 'updateUrl';
const UPDATE_TOKEN_KEY = 'updateToken';
const UPDATE_DEPLOYMENT_KEY = 'updateDeployment';
const VIEW_USER_KEY = 'viewUser';
const VIEW_USER_TOKEN_KEY = 'viewAuthToken';

export interface ApiAuth {
  token: string;
  user: string;
  firstLogin: boolean;
  baseApiUrl: string;
  baseWsUrl: string;
  updateToken: string;
  updateUrl: string;
  deployment: string;
  viewUser: string;
  viewToken: string;
}

export interface AuthStatus extends ApiAuth {
  authenticated: boolean;
  loginError: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject<AuthStatus>({
    authenticated: false,
    token: '',
    firstLogin: false,
    baseApiUrl: '',
    baseWsUrl: '',
    user: null,
    loginError: null,
    updateToken: '',
    updateUrl: '',
    deployment: '',
    viewUser: null,
    viewToken: '',
  });

  loginSub: Subscription;
  firstLoginSub: Subscription;
  token = '';
  private readyPromise: Promise<string>;

  constructor(
    private storage: StorageService,
    private plt: Platform,
    private http: HttpClient,
    private api: ApiService
  ) {
    let readyResolve: (value: string) => void;
    this.readyPromise = new Promise(res => {
      readyResolve = res;
    });

    this.plt.ready().then(() => {
      this.checkStorage().then(() => {
        readyResolve('ready');
      });
    });
  }

  async ready() {
    return this.readyPromise;
  }

  login(payload: { username: string; password: string }) {
    this.loginSub = this.http.post<ApiAuth>(GATEWAY_URL, payload).subscribe(
      async data => {
        const promises = [
          this.storage.set(TOKEN_KEY, data.token),
          this.storage.set(FIRST_LOGIN_KEY, data.firstLogin),
          this.storage.set(BASE_URL_KEY, data.baseApiUrl),
          this.storage.set(ACTIVE_USER_KEY, data.user),
          this.storage.set(BASE_WS_KEY, data.baseWsUrl),
          this.storage.set(UPDATE_URL_KEY, data.updateUrl),
          this.storage.set(UPDATE_TOKEN_KEY, data.updateToken),
          this.storage.set(UPDATE_DEPLOYMENT_KEY, data.deployment),
          this.storage.set(VIEW_USER_KEY, data.user),
          this.storage.set(VIEW_USER_TOKEN_KEY, data.token),
        ];
        const res = await Promise.all(promises);
        this.api.url = data.baseApiUrl;
        this.token = data.token;
        this.authenticationState.next({
          authenticated: true,
          loginError: null,
          ...data,
        });
      },
      error => {
        console.log('error!');
        this.api.url = '';
        this.authenticationState.next({
          authenticated: false,
          token: '',
          firstLogin: false,
          baseApiUrl: '',
          baseWsUrl: '',
          user: null,
          loginError: 'Incorrect Username or Password',
          updateToken: '',
          updateUrl: '',
          deployment: '',
          viewUser: null,
          viewToken: '',
        });
        return null;
      }
    );
  }

  async logout() {
    const promises = [
      this.storage.remove(TOKEN_KEY),
      this.storage.remove(FIRST_LOGIN_KEY),
      this.storage.remove(BASE_URL_KEY),
      this.storage.remove(ACTIVE_USER_KEY),
      this.storage.remove(BASE_WS_KEY),
      this.storage.remove(UPDATE_URL_KEY),
      this.storage.remove(UPDATE_TOKEN_KEY),
      this.storage.remove(UPDATE_DEPLOYMENT_KEY),
      this.storage.remove(VIEW_USER_KEY),
      this.storage.remove(VIEW_USER_TOKEN_KEY),
    ];
    const data = await Promise.all(promises);
    this.api.url = '';
    this.authenticationState.next({
      authenticated: false,
      token: '',
      firstLogin: false,
      baseApiUrl: '',
      baseWsUrl: '',
      user: null,
      loginError: 'You have been logged out.',
      updateToken: '',
      updateUrl: '',
      deployment: '',
      viewUser: null,
      viewToken: '',
    });
  }

  async authenticationStatus() {
    await this.ready();
    return this.authenticationState.value;
  }

  async userView(user: UserTokenData) {
    const promises = [
      this.storage.set(VIEW_USER_KEY, user.user),
      this.storage.set(VIEW_USER_TOKEN_KEY, user.token),
    ];
    const data = await Promise.all(promises);
    this.checkStorage();
  }

  async defaultView() {
    const authData = await this.authenticationStatus();
    const promises = [
      this.storage.set(VIEW_USER_KEY, authData.user),
      this.storage.set(VIEW_USER_TOKEN_KEY, authData.token),
    ];
    const data = await Promise.all(promises);
    this.checkStorage();
  }

  processFirstLogin(newPassword: string) {
    const authState = this.authenticationState.value;
    this.http
      .patch(this.api.url + `users/${authState.user}/`, {
        password: newPassword,
        first_login: false,
      })
      .subscribe(data => {
        this.storage.set(FIRST_LOGIN_KEY, false).then(() => {
          this.authenticationState.next({
            ...authState,
            firstLogin: false,
          });
        });
      });
  }

  async checkStorage() {
    const keys = [
      TOKEN_KEY,
      FIRST_LOGIN_KEY,
      BASE_URL_KEY,
      ACTIVE_USER_KEY,
      BASE_WS_KEY,
      UPDATE_URL_KEY,
      UPDATE_TOKEN_KEY,
      UPDATE_DEPLOYMENT_KEY,
      VIEW_USER_KEY,
      VIEW_USER_TOKEN_KEY,
    ];
    const res = await this.getMultipleKeys(keys);
    if (res) {
      const auth = TOKEN_KEY in res && res[TOKEN_KEY] ? true : false;
      console.log(`setting api url to ${res[BASE_URL_KEY]}`);
      this.api.url = res[BASE_URL_KEY];
      this.api.wsUrl = res[BASE_WS_KEY];
      this.token = res[TOKEN_KEY];
      const state: AuthStatus = {
        authenticated: auth,
        token: res[TOKEN_KEY],
        firstLogin: res[FIRST_LOGIN_KEY],
        baseApiUrl: res[BASE_URL_KEY],
        baseWsUrl: res[BASE_WS_KEY],
        user: res[ACTIVE_USER_KEY],
        loginError: '',
        updateToken: res[UPDATE_TOKEN_KEY],
        updateUrl: res[UPDATE_URL_KEY],
        deployment: res[UPDATE_DEPLOYMENT_KEY],
        viewUser: res[VIEW_USER_KEY]
          ? res[VIEW_USER_KEY]
          : res[ACTIVE_USER_KEY],
        viewToken: res[VIEW_USER_TOKEN_KEY]
          ? res[VIEW_USER_TOKEN_KEY]
          : res[TOKEN_KEY],
      };
      this.authenticationState.next(state);
    } else {
      this.api.url = '';
      this.token = '';
      const state: AuthStatus = {
        authenticated: false,
        token: '',
        firstLogin: false,
        baseApiUrl: '',
        baseWsUrl: '',
        user: null,
        loginError: null,
        updateToken: '',
        updateUrl: '',
        deployment: '',
        viewUser: null,
        viewToken: '',
      };
      this.authenticationState.next(state);
    }
  }

  async getMultipleKeys(keys: string[]) {
    const promises = [];

    keys.forEach(key => promises.push(this.storage.get(key)));

    const values = await Promise.all(promises);
    const result = {};
    values.map((value, index) => {
      result[keys[index]] = value;
    });
    return result;
  }
}
