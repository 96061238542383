import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobEquipment' })
export class JobEquipment {
  @Key id?: string;
  job?: string;
  tmpDrop?: boolean;
  tmpLat?: number;
  tmpLng?: number;
  tmpAddress?: string;
  tmpCity?: string;
  tmpState?: string;
  equip: number;
  equipRequirement?: string;
  status: string;
  inTrucking?: string;
  outTrucking?: string;
}
