import { filter, map, pairwise, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { S3UploadService, S3UploadPayload } from './s3-upload.service';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { JobMedia } from '@app/models';
import { Injectable } from '@angular/core';
import { Update, UpdateSuccess } from '@briebug/ngrx-auto-entity';
import { Store } from '@ngrx/store';
import { AppState, jobMediaSelectors } from '@app/state';

export interface CreateJobMediaPayload {
  jobMedia: JobMedia;
  image: File;
}

@Injectable({
  providedIn: 'root',
})
export class JobMediaService {
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private s3Uploader: S3UploadService,
    private store$: Store<AppState>
  ) {}

  getLink(mediaId: string): Observable<string> {
    return this.http
      .get<JobMedia>(`${this.api.url}/jobs/media/${mediaId}`)
      .pipe(map(result => result.image));
  }

  create(payload: JobMedia): Observable<JobMedia> {
    return this.http.post<JobMedia>(`${this.api.url}/jobs/media`, payload);
  }

  uploadFile(payload: CreateJobMediaPayload) {
    console.log(`Uploading to ${payload.jobMedia.uploadUrl}`);
    const s3Payload: S3UploadPayload = {
      url: payload.jobMedia.uploadUrl,
      file: payload.image,
      messages: {
        connected: 'Connected...',
        uploadProgress: `Uploading ${payload.image.name}...`,
        uploadProgressComplete: `Processing ${payload.image.name} on server...`,
      },
      entityType: 'JobMedia',
      entityId: payload.jobMedia.id,
      onComplete: () => {
        const jobMedia: JobMedia = {
          ...payload.jobMedia,
          imageExists: true,
        };
        this.store$.dispatch(new Update(JobMedia, jobMedia));
        return this.store$.select(jobMediaSelectors.selectIsSaving).pipe(
          pairwise(),
          filter(([a, b]) => a === true && b === false),
          take(1)
        );
      },
    };
    this.s3Uploader.upload(s3Payload);
  }

  bulkDownload$(jobId: string) {
    return this.http.get(`${this.api.url}/jobs/${jobId}/bulk-media`, {
      responseType: 'blob',
    });
  }

  continueUpload(id: string, image: File) {
    this.http
      .get<JobMedia>(`${this.api.url}/jobs/media/${id}`)
      .subscribe(jobMedia => {
        this.uploadFile({
          jobMedia,
          image,
        });
      });
  }
}
