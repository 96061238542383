import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'RestorationVendor' })
export class RestorationVendor {
  @Key id?: number;
  vendor: string;
  vendorName?: string;
  vendorEmail?: string;
  categories: number[];
}
