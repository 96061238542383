import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { IEntityInfo, IAutoEntityService } from '@briebug/ngrx-auto-entity';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TruckingMove } from '@app/models';
import { JobEquipmentMoveTime } from '@app/models/job-equipment-move-time.model';

export interface CreateFromTicketPayload {
  ticket: string;
  shop: string;
}

export interface UnloadMachinesPayload {
  jobEquipmentMoveTime: JobEquipmentMoveTime;
  dropoffTime: string;
  moves: string[];
  tmpDrop: boolean;
  tmpLat?: number;
  tmpLng?: number;
  nightPay: boolean;
}

export interface RerouteMachinePayload {
  move: string;
  rerouteType: string;
  equipReq?: string;
  job?: string;
  shop?: string;
  release?: boolean;
}

@Injectable({ providedIn: 'root' })
export class TruckingMoveService implements IAutoEntityService<any> {
  constructor(private http: HttpClient, private api: ApiService) {}

  load(entityInfo: IEntityInfo, id: string): Observable<TruckingMove> {
    return this.http.get<TruckingMove>(`${this.api.url}/trucking/moves/${id}`);
  }

  loadAll(entityInfo: IEntityInfo): Observable<TruckingMove[]> {
    return this.http.get<TruckingMove[]>(`${this.api.url}/trucking/moves`);
  }

  create(
    entityInfo: IEntityInfo,
    entity: TruckingMove
  ): Observable<TruckingMove> {
    return this.http.post<TruckingMove>(
      `${this.api.url}/trucking/moves`,
      entity
    );
  }

  createFromTicket(payload: CreateFromTicketPayload): Observable<TruckingMove> {
    return this.http.post<TruckingMove>(
      `${this.api.url}/trucking/moves/ticket`,
      payload
    );
  }

  update(
    entityInfo: IEntityInfo,
    entity: TruckingMove
  ): Observable<TruckingMove> {
    return this.http.patch<TruckingMove>(
      `${this.api.url}/trucking/moves/${entity.id}`,
      entity
    );
  }

  updateMany(
    entityInfo: IEntityInfo,
    entities: TruckingMove[]
  ): Observable<TruckingMove[]> {
    return this.http.put<TruckingMove[]>(
      `${this.api.url}/trucking/moves`,
      entities
    );
  }

  loadMachine(
    entityInfo: IEntityInfo,
    entity: TruckingMove
  ): Observable<TruckingMove> {
    return this.http.patch<TruckingMove>(
      `${this.api.url}/trucking/moves/load`,
      entity
    );
  }

  unloadMachines(payload: UnloadMachinesPayload): Observable<TruckingMove[]> {
    return this.http.patch<TruckingMove[]>(
      `${this.api.url}/trucking/moves/unload`,
      payload
    );
  }

  rerouteMachine(payload: RerouteMachinePayload): Observable<TruckingMove> {
    return this.http.patch<TruckingMove>(
      `${this.api.url}/trucking/moves/reroute`,
      payload
    );
  }

  delete(
    entityInfo: IEntityInfo,
    entity: TruckingMove
  ): Observable<TruckingMove> {
    return this.http
      .delete<any>(`${this.api.url}/trucking/moves/${entity.id}`)
      .pipe(map(() => entity));
  }
}
