import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { FormStatus } from '@app/models';

export const {
  initialState: formStatusInitialState,
  selectors: formStatusSelectors,
  facade: FormStatusFacadeBase,
} = buildState(FormStatus);

export function formStatusReducer(
  state = formStatusInitialState
): IEntityState<FormStatus> {
  return state;
}
