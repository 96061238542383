import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobLocate' })
export class JobLocate {
  @Key id?: string;
  job: string;
  ticketNumber: string;
  ticketType: string;
  ticketTimestamp?: string;
  start: string;
  end?: string;
  renewBy?: string;
}

export class JobLocateVM {
  id?: string;
  job: string;
  ticketNumber: string;
  ticketType: string;
  ticketTimestamp?: Date;
  start: Date;
  end?: Date;
  renewBy?: Date;
}
