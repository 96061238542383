import { AppState } from './../app.state';
import { createReducer, Action, on, createFeatureSelector } from '@ngrx/store';
import * as actions from './base-data.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: null,
};

const baseDataReducer = createReducer(
  initialState,
  on(actions.loadAllBaseData, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(actions.loadAllBaseDataSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return baseDataReducer(state, action);
}

export const selectBaseData = createFeatureSelector<State>('baseData');
