import { createAction, props } from '@ngrx/store';
import {
  JobPlannerDateParams,
  JobPlannerExtendDatesParams,
} from '@services/api/job-planner.service';

export const loadJobPlanner = createAction(
  '[Job Planner] Load',
  props<{ dateRange: JobPlannerDateParams }>()
);
export const loadJobPlannerSuccess = createAction(
  '[Job Planner API] Load Success',
  props<{ dateRange: JobPlannerDateParams }>()
);
export const loadJobPlannerError = createAction(
  '[Job Planner API] Load Error',
  props<{ error: any }>()
);

export const updateJobPlannerDates = createAction(
  '[Job Planner] Update Dates',
  props<{ payload: JobPlannerExtendDatesParams }>()
);
export const updateJobPlannerDatesSuccess = createAction(
  '[Job Planner] Update Dates Success',
  props<{ payload: JobPlannerExtendDatesParams }>()
);
export const updateJobPlannerDatesError = createAction(
  '[Job Planner API] Update Dates Error',
  props<{ error: any }>()
);

export const setViewOptions = createAction(
  '[Job Planner] Set View Options',
  props<{ payload: { onHold: boolean; completed: boolean } }>()
);
