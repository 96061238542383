import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'EquipRepairTicket' })
export class EquipRepairTicket {
  @Key id?: string;
  equipment: number;
  employee: string;
  title: string;
  fieldNotes: string;
  truckingNotes?: string;
  inoperable: boolean;
  repairStatus: string;
  recallDate?: string;
  created?: string;
}

export interface EquipRepairMediaThumbnails {
  small: string;
  medium: string;
  large: string;
}

export interface EquipRepairTicketPicture {
  id?: string;
  repairTicket: string;
  employee: string;
  image?: string;
  thumbnails?: EquipRepairMediaThumbnails;
  originalFileName: string;
  imageExists: boolean;
  uploadUrl?: string;
  created?: string;
}

export interface EquipRepairTicketRepairNote {
  id?: string;
  repairTicket: string;
  employee: string;
  notes: string;
  created?: string;
}

export interface EquipRepairTicketsResponse {
  repairTickets: EquipRepairTicket[];
  repairTicketNotes: EquipRepairTicketRepairNote[];
  repairTicketPictures: EquipRepairTicketPicture[];
}
