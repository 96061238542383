import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobRestoration } from '@app/models';

export const {
  initialState: jobRestorationInitialState,
  selectors: jobRestorationSelectors,
  facade: JobRestorationFacadeBase,
} = buildState(JobRestoration);

export function jobRestorationReducer(
  state = jobRestorationInitialState
): IEntityState<JobRestoration> {
  return state;
}
