import { EquipmentOptionType } from './equipment-option-type.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';

export const HourlyCalcMap = {
  ADD: 'Add to parent hours',
  REP: 'Replace parent hours',
};

@Entity({ modelName: 'EquipmentOption' })
export class EquipmentOption {
  @Key id: number;
  equipment: number;
  optionType: number;
  billable: boolean;
  oneTime: boolean;
  oneTimeCode: string;
  hourly: boolean;
  hourlyCode: string;
  hourlyCalc: string;
}

export interface EquipmentOptionVM {
  id: number;
  equipment: number;
  optionType: EquipmentOptionType;
  billable: boolean;
  oneTime: boolean;
  oneTimeCode: string;
  hourly: boolean;
  hourlyCode: string;
  hourlyCalc: string;
}
