import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { EquipmentOption } from '@app/models';

export const {
  initialState: equipOptionInitialState,
  selectors: equipOptionSelectors,
  facade: EquipOptionFacadeBase,
} = buildState(EquipmentOption);

export function equipOptionReducer(
  state = equipOptionInitialState
): IEntityState<EquipmentOption> {
  return state;
}
