import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ContractExtra,
  ContractExtraFieldLog,
  ContractExtraTotalTime,
  JobDateEquipOptTimecard,
  JobDateEquipTimecard,
  JobMaterial,
  JobSubcontractor,
} from '@app/models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ClockOutEquipPayload } from './job-date.service';

export interface FullFieldLog {
  fieldLog: ContractExtraFieldLog;
  totalTime?: ContractExtraTotalTime;
  equipTime: any[];
  equipOptTime: any[];
  materials: JobMaterial[];
  subcontractors: JobSubcontractor[];
}

export interface CreateFullFieldLogResponse extends FullFieldLog {
  contractExtra: ContractExtra;
}

export interface JobContractExtrasDetailResponse {
  extras: ContractExtra[];
  fieldLogs: ContractExtraFieldLog[];
  totalTime: ContractExtraTotalTime[];
  equip: JobDateEquipTimecard[];
  equipOpt: JobDateEquipOptTimecard[];
  materials: JobMaterial[];
  subcontractors: JobSubcontractor[];
}

export interface CreateFieldLogPayload {
  contractExtra?: ContractExtra;
  fieldLog: ContractExtraFieldLog;
  totalTime?: ContractExtraTotalTime;
  equip: ClockOutEquipPayload[];
  materials: JobMaterial[];
  subcontractors: JobSubcontractor[];
}

@Injectable({
  providedIn: 'root',
})
export class ContractExtraFieldLogService {
  constructor(private http: HttpClient, private api: ApiService) {}

  createFull(payload: CreateFieldLogPayload) {
    return this.http.post<CreateFullFieldLogResponse>(
      `${this.api.url}/jobs/contract-extras/field-logs/create-full`,
      payload
    );
  }

  loadFromJob(jobId: string): Observable<JobContractExtrasDetailResponse> {
    return this.http.get<JobContractExtrasDetailResponse>(
      `${this.api.url}/jobs/${jobId}/contract-extras/field-logs`
    );
  }
}
