import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { DeviceAPNS } from '@app/models/device-apns.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PushRegistrationService {
  constructor(private http: HttpClient, private api: ApiService) {}

  registerAPNS(payload: DeviceAPNS): Observable<DeviceAPNS> {
    return this.http.post<DeviceAPNS>(`${this.api.url}/devices/apns`, payload);
  }
}
