import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { User } from './user.model';
import { UserRole } from './user-role.model';

@Entity({ modelName: 'JobDocument' })
export class JobDocument {
  @Key id?: string;
  job: string;
  employee: string;
  title: string;
  fileName: string;
  fileExists?: boolean;
  document?: string;
  mimeType?: string;
  created?: string;
  access: number[];
  uploadUrl?: string;
}

export interface JobDocumentVM {
  id?: string;
  job: string;
  employee: User;
  title: string;
  fileName: string;
  fileExists?: boolean;
  document?: string;
  mimeType?: string;
  created?: Date;
  access: UserRole[];
  uploadUrl?: string;
}
