import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobMaterial } from '@app/models';

export const {
  initialState: jobMaterialInitialState,
  selectors: jobMaterialSelectors,
  facade: JobMaterialFacadeBase,
} = buildState(JobMaterial);

export function jobMaterialReducer(
  state = jobMaterialInitialState
): IEntityState<JobMaterial> {
  return state;
}
