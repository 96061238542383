import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobSubcontractor } from '@app/models';

export const {
  initialState: jobSubcontractorInitialState,
  selectors: jobSubcontractorSelectors,
  facade: JobSubcontractorFacadeBase,
} = buildState(JobSubcontractor);

export function jobSubcontractorReducer(
  state = jobSubcontractorInitialState
): IEntityState<JobSubcontractor> {
  return state;
}
