import { createEffect, ofType, Actions } from '@ngrx/effects';
import { LoadAllSuccess } from '@briebug/ngrx-auto-entity';
import {
  loadAllRestoration,
  loadAllRestorationSuccess,
  loadAllRestorationError,
} from './restoration.actions';
import { exhaustMap, catchError, map } from 'rxjs/operators';
import { RestorationService } from '@services/api/restoration.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import {
  RestorationCategory,
  RestorationVendor,
  Vendor,
  RestorationType,
} from '@app/models';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class RestorationEffects {
  loadAllRestoration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllRestoration),
      exhaustMap(() =>
        this.restorationsService.loadAll().pipe(
          map(restorations => {
            this.store$.dispatch(
              new LoadAllSuccess(
                RestorationCategory,
                restorations.restorationCategories
              )
            );
            this.store$.dispatch(
              new LoadAllSuccess(
                RestorationVendor,
                restorations.restorationVendors
              )
            );
            this.store$.dispatch(
              new LoadAllSuccess(RestorationType, restorations.restorationTypes)
            );
            this.store$.dispatch(
              new LoadAllSuccess(Vendor, restorations.vendors)
            );
            return loadAllRestorationSuccess();
          }),
          catchError(error => {
            return of(loadAllRestorationError({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private restorationsService: RestorationService,
    private store$: Store<AppState>
  ) {}
}
