import { User } from './user.model';
import { AttachedMediaVM, JobMediaThumbnails } from './job-media.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobIncidentMedia' })
export class JobIncidentMedia {
  @Key id?: string;
  jobIncident: string;
  employee: string;
  image?: string;
  thumbnails?: JobMediaThumbnails;
  originalFileName: string;
  imageExists: boolean;
  uploadUrl?: string;
  created?: string;
  width?: number;
  height?: number;
}

export interface JobIncidentMediaVM extends AttachedMediaVM {
  id?: string;
  jobIncident: string;
  employee: User;
  thumbnails?: JobMediaThumbnails;
  originalFileName: string;
  imageExists: boolean;
  uploadUrl?: string;
  created?: Date;
}
