import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { User } from './user.model';

@Entity({ modelName: 'JobExtra' })
export class JobExtra {
  @Key id?: string;
  job: string;
  employee: string;
  title: string;
  description: string;
  created?: string;
  date: string;
}

export interface JobExtraVM {
  id?: string;
  job: string;
  employee: User;
  title: string;
  description: string;
  created?: Date;
  date: Date;
}
