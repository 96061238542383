import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'ShopLocation' })
export class ShopLocation {
  @Key id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  lat: number;
  lng: number;
  geocoordStatus: string;
}
