import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPastEquipment } from '@app/models';

export const {
  initialState: jobPastEquipmentInitialState,
  selectors: jobPastEquipmentSelectors,
  facade: JobPastEquipmentFacadeBase,
} = buildState(JobPastEquipment);

export function jobPastEquipmentReducer(
  state = jobPastEquipmentInitialState
): IEntityState<JobPastEquipment> {
  return state;
}
