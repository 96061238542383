import { selectBaseData } from './base-data.reducer';
import { createSelector } from '@ngrx/store';

export const selectBaseDataState = createSelector(
  selectBaseData,
  baseData => {
    return baseData;
  }
);

export const selectBaseDataLoaded = createSelector(
  selectBaseData,
  baseData => {
    return baseData.loaded;
  }
);
