import { Job, JobRestoration, JobRestorationMedia } from '@app/models';
import { LoadAllSuccess } from '@briebug/ngrx-auto-entity';
import { exhaustMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppState } from '@app/state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, Store } from '@ngrx/store';
import { JobService } from '@services/api/job.service';

export const loadActiveRestoration = createAction(
  '[Job Restoration] LoadActive'
);

@Injectable()
export class JobRestorationEffects {
  loadActiveRestoration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadActiveRestoration),
        exhaustMap(action =>
          this.jobService.loadActiveRestoration().pipe(
            map(resp => {
              this.store$.dispatch(
                new LoadAllSuccess(JobRestoration, resp.tickets)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobRestorationMedia, resp.media)
              );
              this.store$.dispatch(new LoadAllSuccess(Job, resp.jobs));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobService: JobService,
    private store$: Store<AppState>
  ) {}
}
