import { SimpleJob } from '@services/api/job-search.service';
import { EquipmentOptionVM } from '@app/models';
import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { EquipmentModel } from './equipment-model.model';
import { EquipmentType } from './equipment-type.model';
import { EquipmentOption } from './equipment-option.model';
import { Job } from './job.model';
import { JobEquipment } from './job-equipment.model';
import { ShopLocation } from './shop-location.model';

export const EquipMachineBillingMap = {
  HR: 'Hourly',
  DY: 'Daily',
  NA: 'Not Billed',
};

export interface EquipmentRentalDetails {
  id?: number;
  billingCode: string;
  equipMake: string;
  equipModel: string;
  rentedDate: string;
  returnedDate?: string;
}

@Entity({ modelName: 'EquipmentMachine' })
export class EquipmentMachine {
  @Key id?: number;
  number: string;
  billingType: 'HR' | 'DY' | 'NA' | '';
  transportType: string;
  active: boolean;
  qrCode?: string;
  makeModel?: number;
  rental: boolean;
  rentalType?: number;
  rentalDetails?: EquipmentRentalDetails;
}

export interface EquipmentMachineVM {
  id?: number;
  number: string;
  billingType: string;
  transportType: string;
  active: boolean;
  qrCode?: string;
  makeModel?: EquipmentModel;
  equipType: EquipmentType;
  options: EquipmentOptionVM[];
  rental: boolean;
  rentalType?: number;
  rentalDetails?: EquipmentRentalDetails;
}

export interface EquipmentMachineFlat {
  machine: EquipmentMachine;
  rental: boolean;
  makeModel: EquipmentModel;
  equipType: EquipmentType;
  jobEquipment: JobEquipment;
  options: EquipmentOption[];
}

export interface EquipmentMachinesLocation {
  job: Job;
  machines: EquipmentMachineFlat[];
}

export interface RepairTicket {
  id?: string;
  equipment: number;
  employee: string;
  title: string;
  fieldNotes: string;
  inoperable: boolean;
  repairStatus: string;
  created?: string;
}

export interface RepairTicketPicture {
  id?: string;
  repairTicket: string;
  image: string;
  originalFileName: string;
  imageExists: string;
  remoteFileName: string;
  created?: string;
}

export interface RepairTicketRepairNote {
  id?: string;
  repairTicket: string;
  employee: string;
  notes: string;
  created?: string;
}

export interface MachineLocation {
  inTransit: boolean;
  shop: ShopLocation;
  job: SimpleJob;
  tmp: {
    address: string;
    city: string;
    state: string;
    lat: number;
    lng: number;
  };
}
