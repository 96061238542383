import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { FormStatusFacade } from '@app/facades/form-status.facade';
import { Subscription } from 'rxjs';
import { FormStatus } from '@app/models';
import { Store, select } from '@ngrx/store';
import { AppState, formStatusSelectors } from '@app/state';

interface ProgressBar {
  pctComplete: number;
  message: string;
}

@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss'],
})
export class UploadProgressComponent implements OnInit, OnDestroy {
  @Input() position: {
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
  } = { top: 5, right: 5 };

  progressBars: ProgressBar[] = [];
  subs = new Subscription();

  constructor(
    private formStatus: FormStatusFacade,
    private store$: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.subs.add(
      this.formStatus.all$.subscribe(fsArray => {
        // Is it an ongoing problem that upload progress is no longer in zone?
        this.ngZone.run(() => {
          this.progressBars = fsArray.map(fs => ({
            pctComplete: (fs.uploaded / fs.fileSize) * 100.0,
            message: fs.message,
          }));
        });
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getPosition() {
    const style = {};
    for (const key of Object.keys(this.position)) {
      style[key] = this.position[key] + 'px';
    }
    return style;
  }
}
