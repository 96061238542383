import { MultipleTimeCards } from '@app/models/multiple-time-cards.model';
import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { AppState } from '..';
import * as actions from './multiple-timecards.actions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State extends MultipleTimeCards {}

export const initialState: State = {
  timecards: [],
};

const multipleTimeCardsReducer = createReducer(
  initialState,
  on(actions.loadMultipleTimeCardsSuccess, (state, { payload }) => ({
    ...state,
    timecards: [...payload],
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return multipleTimeCardsReducer(state, action);
}

export const selectMultipleTimeCardsFeature = createFeatureSelector<State>(
  'multipleTimeCards'
);
