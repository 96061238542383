import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { EquipmentUntrackedMachine } from '@app/models';

export const {
  initialState: equipUntrackedMachineInitialState,
  selectors: equipUntrackedMachineSelectors,
  facade: EquipUntrackedMachineFacadeBase,
} = buildState(EquipmentUntrackedMachine);

export function equipUntrackedMachineReducer(
  state = equipUntrackedMachineInitialState
): IEntityState<EquipmentUntrackedMachine> {
  return state;
}
