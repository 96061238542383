<ng-container *ngIf="status">
  <div>
    <h1 class="ion-text-center">Updating ValleyRich App...</h1>
    <h2 class="ion-text-center">
      Step {{ status.step }} of {{ status.totalSteps }}
    </h2>
    <h3 class="ion-text-center">
      {{ status.title }} {{ status.progress | number: '1.1-1' }}%
    </h3>
    <h4 class="ion-text-center" *ngIf="status?.subTitle">
      {{ status.subTitle }}
    </h4>
    <ion-progress-bar
      *ngIf="status.progress"
      [value]="status.progress / 100.0"
    ></ion-progress-bar>
  </div>
</ng-container>
