import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { TruckingRequirement } from '@app/models';

export const {
  initialState: truckingRequirementInitialState,
  selectors: truckingRequirementSelectors,
  facade: TruckingRequirementFacadeBase,
} = buildState(TruckingRequirement);

export function truckingRequirementReducer(
  state = truckingRequirementInitialState
): IEntityState<TruckingRequirement> {
  return state;
}
