import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { ContractExtraTotalTime } from './contract-extra-total-time.model';
import { JobDateEquipTimecardVM } from './job-date-equip-timecard.model';
import { JobMaterialVM } from './job-material.model';
import { JobSubcontractorVM } from './job-subcontractor.model';
import { UserVM } from './user.model';

@Entity({ modelName: 'ContractExtraFieldLog' })
export class ContractExtraFieldLog {
  @Key id?: string;
  contractExtra: string;
  employee: string;
  date: string;
  viewed: boolean;
  created?: string;
}

export interface ContractExtraFieldLogVM {
  id?: string;
  contractExtra: string;
  employee: UserVM;
  date: Date;
  viewed: boolean;
  created?: Date;
  totalTime: ContractExtraTotalTime;
  equipmentTime: JobDateEquipTimecardVM[];
  materials: JobMaterialVM[];
  subcontractors: JobSubcontractorVM[];
}
