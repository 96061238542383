import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Vendor } from '@app/models';

export const {
  initialState: vendorInitialState,
  selectors: vendorSelectors,
  facade: VendorFacadeBase,
} = buildState(Vendor);

export function vendorReducer(
  state = vendorInitialState
): IEntityState<Vendor> {
  return state;
}
