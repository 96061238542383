import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { JobVM } from './job.model';
import { User } from './user.model';

@Entity({ modelName: 'JobAlert' })
export class JobAlert {
  @Key id?: string;
  job: string;
  notes: string;
  employee: string;
  created?: string;
}

export interface JobAlertVM {
  id?: string;
  job: string;
  notes: string;
  employee: User;
  created?: Date;
}
