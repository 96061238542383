import {
  DeleteByKey,
  DeleteByKeySuccess,
  Update,
  UpdateSuccess,
} from '@briebug/ngrx-auto-entity';
import { exhaustMap, map } from 'rxjs/operators';
import { AppState } from './app.state';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, props, Store } from '@ngrx/store';
import {
  PlanSelfServeRequest,
  JobService,
  LoadSelfServeRequest,
  UnloadSelfServeRequest,
  SelfServeReturnShopRequest,
} from '@services/api/job.service';
import { JobSiteEquipment } from '@app/models';

export const returnToShop = createAction(
  '[Job Site Equipment] Return To Shop',
  props<{ payload: SelfServeReturnShopRequest }>()
);

export const planSelfServe = createAction(
  '[Job Site Equipment] Plan Self-Serve',
  props<{ payload: PlanSelfServeRequest }>()
);

export const loadSelfServe = createAction(
  '[Job Site Equipment] Load Self-Serve',
  props<{ payload: LoadSelfServeRequest }>()
);

export const unloadSelfServe = createAction(
  '[Job Site Equipment] Unload Self-Serve',
  props<{ payload: UnloadSelfServeRequest }>()
);

@Injectable()
export class JobSiteEquipmentEffects {
  returnToShop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(returnToShop),
        exhaustMap(action =>
          this.jobService.returnToShop(action.payload).pipe(
            map(resp => {
              if (resp.delete !== null) {
                this.store$.dispatch(
                  new DeleteByKeySuccess(JobSiteEquipment, resp.delete)
                );
              } else if (resp.update !== null) {
                this.store$.dispatch(
                  new UpdateSuccess(JobSiteEquipment, resp.update)
                );
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  planSelfServe$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(planSelfServe),
        exhaustMap(action =>
          this.jobService.planSelfServe(action.payload).pipe(
            map(siteEquipment => {
              this.store$.dispatch(new Update(JobSiteEquipment, siteEquipment));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  loadSelfServe$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadSelfServe),
        exhaustMap(action =>
          this.jobService.loadSelfServe(action.payload).pipe(
            map(siteEquipment => {
              this.store$.dispatch(new Update(JobSiteEquipment, siteEquipment));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  unloadSelfServe$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unloadSelfServe),
        exhaustMap(action =>
          this.jobService.unloadSelfServe(action.payload).pipe(
            map(siteEquipment => {
              this.store$.dispatch(new Update(JobSiteEquipment, siteEquipment));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobService: JobService,
    private store$: Store<AppState>
  ) {}
}
