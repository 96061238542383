import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlanSiteEquipment } from '@app/models';

export const {
  initialState: jobPlanSiteEquipmentInitialState,
  selectors: jobPlanSiteEquipmentSelectors,
  facade: JobPlanSiteEquipmentFacadeBase,
} = buildState(JobPlanSiteEquipment);

export function jobPlanSiteEquipmentReducer(
  state = jobPlanSiteEquipmentInitialState
): IEntityState<JobPlanSiteEquipment> {
  return state;
}
