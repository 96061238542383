import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { User } from './user.model';

export interface CrewMember {
  id?: number;
  user: string;
  foreman: boolean;
}

export interface CrewMemberVM {
  id?: number;
  user: User;
  foreman: boolean;
}

@Entity({ modelName: 'Crew' })
export class Crew {
  @Key id?: string;
  saved: boolean;
  type?: string;
  members: CrewMember[];
}

export interface CrewVM {
  id?: string;
  saved: boolean;
  type?: string;
  members: CrewMemberVM[];
}

export const crewVMToCrew = (cvm: CrewVM): Crew => {
  return {
    ...cvm,
    members: cvm.members.map(member => ({
      ...member,
      user: member.user.id,
    })),
  };
};
