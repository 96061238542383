import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobEquipment } from '@app/models';

export const {
  initialState: jobEquipmentInitialState,
  selectors: jobEquipmentSelectors,
  facade: JobEquipmentFacadeBase,
} = buildState(JobEquipment);

export function jobEquipmentReducer(
  state = jobEquipmentInitialState
): IEntityState<JobEquipment> {
  return state;
}
