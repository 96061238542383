import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobPastEquipment' })
export class JobPastEquipment {
  @Key id?: string;
  equip: string;
  start: string;
  end: string;
}

export class JobPastEquipmentVM {
  id?: string;
  equip: string;
  start: Date;
  end: Date;
}
