import { parseISO } from 'date-fns';
import { createSelector } from '@ngrx/store';
import { jobMaterialSelectors } from './job-material.state';
import { userSelectors } from './user.state';
import { materialCategorySelectors } from './material-category.state';
import { materialVendorSelectors } from './material-vendor.state';
import { JobMaterialVM } from '@app/models';

export const selectJobMaterialVMAll = createSelector(
  jobMaterialSelectors.selectAll,
  materialCategorySelectors.selectEntities,
  materialVendorSelectors.selectEntities,
  userSelectors.selectEntities,
  (materials, categories, vendors, users): JobMaterialVM[] => {
    return materials.map(
      (material): JobMaterialVM => {
        return {
          ...material,
          category:
            material.category in categories
              ? categories[material.category]
              : null,
          vendor: material.vendor in vendors ? vendors[material.vendor] : null,
          employee:
            material.employee in users ? users[material.employee] : null,
          created: material.created ? parseISO(material.created) : null,
          date: parseISO(material.date),
        };
      }
    );
  }
);

export const selectJobMaterialsVMByExtra = createSelector(
  selectJobMaterialVMAll,
  mats => {
    const result: { [key: string]: JobMaterialVM[] } = {};
    mats
      .filter(mat => mat.extraFieldLog)
      .forEach(mat => {
        if (!(mat.extraFieldLog in result)) {
          result[mat.extraFieldLog] = [];
        }
        result[mat.extraFieldLog].push(mat);
      });
    return result;
  }
);
