import { filter, map, pairwise, take } from 'rxjs/operators';
import { JobRestorationMedia } from '@app/models';
import { Observable } from 'rxjs';
import { S3UploadService, S3UploadPayload } from './s3-upload.service';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, jobRestorationMediaSelectors } from '@app/state';
import { Update } from '@briebug/ngrx-auto-entity';

export interface CreateJobRestorationMediaPayload {
  jobRestorationMedia: JobRestorationMedia;
  image: File;
}

@Injectable({
  providedIn: 'root',
})
export class JobRestorationMediaService {
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private s3Uploader: S3UploadService,
    private store$: Store<AppState>
  ) {}

  getLink(mediaId: string): Observable<string> {
    return this.http
      .get<JobRestorationMedia>(
        `${this.api.url}/jobs/restoration/media/${mediaId}`
      )
      .pipe(map(result => result.image));
  }

  create(payload: JobRestorationMedia): Observable<JobRestorationMedia> {
    return this.http.post<JobRestorationMedia>(
      `${this.api.url}/jobs/restoration/media`,
      payload
    );
  }

  uploadFile(payload: CreateJobRestorationMediaPayload): Observable<boolean> {
    console.log(`Uploading to ${payload.jobRestorationMedia.uploadUrl}`);
    const s3Payload: S3UploadPayload = {
      url: payload.jobRestorationMedia.uploadUrl,
      file: payload.image,
      messages: {
        connected: 'Connected...',
        uploadProgress: `Uploading ${payload.image.name}...`,
        uploadProgressComplete: `Processing ${payload.image.name} on server...`,
      },
      entityType: 'JobRestorationMedia',
      entityId: payload.jobRestorationMedia.id,
      onComplete: () => {
        const jobRestorationMedia: JobRestorationMedia = {
          ...payload.jobRestorationMedia,
          imageExists: true,
        };
        this.store$.dispatch(
          new Update(JobRestorationMedia, jobRestorationMedia)
        );
        return this.store$
          .select(jobRestorationMediaSelectors.selectIsSaving)
          .pipe(
            pairwise(),
            filter(([a, b]) => a === true && b === false),
            take(1)
          );
      },
    };
    return this.s3Uploader.upload(s3Payload);
  }
}
