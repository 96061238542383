import { createAction, props } from '@ngrx/store';
import { JobDate } from '@app/models/job-date.model';
import {
  CreateTimecardRequest,
  ClockOutPayload,
  UpdateEquipTimecardsRequest,
  DeleteTimecardRequest,
} from '@services/api/job-date.service';
import { UserTimecard, JobDateTimecard } from '@app/models';

export const loadJobDate = createAction(
  '[Job Date] Load',
  props<{ payload: { job: string; date: string } }>()
);

export const loadJobDateSuccess = createAction(
  '[Job Date API] Load Success',
  props<{ jobDate: JobDate }>()
);

export const loadJobDateError = createAction(
  '[Job Date API] Load Error',
  props<{ error: any }>()
);

export const createTimecard = createAction(
  '[Job Date] Create Timecard',
  props<{ payload: CreateTimecardRequest }>()
);

export const clockOut = createAction(
  '[Job Date] Clock Out',
  props<{ payload: ClockOutPayload }>()
);

export const updateEquipTimecards = createAction(
  '[Job Date] Update Equipment Timecards',
  props<{ payload: UpdateEquipTimecardsRequest }>()
);

export const deleteTimecard = createAction(
  '[Job Date] Delete Timecard',
  props<{ payload: DeleteTimecardRequest }>()
);

export const updateTimecard = createAction(
  '[Job Date] Update Timecard',
  props<{ payload: JobDateTimecard }>()
);
