import { JobAlertVM } from '@app/models/job-alert.model';
import { createSelector } from '@ngrx/store';
import { parseISO } from 'date-fns';
import { jobAlertSelectors } from './job-alert.state';
import { userSelectors } from './user.state';

export const selectJobAlertVMAll = createSelector(
  jobAlertSelectors.selectAll,
  userSelectors.selectEntities,
  (alerts, users): JobAlertVM[] => {
    return alerts.map(alert => {
      return {
        ...alert,
        employee: alert.employee in users ? users[alert.employee] : null,
        created: parseISO(alert.created),
      };
    });
  }
);
