import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { EmergencyTicket } from '@app/models';

export const {
  initialState: emergencyTicketInitialState,
  selectors: emergencyTicketSelectors,
  facade: EmergencyTicketFacadeBase,
} = buildState(EmergencyTicket);

export function emergencyTicketReducer(
  state = emergencyTicketInitialState
): IEntityState<EmergencyTicket> {
  return state;
}
