import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobDateUntrackedEquipTimecard } from '@app/models';

export const {
  initialState: jobDateUntrackedEquipTimecardInitialState,
  selectors: jobDateUntrackedEquipTimecardSelectors,
  facade: JobDateUntrackedEquipTimecardFacadeBase,
} = buildState(JobDateUntrackedEquipTimecard);

export function jobDateUntrackedEquipTimecardReducer(
  state = jobDateUntrackedEquipTimecardInitialState
): IEntityState<JobDateUntrackedEquipTimecard> {
  return state;
}
