import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobDateNote } from '@app/models';

export const {
  initialState: jobDateNoteInitialState,
  selectors: jobDateNoteSelectors,
  facade: JobDateNoteFacadeBase,
} = buildState(JobDateNote);

export function jobDateNoteReducer(
  state = jobDateNoteInitialState
): IEntityState<JobDateNote> {
  return state;
}
