import { Component, Input, OnInit } from '@angular/core';
import { Job, UserTimecard } from '@app/models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-multiple-timecards-modal',
  templateUrl: './multiple-timecards-modal.component.html',
  styleUrls: ['./multiple-timecards-modal.component.scss'],
})
export class MultipleTimecardsModalComponent implements OnInit {
  @Input() timecards: UserTimecard[];
  @Input() jobs: { [key: string]: Job } = {};
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  onDelete(timecard: UserTimecard) {
    this.modalCtrl.dismiss(timecard);
  }
}
