import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlanManager } from '@app/models';

export const {
  initialState: jobPlanManagerInitialState,
  selectors: jobPlanManagerSelectors,
  facade: JobPlanManagerFacadeBase,
} = buildState(JobPlanManager);

export function jobPlanManagerReducer(
  state = jobPlanManagerInitialState
): IEntityState<JobPlanManager> {
  return state;
}
