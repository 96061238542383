import { JobMedia } from '@app/models';
import { AppState } from './app.state';
import { map, exhaustMap, concatMap } from 'rxjs/operators';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import {
  CreateJobMediaPayload,
  JobMediaService,
} from '@services/api/job-media.service';
import { CreateSuccess, Update } from '@briebug/ngrx-auto-entity';
import {
  ImageUploadService,
  PhotoMeta,
} from '@services/image-upload/image-upload.service';
import { EMPTY } from 'rxjs';

export interface CreateJobMediaRequest {
  job: string;
  employee: string;
  fileName: string;
  photo: PhotoMeta;
}

export const createJobMedia = createAction(
  '[Job Media] Create',
  props<{ payload: CreateJobMediaRequest }>()
);

export const uploadJobMedia = createAction(
  '[Job Media] Upload',
  props<{ payload: CreateJobMediaPayload }>()
);

@Injectable()
export class JobMediaEffects {
  createMedia$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobMedia),
        map(action => action.payload),
        concatMap(payload => {
          const jm: JobMedia = {
            job: payload.job,
            employee: payload.employee,
            originalFileName: payload.fileName,
            imageExists: false,
            notes: '',
          };
          return this.jobMediaService.create(jm).pipe(
            map(async response => {
              this.store$.dispatch(new CreateSuccess(JobMedia, response));
              const photo = {
                ...payload.photo,
                id: response.id,
              };
              this.imageUpload.addPhoto(photo).then(async () => {
                this.imageUpload.getPhotoFile(response.id).then(photoFile => {
                  const newPayload: CreateJobMediaPayload = {
                    jobMedia: response,
                    image: photoFile,
                  };
                  this.store$.dispatch(uploadJobMedia({ payload: newPayload }));
                });
              });
            })
            // catchError(error => {
            //   console.log(error);
            // })
          );
        })
      ),
    { dispatch: false }
  );

  uploadMedia$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadJobMedia),
        map(action => action.payload),
        concatMap(payload => {
          this.jobMediaService.uploadFile(payload);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobMediaService: JobMediaService,
    private imageUpload: ImageUploadService,
    private store$: Store<AppState>
  ) {}
}
