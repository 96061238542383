import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { EquipmentMachine } from '@app/models';

export const {
  initialState: equipMachineInitialState,
  selectors: equipMachineSelectors,
  facade: EquipMachineFacadeBase,
} = buildState(EquipmentMachine);

export function equipMachineReducer(
  state = equipMachineInitialState
): IEntityState<EquipmentMachine> {
  return state;
}
