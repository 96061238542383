import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintenance-mode',
  templateUrl: './maintenance-mode.page.html',
  styleUrls: ['./maintenance-mode.page.scss'],
})
export class MaintenanceModePage implements OnInit {
  constructor() {}

  ngOnInit() {}
}
