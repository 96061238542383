import { Router } from '@angular/router';
import { NetworkRequiredPage } from './public/network-required/network-required.page';
import { selectBaseDataState } from './state/base-data/base-data.selectors';
import { loadAllBaseData } from './state/base-data/base-data.actions';
import { Component, ChangeDetectorRef, NgZone } from '@angular/core';

import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@services/auth/authentication.service';
import { Store, select } from '@ngrx/store';
import { AppState } from './state';

import { UpdateStatus } from '@shared/components/update-status/update-status.component';
import { VersionService } from '@services/version/version.service';
import { selectMultipleTimeCards } from './state/multiple-timecards/multiple-timecards.selectors';
import { MultipleTimecardsModalComponent } from '@shared/components/multiple-timecards-modal/multiple-timecards-modal.component';
import { UserTimecard } from './models';
import { selectMultipleTimeCardJobs } from './state/multiple-timecards/multiple-timecards.join-selectors';
import { take } from 'rxjs/operators';
import { UserMeService } from '@services/api/user-me.service';
import { appBackground, appResume } from './state/app-state/app-state.actions';

import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { Device, DeviceInfo } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { PushUpdate } from 'capacitor-push-update';
import { ServerStatusWsService } from '@services/ws/server-status-ws/server-status-ws.service';
import { MaintenanceModePage } from '@public/maintenance-mode/maintenance-mode.page';
import { DeviceAPNS } from './models/device-apns.model';
import { PushRegistrationService } from '@services/api/push-registration.service';
import { PluginListenerHandle } from '@capacitor/core';
import { S3UploadService } from '@services/api/s3-upload.service';

interface PushNotificationListeners {
  registration: PluginListenerHandle;
  registrationError: PluginListenerHandle;
  notificactionReceived: PluginListenerHandle;
  actionPerformed: PluginListenerHandle;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  subs = new Subscription();
  loaded = false;
  authenticated = false;
  firstLogin = false;
  networkHandler: any;
  isConnected = true;
  maintenanceMode = false;
  netReqPage = NetworkRequiredPage;
  maintenancePage = MaintenanceModePage;
  deviceInfo: DeviceInfo;
  deviceId: string;

  updateAvailable = false;
  updateStatus: UpdateStatus;

  uploading = false;

  pushListeners: PushNotificationListeners;

  constructor(
    private platform: Platform,
    private authService: AuthenticationService,
    private store$: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private version: VersionService,
    private modalCtrl: ModalController,
    private userMeSvc: UserMeService,
    public serverStatus: ServerStatusWsService,
    private pushRegistration: PushRegistrationService,
    private zone: NgZone,
    private uploadSvc: S3UploadService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.pushListeners = {
      registration: null,
      registrationError: null,
      notificactionReceived: null,
      actionPerformed: null,
    };
    App.addListener('appStateChange', async state => {
      if (!state.isActive) {
        // The app has become inactive. We should check if we have some work left to do, and, if so,
        // execute a background task that will allow us to finish that work before the OS
        // suspends or terminates our app:
        if (this.uploading) {
          this.store$.dispatch(appBackground());
        }
      } else {
        this.store$.dispatch(appResume());
        if (this.authenticated) {
          try {
            await this.update();
            await this.addPushNotificationListeners();
            await this.registerPushNotifications();
          } catch (err) {
            console.log(err);
          }
          this.loaded = false;
          this.store$.dispatch(loadAllBaseData());
          const path = this.router.url.split('/');
          const skipReloadEndpoints = [
            'addl-cost',
            'info',
            'attachments',
            'daily',
          ];
          const skip =
            path.includes('jobs') && skipReloadEndpoints.includes(path.pop());
          if (this.deviceInfo.platform !== 'web' && !skip) {
            this.zone.run(() => {
              this.router.navigateByUrl('/');
            });
          } else if (skip) {
            console.log('---- Skipping Reload ----');
          }
        }
      }
    });

    this.platform.ready().then(() => {
      this.networkHandler = Network.addListener(
        'networkStatusChange',
        status => {
          this.isConnected = status.connected;
          this.cdr.detectChanges();
        }
      );
      StatusBar.show().catch(e => {});
      SplashScreen.hide().catch(e => {});
      this.subs.add(
        this.uploadSvc.activeUploads$.subscribe(uploading => {
          this.uploading = uploading;
        })
      );
      this.subs.add(
        this.store$.pipe(select(selectMultipleTimeCards)).subscribe(mtc => {
          if (mtc.timecards.length > 0) {
            this.multipleTimecardsModal(mtc.timecards);
          }
        })
      );
      this.subs.add(
        this.store$.pipe(select(selectBaseDataState)).subscribe(bds => {
          this.loaded = bds.loaded;
          if (this.loaded) {
            this.router.initialNavigation();
          }
        })
      );
      this.subs.add(
        this.authService.authenticationState.subscribe(async authState => {
          if (authState.authenticated && authState.token) {
            this.deviceInfo = await Device.getInfo();
            this.deviceId = (await Device.getId()).uuid;
            this.authenticated = true;
            try {
              await PushUpdate.initialize({
                serverUrl: authState.updateUrl,
                serverToken: authState.updateToken,
                deployment: authState.deployment,
              });
              await this.update();
              await this.addPushNotificationListeners();
              await this.registerPushNotifications();
            } catch (error) {
              console.log(
                'PushUpdate does not have web implementation, and isnt loaded.'
              );
            }
            this.store$.dispatch(loadAllBaseData());
            this.serverStatus.setupWebSocket();
            this.serverStatus.maintenance$.subscribe(maintenance => {
              this.maintenanceMode = maintenance;
            });
          } else {
            this.router.initialNavigation();
          }
        })
      );
      if (!this.platform.is('capacitor')) {
        this.version.initVersionCheck();
      }
    });
    this.platform.resume.subscribe(async () => {});
  }

  async multipleTimecardsModal(timecards: UserTimecard[]) {
    const jobs = await this.store$
      .pipe(
        select(selectMultipleTimeCardJobs),
        take(1)
      )
      .toPromise();
    const modal = await this.modalCtrl.create({
      component: MultipleTimecardsModalComponent,
      componentProps: {
        timecards,
        jobs,
      },
      backdropDismiss: false,
    });

    await modal.present();

    const data = await modal.onDidDismiss();
    if (data && data.data) {
      this.userMeSvc.deleteTimecard(data.data).subscribe(async res => {
        location.reload();
      });
    }
  }

  async clearPushNotificationListeners() {
    Object.values(this.pushListeners).forEach(
      async (listener: PluginListenerHandle) => {
        if (listener) {
          await listener.remove();
        }
      }
    );
  }

  async addPushNotificationListeners() {
    await this.clearPushNotificationListeners();
    this.pushListeners.registration = await PushNotifications.addListener(
      'registration',
      token => {
        console.info(
          'Registration token Receieved: ',
          token.value.slice(0, 10)
        );
        console.info('Device Details', this.deviceId);
        const payload: DeviceAPNS = {
          name: this.deviceInfo.name ? this.deviceInfo.name : '',
          registrationId: token.value,
          deviceId: this.deviceId.toLowerCase(),
          active: true,
        };
        this.pushRegistration
          .registerAPNS(payload)
          .pipe(take(1))
          .subscribe(resp => {
            console.log('Succesfully registered device with server.');
          });
      }
    );

    this.pushListeners.registrationError = await PushNotifications.addListener(
      'registrationError',
      err => {
        console.error('Registration error: ', err.error);
      }
    );

    this.pushListeners.notificactionReceived = await PushNotifications.addListener(
      'pushNotificationReceived',
      notification => {
        console.log('Push notification received: ', notification);
      }
    );

    this.pushListeners.actionPerformed = await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      notification => {
        console.log(
          'Push notification action performed',
          notification.actionId,
          notification.inputValue
        );
      }
    );
  }

  async registerPushNotifications() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
    await PushNotifications.register();
  }

  async update() {
    this.updateAvailable = false;
    const status = await PushUpdate.checkForUpdates();
    if (status.data.updateAvailable) {
      this.updateAvailable = true;
      PushUpdate.downloadUpdate(
        (data: { data: any; step: string }, error: string) => {
          if (!error) {
            if (data.step === 'downloading') {
              this.updateStatus = {
                step: 1,
                totalSteps: 2,
                title: 'Downloading',
                subTitle: '',
                progress: (100.0 * data.data.currentSize) / data.data.totalSize,
                indeterminate: false,
              };
              this.cdr.detectChanges();
            } else if (data.step === 'unpacking') {
              this.updateStatus = {
                step: 2,
                totalSteps: 2,
                title: 'Installing',
                subTitle: 'This can take a few minutes.',
                progress: data.data.progress,
                indeterminate: false,
              };
              this.cdr.detectChanges();
            }
          } else {
            console.log('download update callback err: ', error);
          }
        }
      );
    }
  }
}
