<div
  class="progress-modal"
  *ngIf="progressBars.length > 0"
  [ngStyle]="getPosition()"
>
  <div class="progress" *ngFor="let pb of progressBars">
    {{ pb.message }}
    <div class="progress-bar" [style.width.%]="pb.pctComplete"></div>
  </div>
</div>
