import { selectAllUserVMEntities } from './user.join-selectors';
import { createSelector } from '@ngrx/store';
import { jobDateCrewSelectors } from './job-date-crew.state';
import { JobDateCrewVM } from '@app/models';

export const selectJobDateCrewVMAll = createSelector(
  jobDateCrewSelectors.selectAll,
  selectAllUserVMEntities,
  (crews, users): JobDateCrewVM[] => {
    return crews.map(
      (crew): JobDateCrewVM => {
        return {
          ...crew,
          foreman: crew.foreman in users ? users[crew.foreman] : null,
          employees: crew.employees.map(emp =>
            emp in users ? users[emp] : null
          ),
        };
      }
    );
  }
);
