import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobSiteEquipment } from '@app/models';

export const {
  initialState: jobSiteEquipmentInitialState,
  selectors: jobSiteEquipmentSelectors,
  facade: JobSiteEquipmentFacadeBase,
} = buildState(JobSiteEquipment);

export function jobSiteEquipmentReducer(
  state = jobSiteEquipmentInitialState
): IEntityState<JobSiteEquipment> {
  return state;
}
