import { createSelector } from '@ngrx/store';
import { formStatusSelectors } from './form-status.state';
import { FormStatus } from '@app/models';

export interface FormStatusByEntityId {
  [key: string]: FormStatus;
}

export interface FormStatusByEntityType {
  [key: string]: FormStatusByEntityId;
}

export const selectFormStatusByEntityType = createSelector(
  formStatusSelectors.selectAll,
  fs => {
    const res: FormStatusByEntityType = {};
    fs.forEach(f => {
      if (!(f.entityType in res)) {
        res[f.entityType] = {};
      }
      res[f.entityType][f.entityId] = f;
    });
    return res;
  }
);
