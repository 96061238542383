import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobDateCrew } from '@app/models';

export const {
  initialState: jobDateCrewInitialState,
  selectors: jobDateCrewSelectors,
  facade: JobDateCrewFacadeBase,
} = buildState(JobDateCrew);

export function jobDateCrewReducer(
  state = jobDateCrewInitialState
): IEntityState<JobDateCrew> {
  return state;
}
