import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadProgressComponent } from './upload-progress/upload-progress.component';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageViewerModalComponent } from './image-viewer-modal/image-viewer-modal.component';
import { CalendarPickerModalComponent } from './calendar-picker-modal/calendar-picker-modal.component';

@NgModule({
  declarations: [
    UploadProgressComponent,
    ImageViewerModalComponent,
    CalendarPickerModalComponent,
  ],
  imports: [CommonModule, IonicModule, FontAwesomeModule],
  exports: [UploadProgressComponent, ImageViewerModalComponent],
})
export class UtilsModule {}
