import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JobDateCrew } from '@app/models';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

export interface AddCrewMemberPayload {
  id: string;
  payload: { newCrewMember: string };
}

@Injectable({
  providedIn: 'root',
})
export class JobDateCrewService {
  constructor(private http: HttpClient, private api: ApiService) {}

  addCrewMember(payload: AddCrewMemberPayload): Observable<JobDateCrew> {
    return this.http.post<JobDateCrew>(
      `${this.api.url}/jobs/date-crews/${payload.id}/add-crew-member`,
      payload.payload
    );
  }
}
