import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { SubcontractorVendor } from '@app/models';

export const {
  initialState: subcontractorVendorInitialState,
  selectors: subcontractorVendorSelectors,
  facade: SubcontractorVendorFacadeBase,
} = buildState(SubcontractorVendor);

export function subcontractorVendorReducer(
  state = subcontractorVendorInitialState
): IEntityState<SubcontractorVendor> {
  return state;
}
