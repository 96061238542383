import * as actions from './user-me.actions';
import { createReducer, on, Action, createFeatureSelector } from '@ngrx/store';
import { UserMe } from '@app/models';
import { AppState } from '../app.state';

export interface State extends UserMe {
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: null,
  user: null,
  activeTimecard: null,
  incompleteCount: 0,
  minDate: null,
  invalidRanges: [],
};

const userMeReducer = createReducer(
  initialState,
  on(actions.loadUserMe, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(actions.loadUserMeSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    user: { ...payload.user },
    activeTimecard: { ...payload.activeTimecard },
    incompleteCount: payload.incompleteCount,
    minDate: payload.minDate,
    invalidRanges: [...payload.invalidRanges],
  })),
  on(actions.updateUserMeTimecard, (state, { timecard }) => ({
    ...state,
    activeTimecard: timecard === null ? null : { ...timecard },
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return userMeReducer(state, action);
}

export const selectUserMeFeature = createFeatureSelector<State>('userMe');
