import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { SubcontractorCategory } from '@app/models';

export const {
  initialState: subcontractorCategoryInitialState,
  selectors: subcontractorCategorySelectors,
  facade: SubcontractorCategoryFacadeBase,
} = buildState(SubcontractorCategory);

export function subcontractorCategoryReducer(
  state = subcontractorCategoryInitialState
): IEntityState<SubcontractorCategory> {
  return state;
}
