import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { EquipmentMachine } from './equipment-machine.model';
import { EquipmentModel } from './equipment-model.model';
import { EquipmentType } from './equipment-type.model';
import { EquipmentOption } from './equipment-option.model';

export interface TruckingAvailability {
  status: string;
  reason: string;
  availableOn: string;
}

export interface TELocation {
  tmpDrop: boolean;
  lat: number;
  lng: number;
  address: string;
  city: string;
  state: string;
  name: string;
  jobNumber: string;
  job: string;
  completed: boolean;
}

@Entity({ modelName: 'TruckingEquipLocation' })
export class TruckingEquipLocation {
  @Key id: string;
  location: TELocation;
  equip: number;
  equipRequirement: string;
  status: string;
  inTrucking: string;
  outTrucking: string;
  availability: TruckingAvailability;
}

export interface TruckingMachineFlat {
  machine: EquipmentMachine;
  makeModel: EquipmentModel;
  equipType: EquipmentType;
  options: EquipmentOption[];
}

export interface TruckingEquipWithLocation {
  id: string;
  location: TELocation;
  equip: TruckingMachineFlat;
  equipRequirement: string;
  status: string;
  inTrucking: string;
  outTrucking: string;
  availability: TruckingAvailability;
}

export interface TruckingMachinesLocation {
  location: TELocation;
  machines: TruckingEquipWithLocation[];
}
