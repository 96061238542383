import { UserRole } from '@app/models';
import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'User' })
export class User {
  @Key id: string;
  username: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  suffix: string;
  mobile: string;
  email: string;
  color: string;
  accountingId: string;
  primaryRole: number;
  active: boolean;
  firstLogin: boolean;
  resetPw: boolean;
}

export interface UserVM {
  id: string;
  username: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  suffix: string;
  mobile: string;
  email: string;
  color: string;
  accountingId: string;
  primaryRole: UserRole;
  active: boolean;
  firstLogin: boolean;
  resetPw: boolean;
}
