import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { EquipmentModel } from '@app/models';

export const {
  initialState: equipModelInitialState,
  selectors: equipModelSelectors,
  facade: EquipModelFacadeBase,
} = buildState(EquipmentModel);

export function equipModelReducer(
  state = equipModelInitialState
): IEntityState<EquipmentModel> {
  return state;
}
