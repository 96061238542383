import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobIncident } from '@app/models';

export const {
  initialState: jobIncidentInitialState,
  selectors: jobIncidentSelectors,
  facade: JobIncidentFacadeBase,
} = buildState(JobIncident);

export function jobIncidentReducer(
  state = jobIncidentInitialState
): IEntityState<JobIncident> {
  return state;
}
