import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobMedia } from '@app/models';

export const {
  initialState: jobMediaInitialState,
  selectors: jobMediaSelectors,
  facade: JobMediaFacadeBase,
} = buildState(JobMedia);

export function jobMediaReducer(
  state = jobMediaInitialState
): IEntityState<JobMedia> {
  return state;
}
