import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobDateTimecard } from '@app/models';

export const {
  initialState: jobDateTimecardInitialState,
  selectors: jobDateTimecardSelectors,
  facade: JobDateTimecardFacadeBase,
} = buildState(JobDateTimecard);

export function jobDateTimecardReducer(
  state = jobDateTimecardInitialState
): IEntityState<JobDateTimecard> {
  return state;
}
