import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserTimecard } from '@app/models';
import {
  IEntityInfo,
  IEntityWithPageInfo,
  IEntityWithRangeInfo,
  ISkipTakeRange,
  Page,
  Range,
} from '@briebug/ngrx-auto-entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

interface UserTimecardsPaginated {
  count: number;
  next: string;
  previous: string;
  results: UserTimecard[];
}

@Injectable({
  providedIn: 'root',
})
export class UserTimecardsService {
  constructor(private http: HttpClient, private api: ApiService) {}

  load(
    entityInfo: IEntityInfo,
    id: any,
    criteria?: any
  ): Observable<UserTimecard> {
    return this.http.get<UserTimecard>(
      `${this.api.url}/trucking/timecards/${id}`,
      {
        params: criteria ? criteria.query || {} : {},
      }
    );
  }

  loadPage(
    entityInfo: IEntityInfo,
    { page = 1, size = 10 }: Page,
    criteria?: any
  ): Observable<IEntityWithPageInfo<UserTimecard>> {
    const offset = (page - 1) * size;
    const limit = size;

    return this.http
      .get<UserTimecardsPaginated>(`${this.api.url}/trucking/timecards`, {
        params: { offset, limit },
      })
      .pipe(
        map(timecardsMeta => ({
          pageInfo: {
            page: {
              page,
              size,
            },
            totalCount: timecardsMeta.count,
          },
          entities: timecardsMeta.results,
        }))
      );
  }

  loadRange(
    entityInfo: IEntityInfo,
    { skip, take }: ISkipTakeRange,
    criteria?: any
  ): Observable<IEntityWithRangeInfo<UserTimecard>> {
    return this.http
      .get<UserTimecardsPaginated>(`${this.api.url}/trucking/timecards`, {
        params: { offset: skip, limit: take },
      })
      .pipe(
        map(timecardsMeta => ({
          rangeInfo: {
            range: { skip, take },
            totalCount: timecardsMeta.count,
          },
          entities: timecardsMeta.results,
        }))
      );
  }

  update(
    entityInfo: IEntityInfo,
    entity: UserTimecard
  ): Observable<UserTimecard> {
    return this.http.patch<UserTimecard>(
      `${this.api.url}/trucking/timecards/${entity.id}`,
      entity
    );
  }

  replace(
    entityInfo: IEntityInfo,
    entity: UserTimecard
  ): Observable<UserTimecard> {
    return this.http.put<UserTimecard>(
      `${this.api.url}/trucking/timecards/${entity.id}`,
      entity
    );
  }

  delete(entityInfo: IEntityInfo, entity: any): Observable<any> {
    return this.http
      .delete<any>(`${this.api.url}/trucking/timecards/${entity.id}`)
      .pipe(map(() => entity));
  }
}
