import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'EquipmentUntrackedMachine' })
export class EquipmentUntrackedMachine {
  @Key id: number;
  name: string;
  delta: number;
  billingCode: string;
  billingType: string;
  equipClass: number;
}
