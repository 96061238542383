import { JobAlert } from '@app/models/job-alert.model';
import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';

export const {
  initialState: jobAlertInitialState,
  selectors: jobAlertSelectors,
  facade: JobAlertFacadeBase,
} = buildState(JobAlert);

export function jobAlertReducer(
  state = jobAlertInitialState
): IEntityState<JobAlert> {
  return state;
}
