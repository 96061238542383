import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { RestorationCategory } from '@app/models';

export const {
  initialState: restorationCategoryInitialState,
  selectors: restorationCategorySelectors,
  facade: RestorationCategoryFacadeBase,
} = buildState(RestorationCategory);

export function restorationCategoryReducer(
  state = restorationCategoryInitialState
): IEntityState<RestorationCategory> {
  return state;
}
