import { createReducer, Action, on } from '@ngrx/store';
import * as actions from './subcontractors.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: null,
};

const subcontractorsReducer = createReducer(
  initialState,
  on(actions.loadAllSubcontractors, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(actions.loadAllSubcontractorsSuccess, state => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return subcontractorsReducer(state, action);
}
