import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { User } from './user.model';

export interface JobMediaThumbnails {
  small: string;
  medium: string;
  large: string;
}

@Entity({ modelName: 'JobMedia' })
export class JobMedia {
  @Key id?: string;
  job: string;
  employee: string;
  image?: string;
  thumbnails?: JobMediaThumbnails;
  originalFileName: string;
  imageExists: boolean;
  uploadUrl?: string;
  notes: string;
  created?: string;
  width?: number;
  height?: number;
}
export interface AttachedMediaVM {
  image?: string;
  width?: number;
  height?: number;
}
export interface JobMediaVM extends AttachedMediaVM {
  id?: string;
  job: string;
  employee: User;
  thumbnails?: JobMediaThumbnails;
  originalFileName: string;
  imageExists: boolean;
  uploadUrl?: string;
  notes: string;
  created?: Date;
}
