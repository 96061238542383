import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobFieldLogTotalTime' })
export class JobFieldLogTotalTime {
  @Key id?: string;
  jobFieldLog: string;
  minutes: number;
}

export interface JobFieldLogTotalTimeVM {
  id?: string;
  jobFieldLog: string;
  minutes: number;
  hours: number;
}
