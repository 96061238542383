import { selectMultipleTimeCardsFeature } from './multiple-timecards.reducer';
import { createSelector } from '@ngrx/store';

export const selectMultipleTimeCards = createSelector(
  selectMultipleTimeCardsFeature,
  multipleTimeCards => {
    return multipleTimeCards;
  }
);

export const selectMultipleTimeCardsStatus = createSelector(
  selectMultipleTimeCards,
  multipleTimeCards => {
    return multipleTimeCards.timecards.length > 0;
  }
);
