import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { UserTimecard, User, Job, JobDateNote } from '@app/models';
import { SimpleJob } from './job-search.service';
import { DeleteTimecardResponse } from './job-date.service';

export interface GPSStatusResponse {
  id: string;
  user: string;
  gps: boolean;
  last_update: string;
}

export interface MyOnCallResponse {
  status: string;
  conflicts: string[];
}

export interface MyJobs {
  job: SimpleJob;
  date: string;
  startTime: string;
  note: JobDateNote;
}

export interface MyJobsResponse {
  jobs: MyJobs[];
  ocStatus: MyOnCallResponse;
}

export interface TimecardSummary {
  jobSummary: SimpleJob;
  timecardStatus: string;
  timeType: string;
}

export interface JobsByDateResponse {
  ids: string[];
  entities: { [key: string]: SimpleJob[] };
}

export interface TimecardsByDateResponse {
  nextPage: number;
  ids: string[];
  entities: { [key: string]: TimecardSummary[] };
}

export interface UserMeResponse {
  user: User;
  activeTimecard: UserTimecard;
  incompleteCount: number;
  job: Job;
  minDate: string;
  invalidRanges: { start: string; end: string }[];
}

export interface SetPasswordResponse {
  user?: User;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserMeService {
  constructor(private http: HttpClient, private api: ApiService) {}

  load(userId: string = null): Observable<UserMeResponse> {
    const paramObj = userId ? { user: `${userId}` } : {};
    const httpParams: HttpParams = new HttpParams({ fromObject: paramObj });
    return this.http.get<UserMeResponse>(`${this.api.url}/users/me`, {
      params: httpParams,
    });
  }

  loadMyJobs(date = null): Observable<MyJobsResponse> {
    return this.http.get<MyJobsResponse>(
      `${this.api.url}/users/me/todays-jobs`
    );
  }

  loadIncompleteTimecards(): Observable<JobsByDateResponse> {
    return this.http
      .get<JobsByDateResponse>(`${this.api.url}/users/me/past-incomplete`)
      .pipe(
        map(data => {
          // camelize converts YYYY-MM-DD to integer of YYYYMMDD.  Converting back
          // to original ISO date string
          const entities: { [key: string]: SimpleJob[] } = {};
          data.ids.forEach(id => {
            entities[id] = data.entities[Number(id.replace(/\D/g, ''))];
          });
          return {
            ids: data.ids,
            entities,
          };
        })
      );
  }

  loadMyTimecards(page: number): Observable<TimecardsByDateResponse> {
    const paramObj = { page: `${page}` };
    const httpParams: HttpParams = new HttpParams({ fromObject: paramObj });
    return this.http
      .get<TimecardsByDateResponse>(`${this.api.url}/users/me/past-jobs`, {
        params: httpParams,
      })
      .pipe(
        map(data => {
          // camelize converts YYYY-MM-DD to integer of YYYYMMDD.  Converting back
          // to original ISO date string
          const entities: { [key: string]: TimecardSummary[] } = {};
          data.ids.forEach(id => {
            entities[id] = data.entities[Number(id.replace(/\D/g, ''))];
          });
          return {
            nextPage: data.nextPage,
            ids: data.ids,
            entities,
          };
        })
      );
  }

  loadFutureJobs(): Observable<JobsByDateResponse> {
    return this.http
      .get<JobsByDateResponse>(`${this.api.url}/users/me/future-jobs`)
      .pipe(
        map(data => {
          // camelize converts YYYY-MM-DD to integer of YYYYMMDD.  Converting back
          // to original ISO date string
          const entities: { [key: string]: SimpleJob[] } = {};
          data.ids.forEach(id => {
            entities[id] = data.entities[Number(id.replace(/\D/g, ''))];
          });
          return {
            ids: data.ids,
            entities,
          };
        })
      );
  }

  setPassword(
    payload: { password: string },
    id: string
  ): Observable<SetPasswordResponse> {
    return this.http.patch<SetPasswordResponse>(
      `${this.api.url}/users/${id}/set-password`,
      payload
    );
  }

  deleteTimecard(payload: UserTimecard) {
    return this.http.delete<DeleteTimecardResponse>(
      `${this.api.url}/timecards/${payload.id}`
    );
  }

  setGpsStatus(gps: boolean) {
    return this.http.post<GPSStatusResponse>(`${this.api.url}/users/status`, {
      gps,
    });
  }
}
