import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { JobService } from '@services/api/job.service';
import { map, exhaustMap } from 'rxjs/operators';
import {
  LoadSuccess,
  LoadAllSuccess,
  LoadManySuccess,
} from '@briebug/ngrx-auto-entity';
import {
  Job,
  JobPlan,
  JobDocument,
  JobNote,
  JobMedia,
  JobLocate,
  JobIncident,
  JobIncidentMedia,
  JobMaterial,
  JobSubcontractor,
  JobRestoration,
  JobSiteEquipment,
  JobExtra,
  JobRestorationMedia,
  JobPastEquipment,
} from '@app/models';
import {
  loadJobDetails,
  loadJobDetailsSuccess,
  updateJobLatLng,
} from './job-details.actions';
import { JobAlert } from '@app/models/job-alert.model';

@Injectable()
export class JobDetailsEffects {
  loadJobDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadJobDetails),
        map(action => action.job),
        exhaustMap(job =>
          this.jobService.details(job).pipe(
            map(response => {
              this.store$.dispatch(
                new LoadSuccess(Job, {
                  ...response.job,
                  lat: +response.job.lat,
                  lng: +response.job.lng,
                })
              );
              if (response.jobPlan) {
                this.store$.dispatch(
                  new LoadSuccess(JobPlan, response.jobPlan)
                );
              }
              this.store$.dispatch(
                new LoadAllSuccess(JobDocument, response.documents)
              );
              this.store$.dispatch(new LoadAllSuccess(JobNote, response.notes));
              this.store$.dispatch(
                new LoadAllSuccess(JobMedia, response.media)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobLocate, response.locates)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobIncident, response.incidents)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobIncidentMedia, response.incidentMedia)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobMaterial, response.materials)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobSubcontractor, response.subcontractors)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobExtra, response.extras)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobRestoration, response.restoration)
              );
              console.log(
                'dispatching LoadAllSuccess',
                response.restorationMedia
              );
              this.store$.dispatch(
                new LoadAllSuccess(
                  JobRestorationMedia,
                  response.restorationMedia
                )
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobSiteEquipment, response.siteEquipment)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobPastEquipment, response.pastEquipment)
              );
              this.store$.dispatch(
                new LoadManySuccess(JobAlert, response.alerts)
              );
              this.store$.dispatch(loadJobDetailsSuccess({ job }));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  updateLatLng$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateJobLatLng),
        exhaustMap(payload =>
          this.jobService.updateLatLng(payload.job, payload.location).pipe(
            map(response => {
              this.store$.dispatch(
                new LoadSuccess(Job, {
                  ...response,
                  lat: +response.lat,
                  lng: +response.lng,
                })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobService: JobService,
    private store$: Store<AppState>
  ) {}
}
