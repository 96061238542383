import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { ShopLocation } from '@app/models';

export const {
  initialState: shopLocationInitialState,
  selectors: shopLocationSelectors,
  facade: ShopLocationFacadeBase,
} = buildState(ShopLocation);

export function shopLocationReducer(
  state = shopLocationInitialState
): IEntityState<ShopLocation> {
  return state;
}
