import { Injectable } from '@angular/core';
import { AppState, JobPlanCrewReqFacadeBase } from '@app/state';
import { Store } from '@ngrx/store';
import { JobPlanCrewRequirement } from '@app/models';

@Injectable({ providedIn: 'root' })
export class JobPlanCrewReqFacade extends JobPlanCrewReqFacadeBase {
  constructor(private store: Store<AppState>) {
    super(JobPlanCrewRequirement, store);
  }
}
