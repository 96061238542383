import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobFieldLog } from '@app/models';

export const {
  initialState: jobFieldLogInitialState,
  selectors: jobFieldLogSelectors,
  facade: JobFieldLogFacadeBase,
} = buildState(JobFieldLog);

export function jobFieldLogReducer(
  state = jobFieldLogInitialState
): IEntityState<JobFieldLog> {
  return state;
}
