import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobDateEquipOptTimecard } from '@app/models';

export const {
  initialState: jobDateEquipOptTimecardInitialState,
  selectors: jobDateEquipOptTimecardSelectors,
  facade: JobDateEquipOptTimecardFacadeBase,
} = buildState(JobDateEquipOptTimecard);

export function jobDateEquipOptTimecardReducer(
  state = jobDateEquipOptTimecardInitialState
): IEntityState<JobDateEquipOptTimecard> {
  return state;
}
