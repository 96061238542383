import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { UserTimecard } from '@app/models';

export const {
  initialState: userTimecardInitialState,
  selectors: userTimecardSelectors,
  facade: UserUserTimecardFacadeBase,
} = buildState(UserTimecard);

export function userTimecardReducer(
  state = userTimecardInitialState
): IEntityState<UserTimecard> {
  return state;
}
