import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobIncidentMedia } from '@app/models';

export const {
  initialState: jobIncidentMediaInitialState,
  selectors: jobIncidentMediaSelectors,
  facade: JobIncidentMediaFacadeBase,
} = buildState(JobIncidentMedia);

export function jobIncidentMediaReducer(
  state = jobIncidentMediaInitialState
): IEntityState<JobIncidentMedia> {
  return state;
}
