import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { RestorationVendor } from '@app/models';

export const {
  initialState: restorationVendorInitialState,
  selectors: restorationVendorSelectors,
  facade: RestorationVendorFacadeBase,
} = buildState(RestorationVendor);

export function restorationVendorReducer(
  state = restorationVendorInitialState
): IEntityState<RestorationVendor> {
  return state;
}
