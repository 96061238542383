import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobDocument } from '@app/models';

export const {
  initialState: jobDocumentInitialState,
  selectors: jobDocumentSelectors,
  facade: JobDocumentFacadeBase,
} = buildState(JobDocument);

export function jobDocumentReducer(
  state = jobDocumentInitialState
): IEntityState<JobDocument> {
  return state;
}
