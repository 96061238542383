import { createSelector } from '@ngrx/store';
import { jobSelectors } from './job.state';
import { selectJobDetailsState } from './job-details/job-details.selectors';
import { JobVM } from '@app/models';
import { parseISO } from 'date-fns';
import { selectAllUserVMEntities } from './user.join-selectors';
import { selectJobAlertVMAll } from './job-alert.join-selectors';

export const selectUnassignedJobs = createSelector(
  jobSelectors.selectAll,
  jobs => {
    return jobs.filter(job => job.unassigned);
  }
);

export const selectDetailedJobVM = createSelector(
  selectJobDetailsState,
  jobSelectors.selectEntities,
  selectAllUserVMEntities,
  selectJobAlertVMAll,
  (detailsState, jobs, users, alerts): JobVM => {
    const id = detailsState.job ? detailsState.job : 'NONE';
    const loaded = detailsState.loaded && id;

    return loaded && id in jobs
      ? {
          ...jobs[id],
          allEmployees: jobs[id].allEmployees.map(u => ({
            user: u.user in users ? users[u.user] : null,
            latest: parseISO(u.latest),
            numDays: u.numDays,
          })),
          activeDates: jobs[id].activeDates.map(d => parseISO(d)),
          alerts: alerts.filter(alert => alert.job === id),
        }
      : null;
  }
);
