import { parseISO } from 'date-fns';
import { JobLocateVM } from '@app/models/job-locate.model';
import { createSelector } from '@ngrx/store';
import { jobLocateSelectors } from './job-locate.state';

export const selectJobLocateVMAll = createSelector(
  jobLocateSelectors.selectAll,
  locates => {
    return locates.map(
      (locate): JobLocateVM => {
        return {
          ...locate,
          ticketTimestamp: locate.ticketTimestamp
            ? parseISO(locate.ticketTimestamp)
            : null,
          start: locate.start ? parseISO(locate.start) : null,
          end: locate.end ? parseISO(locate.end) : null,
          renewBy: locate.renewBy ? parseISO(locate.renewBy) : null,
        };
      }
    );
  }
);
