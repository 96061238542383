import { selectJobLocateVMAll } from './../job-locate.join-selectors';
import { selectJobSubcontractorVMAll } from './../job-subcontractor.join-selectors';
import { selectJobIncidentVMAll } from './../job-incident.join-selectors';
import { createSelector } from '@ngrx/store';
import { selectJobDetails } from './job-details.reducer';
import { jobNoteSelectors } from '../job-note.state';
import { userSelectors } from '../user.state';
import {
  JobNoteVM,
  JobMediaVM,
  JobDocumentVM,
  JobIncidentVM,
  JobMaterialVM,
  JobSubcontractorVM,
  JobRestorationVM,
} from '@app/models';
import { parseISO } from 'date-fns';
import { jobMediaSelectors } from '../job-media.state';
import { jobDocumentSelectors } from '../job-document.state';
import { userRoleSelectors } from '../user-role.state';
import { selectJobMaterialVMAll } from '../job-material.join-selectors';
import { selectJobRestorationVMAll } from '../job-restoration.join-selectors';
import { jobPlanSelectors } from '../job-plan.state';

export const selectJobDetailsState = createSelector(
  selectJobDetails,
  details => details
);

export const selectJobDetailNotes = createSelector(
  selectJobDetailsState,
  jobNoteSelectors.selectAll,
  userSelectors.selectEntities,
  (detailsState, notes, users): JobNoteVM[] => {
    const jobId = detailsState.job;
    return notes
      .filter(note => note.job === jobId)
      .map(note => ({
        ...note,
        employee: note.employee in users ? users[note.employee] : null,
        created: parseISO(note.created),
      }));
  }
);

export const selectJobDetailMedia = createSelector(
  selectJobDetailsState,
  jobMediaSelectors.selectAll,
  userSelectors.selectEntities,
  (detailsState, media, users): JobMediaVM[] => {
    const jobId = detailsState.job;
    return media
      .filter(m => m.job === jobId)
      .map(m => ({
        ...m,
        thumbnails: { ...m.thumbnails },
        employee: m.employee in users ? users[m.employee] : null,
        created: parseISO(m.created),
      }));
  }
);

export const selectJobDetailIncidents = createSelector(
  selectJobDetailsState,
  selectJobIncidentVMAll,
  (detailsState, incidents): JobIncidentVM[] => {
    return incidents.filter(incident => incident.job === detailsState.job);
  }
);

export const selectJobDetailMaterials = createSelector(
  selectJobDetailsState,
  selectJobMaterialVMAll,
  (detailsState, materials): JobMaterialVM[] => {
    return materials.filter(material => material.job === detailsState.job);
  }
);

export const selectJobDetailSubcontractors = createSelector(
  selectJobDetailsState,
  selectJobSubcontractorVMAll,
  (detailsState, subs): JobSubcontractorVM[] => {
    return subs.filter(sub => sub.job === detailsState.job);
  }
);

export const selectJobDetailRestoration = createSelector(
  selectJobDetailsState,
  selectJobRestorationVMAll,
  (detailsState, restoration): JobRestorationVM[] => {
    return restoration.filter(rest => rest.job === detailsState.job);
  }
);

export const selectJobDetailDocs = createSelector(
  selectJobDetailsState,
  jobDocumentSelectors.selectAll,
  userSelectors.selectEntities,
  userRoleSelectors.selectEntities,
  (detailsState, documents, users, roles): JobDocumentVM[] => {
    const jobId = detailsState.job;
    return documents
      .filter(doc => doc.job === jobId)
      .map(doc => ({
        ...doc,
        employee: doc.employee in users ? users[doc.employee] : null,
        created: parseISO(doc.created),
        access: doc.access
          .filter(roleId => roleId in roles)
          .map(roleId => roles[roleId]),
      }));
  }
);

export const selectJobDetailJobPlan = createSelector(
  selectJobDetailsState,
  jobPlanSelectors.selectAll,
  (detailsState, jobPlans) => {
    return jobPlans.find(jp => jp.job === detailsState.job);
  }
);

export const selectJobDetailLocateVM = createSelector(
  selectJobDetailsState,
  selectJobLocateVMAll,
  (detailsState, locates) => {
    return locates.filter(locate => locate.job === detailsState.job);
  }
);
