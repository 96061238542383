import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { MaterialVendor } from '@app/models';

export const {
  initialState: materialVendorInitialState,
  selectors: materialVendorSelectors,
  facade: MaterialVendorFacadeBase,
} = buildState(MaterialVendor);

export function materialVendorReducer(
  state = materialVendorInitialState
): IEntityState<MaterialVendor> {
  return state;
}
