import { ShopLocation } from '@app/models';
import { EquipmentOptionType } from './equipment-option-type.model';
import { EquipmentType } from './equipment-type.model';
import { EquipmentMachineVM } from './equipment-machine.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { Job } from './job.model';

export interface JobSiteEquipmentDate {
  date: string;
  startTime: string;
  endTime: string;
}

export interface JobSiteEquipmentDateVM {
  date: Date;
  startTime: Date;
  endTime: Date;
}

export interface JobSiteMachine {
  equip: number;
  status: string;
  arrival: string;
  departure: string;
}

export interface JobSiteMachineVM {
  equip: EquipmentMachineVM;
  status: string;
  arrival: Date;
  departure: Date;
}

export interface JobSiteEquipTransportTemp {
  tmpAddress: string;
  tmpCity: string;
  tmpState: string;
  tmpLat: number;
  tmpLng: number;
}

export interface JobSiteEquipTransport {
  status: string;
  transportType: string;
  location: Job;
  shop: ShopLocation;
  tmp: JobSiteEquipTransportTemp;
  equip: number;
}

@Entity({ modelName: 'JobSiteEquipment' })
export class JobSiteEquipment {
  @Key id?: string;
  equipmentType: number;
  equipmentOptions: number[];
  jobPlan: string;
  filled: boolean;
  notes: string;
  transportStatus: string;
  transport: JobSiteEquipTransport;
  transportTime?: { pickup: string; dropoff: string };
  dates: JobSiteEquipmentDate[];
  siteMachine: JobSiteMachine;
}

export interface JobSiteEquipmentVM {
  id?: string;
  equipmentType: EquipmentType;
  equipmentOptions: EquipmentOptionType[];
  jobPlan: string;
  filled: boolean;
  notes: string;
  transportStatus: string;
  transport: JobSiteEquipTransport;
  dates: JobSiteEquipmentDateVM[];
  siteMachine: JobSiteMachineVM;
}
