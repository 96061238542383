import { EquipRepairTicketRepairNote } from './../../models/equip-repair-ticket.model';
import { filter, map, pairwise, take } from 'rxjs/operators';
import { S3UploadPayload, S3UploadService } from './s3-upload.service';
import {
  EquipRepairTicket,
  EquipRepairTicketPicture,
  EquipRepairTicketsResponse,
} from '@app/models/equip-repair-ticket.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Equipment } from '@app/models/equipment.model';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { EquipmentLocations } from '@app/models/equipment-locations.model';
import {
  EquipmentMachine,
  Job,
  MachineLocation,
  RepairTicketPicture,
  ShopLocation,
} from '@app/models';
import { Store } from '@ngrx/store';
import { AppState, equipRepairTicketSelectors } from '@app/state';
import { Update } from '@briebug/ngrx-auto-entity';
import { EquipmentRental } from '@app/models/equipment-rental.model';
import { format } from 'date-fns';

export interface CreateRepairTicketPhotoPayload {
  repairTicketPicture: EquipRepairTicketPicture;
  image: File;
}

export interface EquipMachTempLocation {
  tmpLat: number;
  tmpLng: number;
  tmpAddress: string;
  tmpCity: string;
  tmpState: string;
}

export interface EquipMachineLocation {
  job: Job;
  shop: ShopLocation;
  tmp: EquipMachTempLocation;
  equip: {
    equip: number;
    status: {
      status: string;
      date: string;
    };
  }[];
  distance?: number;
}

export interface EquipMachinesByLocation {
  onJob: EquipMachineLocation[];
  onTruck: number[];
}

export interface MachineLocationResponse {
  location: MachineLocation;
}

export interface CreateEquipPayload {
  equipMachine: EquipmentMachine;
  location: string;
}

@Injectable({ providedIn: 'root' })
export class EquipService {
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private s3Uploader: S3UploadService,
    private store$: Store<AppState>
  ) {}

  loadAll(): Observable<Equipment> {
    return this.http.get<Equipment>(`${this.api.url}/equipment`);
  }

  loadLocations(): Observable<EquipmentLocations> {
    return this.http.get<EquipmentLocations>(
      `${this.api.url}/equipment/machines/locations`
    );
  }

  loadTypeLocations(request: { equipType: number; equipOptions: number[] }) {
    return this.http.get<EquipMachinesByLocation>(
      `${this.api.url}/equipment/types/${request.equipType}/locations`
    );
  }

  createMachineWithLocation(
    payload: CreateEquipPayload
  ): Observable<EquipmentMachine> {
    return this.http.post<EquipmentMachine>(
      `${this.api.url}/equipment/machines/create-with-location`,
      payload
    );
  }

  machineLocation(id: number): Observable<MachineLocationResponse> {
    return this.http.get<MachineLocationResponse>(
      `${this.api.url}/equipment/machines/${id}/location`
    );
  }

  repairTickets(id: number): Observable<EquipRepairTicketsResponse> {
    return this.http.get<EquipRepairTicketsResponse>(
      `${this.api.url}/equipment/machines/${id}/repair-tickets`
    );
  }

  allRepairTickets(): Observable<EquipRepairTicketsResponse> {
    return this.http.get<EquipRepairTicketsResponse>(
      `${this.api.url}/equipment/repair-tickets`
    );
  }

  createRepairTicket(
    payload: EquipRepairTicket
  ): Observable<EquipRepairTicket> {
    return this.http.post<EquipRepairTicket>(
      `${this.api.url}/equipment/repair-tickets`,
      payload
    );
  }

  deleteRepairTicket(payload: EquipRepairTicket) {
    return this.http.delete(
      `${this.api.url}/equipment/repair-tickets/${payload.id}`
    );
  }

  updateRepairTicket(
    payload: EquipRepairTicket
  ): Observable<EquipRepairTicket> {
    return this.http.patch<EquipRepairTicket>(
      `${this.api.url}/equipment/repair-tickets/${payload.id}`,
      payload
    );
  }

  updateRepairTicketStatus(
    payload: EquipRepairTicket
  ): Observable<EquipRepairTicket> {
    return this.http.patch<EquipRepairTicket>(
      `${this.api.url}/equipment/repair-tickets/${payload.id}/status`,
      payload
    );
  }

  getRepairTicketPictureLink(id: string): Observable<string> {
    return this.http
      .get<EquipRepairTicketPicture>(
        `${this.api.url}/equipment/repair-tickets/pictures/${id}`
      )
      .pipe(map(result => result.image));
  }

  createRepairTicketPicture(
    payload: EquipRepairTicketPicture
  ): Observable<EquipRepairTicketPicture> {
    return this.http.post<EquipRepairTicketPicture>(
      `${this.api.url}/equipment/repair-tickets/pictures`,
      payload
    );
  }

  updateRepairTicketPicture(
    payload: EquipRepairTicketPicture
  ): Observable<EquipRepairTicketPicture> {
    return this.http.patch<EquipRepairTicketPicture>(
      `${this.api.url}/equipment/repair-tickets/pictures/${payload.id}`,
      payload
    );
  }

  uploadRepairTicketPicture(payload: CreateRepairTicketPhotoPayload) {
    console.log(`Uploading to ${payload.repairTicketPicture.uploadUrl}`);
    const s3Payload: S3UploadPayload = {
      url: payload.repairTicketPicture.uploadUrl,
      file: payload.image,
      messages: {
        connected: 'Connected...',
        uploadProgress: `Uploading ${payload.image.name}...`,
        uploadProgressComplete: `Processing ${payload.image.name} on server...`,
      },
      entityType: 'RepairTicketPicture',
      entityId: payload.repairTicketPicture.id,
      onComplete: () => {
        // const repairTicketPicture: RepairTicketPicture = {
        //   ...payload.repairTicketPicture,
        //   imageExists: true,
        // };
        // this.store$.dispatch(new Update(RepairTicketPicture, repairTicketPicture));
        return this.store$
          .select(equipRepairTicketSelectors.selectIsSaving)
          .pipe(
            pairwise(),
            filter(([a, b]) => a === true && b === false),
            take(1)
          );
      },
    };
    //return this.s3Uploader.upload(s3Payload);
  }

  createRepairTicketNote(
    payload: EquipRepairTicketRepairNote
  ): Observable<EquipRepairTicketRepairNote> {
    return this.http.post<EquipRepairTicketRepairNote>(
      `${this.api.url}/equipment/repair-tickets/notes`,
      payload
    );
  }

  updateRepairTicketNote(
    payload: EquipRepairTicketRepairNote
  ): Observable<EquipRepairTicketRepairNote> {
    return this.http.patch<EquipRepairTicketRepairNote>(
      `${this.api.url}/equipment/repair-tickets/notes/${payload.id}`,
      payload
    );
  }

  rentals(minDate: Date, maxDate: Date): Observable<EquipmentRental[]> {
    const params = {
      min_date: format(minDate, 'yyyy-MM-dd'),
      max_date: format(maxDate, 'yyyy-MM-dd'),
    };
    return this.http.get<EquipmentRental[]>(
      `${this.api.url}/equipment/machines/rentals`,
      { params }
    );
  }
}
