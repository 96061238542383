import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { MaterialCategory } from '@app/models';

export const {
  initialState: materialCategoryInitialState,
  selectors: materialCategorySelectors,
  facade: MaterialCategoryFacadeBase,
} = buildState(MaterialCategory);

export function materialCategoryReducer(
  state = materialCategoryInitialState
): IEntityState<MaterialCategory> {
  return state;
}
