import { createAction, props } from '@ngrx/store';

export const loadAllSubcontractors = createAction('[Subcontractors] Load All');
export const loadAllSubcontractorsSuccess = createAction(
  '[Subcontractors API] Load All Success'
);
export const loadAllSubcontractorsError = createAction(
  '[Subcontractors API] Load All Error',
  props<{ error: any }>()
);
