import { createAction, props } from '@ngrx/store';
import { EquipTypeFilter } from '../equipment/equipment.reducer';
import { UserTimecard, TruckingMove } from '@app/models';
import {
  CreateRentalPayload,
  CreateRentalResponse,
  PlanRentalReturnPayload,
  RentalReturnPayload,
} from '@services/api/trucking.service';

export const loadAllTrucking = createAction('[Trucking] Load All');
export const loadAllTruckingSuccess = createAction(
  '[Trucking API] Load All Success'
);
export const loadAllTruckingError = createAction(
  '[Trucking API] Load All Error',
  props<{ error: any }>()
);

export const loadTruckingEquipLocs = createAction(
  '[Trucking] Load Equipment Locations'
);
export const loadTruckingEquipLocsSuccess = createAction(
  '[Trucking API] Load Equipment Locations Success'
);
export const loadTruckingEquipLocsError = createAction(
  '[Trucking API] Load Equipment Locations Error',
  props<{ error: any }>()
);
export const setTruckingEquipTypeFilter = createAction(
  '[Trucking] Set Trucking Equip EquipmentType Filter',
  props<EquipTypeFilter>()
);

export const createTruckingTimecard = createAction(
  '[Trucking] Create Timecard',
  props<{ payload: UserTimecard }>()
);
export const createTruckingTimecardSuccess = createAction(
  '[Trucking API] Create Timecard Success',
  props<{ payload: UserTimecard }>()
);
export const createTruckingTimecardError = createAction(
  '[Trucking API] Create Timecard Error',
  props<{ error: any }>()
);

export const updateTruckingTimecard = createAction(
  '[Trucking] Update Timecard',
  props<{ payload: UserTimecard }>()
);
export const updateTruckingTimecardSuccess = createAction(
  '[Trucking API] Update Timecard Success',
  props<{ payload: UserTimecard }>()
);
export const updateTruckingTimecardError = createAction(
  '[Trucking API] Update Timecard Error',
  props<{ error: any }>()
);

export const createTruckingRental = createAction(
  '[Trucking] Create Rental',
  props<{ payload: CreateRentalPayload }>()
);
export const createTruckingRentalSuccess = createAction(
  '[Trucking API] Create Rental Success',
  props<{ payload: CreateRentalResponse }>()
);
export const createTruckingRentalError = createAction(
  '[Trucking API] Create Rental Error',
  props<{ error: any }>()
);

export const planRentalReturn = createAction(
  '[Trucking] Plan Rental Return',
  props<{ payload: PlanRentalReturnPayload }>()
);
export const planRentalReturnSuccess = createAction(
  '[Trucking API] Plan Rental Return Success',
  props<{ payload: TruckingMove }>()
);
export const planRentalReturnError = createAction(
  '[Trucking API] Plan Rental Return Error',
  props<{ error: any }>()
);

export const rentalReturn = createAction(
  '[Trucking] Rental Return',
  props<{ payload: RentalReturnPayload }>()
);
export const rentalReturnSuccess = createAction(
  '[Trucking API] Rental Return Success'
);
export const rentalReturnError = createAction(
  '[Trucking API] Rental Return Error',
  props<{ error: any }>()
);
