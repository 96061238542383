import { createAction, props } from '@ngrx/store';

export const loadAllRestoration = createAction('[Restoration] Load All');
export const loadAllRestorationSuccess = createAction(
  '[Restoration API] Load All Success'
);
export const loadAllRestorationError = createAction(
  '[Restoration API] Load All Error',
  props<{ error: any }>()
);
