import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { EquipmentClass } from '@app/models';

export const {
  initialState: equipClassInitialState,
  selectors: equipClassSelectors,
  facade: EquipClassFacadeBase,
} = buildState(EquipmentClass);

export function equipClassReducer(
  state = equipClassInitialState
): IEntityState<EquipmentClass> {
  return state;
}
