import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  JobDate,
  Job,
  JobDateCrew,
  JobDateEquipment,
  JobFieldLog,
  JobDateTimecard,
  JobDateEquipTimecard,
  JobDateEquipOptTimecard,
  JobDateUntrackedEquipTimecard,
  JobFieldLogTotalTime,
  UserTimecard,
} from '@app/models';
import {
  FieldLogPayload,
  FieldLogEquipmentPayload,
  FieldLogUntrackedEquipPayload,
} from '@components/job/forms/clock-out-modal/clock-out-modal.component';

export interface JobDateResponse {
  jobDate: JobDate;
  job: Job;
  jobDateCrews: JobDateCrew[];
  jobDateEquipment: JobDateEquipment[];
  fieldLogs: JobFieldLog[];
  timecards: JobDateTimecard[];
  equipmentTimecards: JobDateEquipTimecard[];
  equipmentOptionTimecards: JobDateEquipOptTimecard[];
  untrackedEquipTimecards: JobDateUntrackedEquipTimecard[];
  totalTime: JobFieldLogTotalTime[];
}

export interface CreateTimecardResponse {
  timecard: JobDateTimecard;
  fieldLog: JobFieldLog;
  updateCrew: JobDateCrew;
}

export interface CreateTimecardRequest {
  fieldLog: JobFieldLog | { id: string };
  timecard: JobDateTimecard;
}

export interface ClockOutEquipPayload extends JobDateEquipTimecard {
  options: JobDateEquipOptTimecard[];
}

export interface ClockOutPayload {
  id: string;
  payload: ClockOutRequest;
}

export interface ClockOutRequest {
  timecard: JobDateTimecard;
  nightPay?: boolean;
  unscheduled?: boolean;
  equipment?: ClockOutEquipPayload[];
  untracked?: JobDateUntrackedEquipTimecard[];
  totalTime?: JobFieldLogTotalTime;
}

export interface ClockOutResponse {
  timecard: JobDateTimecard;
  equipmentTimecards: JobDateEquipTimecard[];
  equipmentOptionTimecards: JobDateEquipOptTimecard[];
  untrackedTimecards: JobDateUntrackedEquipTimecard[];
  totalTime: JobFieldLogTotalTime;
  deleteFieldLog: boolean;
}

export interface UpdateEquipTimecardPayload {
  equipment: FieldLogEquipmentPayload[];
  untracked: FieldLogUntrackedEquipPayload[];
}

export interface UpdateEquipTimecardsRequest {
  id: string;
  payload: UpdateEquipTimecardPayload;
}

export interface UpdateEquipTimecardsResponse {
  equipmentTimecards: JobDateEquipTimecard[];
  equipmentOptionTimecards: JobDateEquipOptTimecard[];
  untrackedTimecards: JobDateUntrackedEquipTimecard[];
}

export interface DeleteTimecardRequest {
  timecard: JobDateTimecard;
  fieldLog: JobFieldLog;
}

export interface DeleteTimecardResponse {
  fieldLogDeleted: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class JobDateService {
  constructor(private http: HttpClient, private api: ApiService) {}

  date(request: { job: string; date: string }): Observable<JobDateResponse> {
    const params = { date: request.date };
    return this.http.get<JobDateResponse>(
      `${this.api.url}/jobs/${request.job}/date`,
      { params }
    );
  }

  createTimecard(
    request: CreateTimecardRequest
  ): Observable<CreateTimecardResponse> {
    return this.http.post<CreateTimecardResponse>(
      `${this.api.url}/jobs/timecards`,
      request
    );
  }

  deleteTimecard(
    request: DeleteTimecardRequest
  ): Observable<DeleteTimecardResponse> {
    return this.http.delete<DeleteTimecardResponse>(
      `${this.api.url}/timecards/${request.timecard.id}`
    );
  }

  updateTimecard(request: JobDateTimecard): Observable<JobDateTimecard> {
    return this.http.patch<JobDateTimecard>(
      `${this.api.url}/jobs/timecards/${request.id}`,
      request
    );
  }

  clockOut(request: ClockOutPayload): Observable<ClockOutResponse> {
    return this.http.put<ClockOutResponse>(
      `${this.api.url}/jobs/field-logs/${request.id}/clockout`,
      request.payload
    );
  }

  updateEquipmentTime(
    request: UpdateEquipTimecardsRequest
  ): Observable<UpdateEquipTimecardsResponse> {
    return this.http.put<UpdateEquipTimecardsResponse>(
      `${this.api.url}/jobs/field-logs/${request.id}/equipment`,
      request.payload
    );
  }
}
