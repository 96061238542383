import { createReducer, on, Action, createFeatureSelector } from '@ngrx/store';
import { JobDate } from '@app/models/job-date.model';
import * as actions from './job-date.actions';
import { AppState } from '../app.state';

export interface State {
  loading: boolean;
  loaded: boolean;
  error: any;
  jobDate: JobDate;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  error: null,
  jobDate: null,
};

const jobDateReducer = createReducer(
  initialState,
  on(actions.loadJobDate, state => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
    jobDate: null,
  })),
  on(actions.loadJobDateSuccess, (state, { jobDate }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    jobDate,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return jobDateReducer(state, action);
}

export const selectJobDate = createFeatureSelector<State>('jobDate');
