import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobRestorationMedia } from '@app/models';

export const {
  initialState: jobRestorationMediaInitialState,
  selectors: jobRestorationMediaSelectors,
  facade: jobRestorationMediaFacadeBase,
} = buildState(JobRestorationMedia);

export function jobRestorationMediaReducer(
  state = jobRestorationMediaInitialState
): IEntityState<JobRestorationMedia> {
  return state;
}
