import { createEffect, ofType, Actions } from '@ngrx/effects';
import { LoadAllSuccess, LoadManySuccess } from '@briebug/ngrx-auto-entity';
import {
  loadAllEquipment,
  loadAllEquipmentSuccess,
  loadAllEquipmentError,
  loadEquipmentLocations,
  loadEquipmentLocationsSuccess,
  loadEquipmentLocationsError,
} from './equipment.actions';
import { exhaustMap, mergeMap, catchError, map } from 'rxjs/operators';
import { EquipService } from '@services/api/equip.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import {
  EquipmentClass,
  EquipmentType,
  EquipmentModel,
  EquipmentMachine,
  EquipmentOption,
  EquipmentOptionType,
  EquipmentUntrackedClass,
  EquipmentUntrackedMachine,
  JobEquipment,
  Job,
} from '@app/models';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class EquipmentEffects {
  loadAllEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllEquipment),
      exhaustMap(() =>
        this.equipService.loadAll().pipe(
          map(equip => {
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentClass, equip.equipmentClasses)
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentType, equip.equipmentTypes)
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentModel, equip.equipmentModels)
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentMachine, equip.equipmentMachines)
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentOption, equip.equipmentOptions)
            );
            this.store$.dispatch(
              new LoadAllSuccess(
                EquipmentOptionType,
                equip.equipmentOptionTypes
              )
            );
            this.store$.dispatch(
              new LoadAllSuccess(
                EquipmentUntrackedClass,
                equip.untrackedClasses
              )
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentUntrackedMachine, equip.untracked)
            );
            return loadAllEquipmentSuccess();
          }),
          catchError(error => {
            return of(loadAllEquipmentError({ error }));
          })
        )
      )
    )
  );

  loadEquipmentLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEquipmentLocations),
      exhaustMap(() =>
        this.equipService.loadLocations().pipe(
          map(res => {
            this.store$.dispatch(
              new LoadAllSuccess(JobEquipment, res.jobEquipment)
            );
            this.store$.dispatch(new LoadManySuccess(Job, res.jobs));
            return loadEquipmentLocationsSuccess();
          }),
          catchError(error => {
            return of(loadEquipmentLocationsError({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private equipService: EquipService,
    private store$: Store<AppState>
  ) {}
}
