import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { TruckingMove } from '@app/models';

export const {
  initialState: truckingMoveInitialState,
  selectors: truckingMoveSelectors,
  facade: TruckingMoveFacadeBase,
} = buildState(TruckingMove);

export function truckingMoveReducer(
  state = truckingMoveInitialState
): IEntityState<TruckingMove> {
  return state;
}
