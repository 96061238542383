import { JobIncidentMedia } from '@app/models';
import { map, concatMap } from 'rxjs/operators';
import { AppState } from '@app/state';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import {
  CreateJobIncidentMediaPayload,
  JobIncidentMediaService,
} from '@services/api/job-incident-media.service';
import {
  ImageUploadService,
  PhotoMeta,
} from '@services/image-upload/image-upload.service';
import { CreateSuccess } from '@briebug/ngrx-auto-entity';
import { EMPTY } from 'rxjs';

export interface CreateJobIncidentMediaRequest {
  jobIncident: string;
  employee: string;
  fileName: string;
  photo: PhotoMeta;
}

export const createJobIncidentMedia = createAction(
  '[Job Incident Media] Create',
  props<{ payload: CreateJobIncidentMediaRequest }>()
);

export const uploadJobIncidentMedia = createAction(
  '[Job Incident Media] Upload',
  props<{ payload: CreateJobIncidentMediaPayload }>()
);

@Injectable()
export class JobIncidentMediaEffects {
  createIncidentMedia$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createJobIncidentMedia),
        map(action => action.payload),
        concatMap(payload => {
          console.log('createJobIncidentMedia');
          const jm: JobIncidentMedia = {
            jobIncident: payload.jobIncident,
            employee: payload.employee,
            originalFileName: payload.fileName,
            imageExists: false,
          };
          return this.jobIncidentMediaService.create(jm).pipe(
            map(async response => {
              this.store$.dispatch(
                new CreateSuccess(JobIncidentMedia, response)
              );
              const photo = {
                ...payload.photo,
                id: response.id,
              };
              await this.imageUpload.addPhoto(photo);

              const newPayload: CreateJobIncidentMediaPayload = {
                jobIncidentMedia: response,
                image: await this.imageUpload.getPhotoFile(response.id),
              };
              this.store$.dispatch(
                uploadJobIncidentMedia({ payload: newPayload })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          );
        })
      ),
    { dispatch: false }
  );

  uploadIncidentMedia$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadJobIncidentMedia),
        map(action => action.payload),
        concatMap(payload => {
          console.log('uploadJobIncidentMedia');
          this.jobIncidentMediaService.uploadFile(payload);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobIncidentMediaService: JobIncidentMediaService,
    private imageUpload: ImageUploadService,
    private store$: Store<AppState>
  ) {}
}
