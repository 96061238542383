import { createAction, props } from '@ngrx/store';

export const setBackgroundTaskId = createAction(
  '[App State] Set Background Task Id',
  props<{ activeBackgroundTaskId: string }>()
);
export const clearBackgroundTaskId = createAction(
  '[App State] Clear Background Task Id'
);
export const appResume = createAction('[App State] Resume');
export const appBackground = createAction('[App State] Background');
