<ion-content color="dark">
  <div class="message">
    <h2>
      A network connection is required.
    </h2>
    <p>
      Please check to make sure you have a mobile signal, or are connected to
      wifi.
    </p>
    <p>This message will disappear once a network connection is established.</p>
  </div>
  <div class="bg">
    <fa-icon [icon]="['fas', 'exclamation-triangle']" size="4x"></fa-icon>
  </div>
</ion-content>
