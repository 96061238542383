import { parseISO } from 'date-fns';
import { createSelector } from '@ngrx/store';
import { jobIncidentSelectors } from './job-incident.state';
import { userSelectors } from './user.state';
import { JobIncidentVM } from '@app/models';

export const selectJobIncidentVMAll = createSelector(
  jobIncidentSelectors.selectAll,
  userSelectors.selectEntities,
  (incidents, users): JobIncidentVM[] => {
    return incidents.map(
      (incident): JobIncidentVM => {
        return {
          ...incident,
          employee:
            incident.employee in users ? users[incident.employee] : null,
          created: incident.created ? parseISO(incident.created) : null,
        };
      }
    );
  }
);
