import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlanTentative } from '@app/models';

export const {
  initialState: jobPlanTentativeInitialState,
  selectors: jobPlanTentativeSelectors,
  facade: JobPlanTentativeFacadeBase,
} = buildState(JobPlanTentative);

export function jobPlanTentativeReducer(
  state = jobPlanTentativeInitialState
): IEntityState<JobPlanTentative> {
  return state;
}
