import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { User } from './user.model';
import { SubcontractorVendor } from './subcontractor-vendor.model';
import { SubcontractorCategory } from './subcontractor-category.model';

@Entity({ modelName: 'JobSubcontractor' })
export class JobSubcontractor {
  @Key id?: string;
  job: string;
  extraFieldLog: string;
  employee: string;
  vendor: number;
  category: number;
  description: string;
  created?: string;
  date: string;
}

export interface JobSubcontractorVM {
  id?: string;
  job: string;
  extraFieldLog: string;
  employee: User;
  vendor: SubcontractorVendor;
  category: SubcontractorCategory;
  description: string;
  created?: Date;
  date: Date;
}
