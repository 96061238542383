import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { EquipmentUntrackedClass } from '@app/models';

export const {
  initialState: equipUntrackedClassInitialState,
  selectors: equipUntrackedClassSelectors,
  facade: EquipUntrackedClassFacadeBase,
} = buildState(EquipmentUntrackedClass);

export function equipUntrackedClassReducer(
  state = equipUntrackedClassInitialState
): IEntityState<EquipmentUntrackedClass> {
  return state;
}
