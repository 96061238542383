import { createEffect, ofType, Actions } from '@ngrx/effects';
import { LoadAllSuccess, LoadManySuccess } from '@briebug/ngrx-auto-entity';
import {
  loadJobPlanner,
  loadJobPlannerSuccess,
  loadJobPlannerError,
  updateJobPlannerDates,
  updateJobPlannerDatesSuccess,
  updateJobPlannerDatesError,
} from './job-planner.actions';
import { exhaustMap, catchError, map } from 'rxjs/operators';
import { JobPlannerService } from '@services/api/job-planner.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import {
  JobPlan,
  Job,
  JobPlanManaged,
  JobPlanManager,
  JobPlanTentative,
  EmergencyTicket,
  JobDateNote,
  JobPlanAlarm,
  EquipmentUseDate,
} from '@app/models';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { PlannerUIService } from '@shared/planner/planner-ui.service';
import { parseISO } from 'date-fns';
import { JobAlert } from '@app/models/job-alert.model';

@Injectable()
export class JobPlannerEffects {
  loadJobPlanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadJobPlanner),
      exhaustMap(action =>
        this.jobPlannerService.load(action.dateRange).pipe(
          map(jobPlanner => {
            this.store$.dispatch(
              new LoadAllSuccess(
                JobPlan,
                jobPlanner.jobPlans.map(jp => ({ ...jp, details: 'none' }))
              )
            );
            this.store$.dispatch(new LoadManySuccess(Job, jobPlanner.jobs));
            this.store$.dispatch(
              new LoadAllSuccess(JobPlanManaged, jobPlanner.jobPlanManaged)
            );
            this.store$.dispatch(
              new LoadAllSuccess(JobPlanManager, jobPlanner.managers)
            );
            this.store$.dispatch(
              new LoadManySuccess(JobPlanTentative, jobPlanner.tentative)
            );
            this.store$.dispatch(
              new LoadAllSuccess(EmergencyTicket, jobPlanner.emergencyTickets)
            );
            this.store$.dispatch(
              new LoadAllSuccess(JobDateNote, jobPlanner.jobDateNotes)
            );
            this.store$.dispatch(
              new LoadAllSuccess(JobAlert, jobPlanner.jobAlerts)
            );
            this.store$.dispatch(
              new LoadAllSuccess(JobPlanAlarm, jobPlanner.jobAlarms)
            );
            this.store$.dispatch(
              new LoadAllSuccess(EquipmentUseDate, jobPlanner.equipUse)
            );
            return loadJobPlannerSuccess({ dateRange: action.dateRange });
          }),
          catchError(error => {
            return of(loadJobPlannerError({ error }));
          })
        )
      )
    )
  );

  updateJobPlannerDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateJobPlannerDates),
      exhaustMap(action =>
        this.jobPlannerService.extendDates(action.payload).pipe(
          map(jobPlanner => {
            this.store$.dispatch(
              new LoadManySuccess(
                JobPlan,
                jobPlanner.jobPlans.map(jp => ({ ...jp, details: 'none' }))
              )
            );
            this.store$.dispatch(new LoadManySuccess(Job, jobPlanner.jobs));
            this.store$.dispatch(
              new LoadManySuccess(JobPlanManaged, jobPlanner.jobPlanManaged)
            );
            this.store$.dispatch(
              new LoadManySuccess(JobPlanManager, jobPlanner.managers)
            );
            this.store$.dispatch(
              new LoadManySuccess(JobPlanTentative, jobPlanner.tentative)
            );
            this.store$.dispatch(
              new LoadManySuccess(EmergencyTicket, jobPlanner.emergencyTickets)
            );
            this.jobPlannerUI.dateRange = {
              startDate: parseISO(action.payload.minDate),
              endDate: parseISO(action.payload.maxDate),
            };
            return updateJobPlannerDatesSuccess({ payload: action.payload });
          }),
          catchError(error => {
            return of(updateJobPlannerDatesError({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private jobPlannerService: JobPlannerService,
    private store$: Store<AppState>,
    private jobPlannerUI: PlannerUIService
  ) {}
}
