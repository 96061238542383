import { EquipmentUntrackedMachine } from './equipment-untracked-machine.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'EquipmentUntrackedClass' })
export class EquipmentUntrackedClass {
  @Key id: number;
  name: string;
  delta: number;
}

export interface EquipmentUntrackedClassVM {
  id: number;
  name: string;
  delta: number;
  machines: EquipmentUntrackedMachine[];
}
