import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'ImportStatus' })
export class ImportStatus {
  @Key id: string;
  entity: string;
  status: string;
  user: string;
  recordsBefore: number;
  recordsAfter: number;
  started: string;
  completed: string;
}
