import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'UserRole' })
export class UserRole {
  @Key id: number;
  role: string;
  trucking: boolean;
  planner: boolean;
  export: boolean;
  shop: boolean;
  shopHours: boolean;
  emergencyTickets: boolean;
  admin: boolean;
}
