import { selectAllEqupTime } from './job-date.join-selectors';
import { loadUserMe } from './../user-me/user-me.actions';
import { JobDateEquipment } from '@app/models/job-date-equipment.model';
import { map, exhaustMap, withLatestFrom } from 'rxjs/operators';
import {
  loadJobDate,
  loadJobDateSuccess,
  createTimecard,
  clockOut,
  updateEquipTimecards,
  deleteTimecard,
  updateTimecard,
} from './job-date.actions';
import { Injectable } from '@angular/core';
import { AppState } from '@app/state';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JobDateService } from '@services/api/job-date.service';
import {
  LoadSuccess,
  LoadAllSuccess,
  CreateSuccess,
  CreateManySuccess,
  DeleteManySuccess,
  LoadManySuccess,
  DeleteSuccess,
  UpdateSuccess,
  DeleteByKey,
} from '@briebug/ngrx-auto-entity';
import {
  Job,
  JobDateCrew,
  JobFieldLog,
  JobDateTimecard,
  JobDateEquipTimecard,
  JobDateEquipOptTimecard,
  JobDateUntrackedEquipTimecard,
  JobFieldLogTotalTime,
} from '@app/models';

@Injectable()
export class JobDateEffects {
  loadJobDate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadJobDate),
        map(action => action.payload),
        exhaustMap(request =>
          this.jobDateService.date(request).pipe(
            map(response => {
              this.store$.dispatch(new LoadSuccess(Job, response.job));
              this.store$.dispatch(
                new LoadAllSuccess(JobDateCrew, response.jobDateCrews)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobDateEquipment, response.jobDateEquipment)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobFieldLog, response.fieldLogs)
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobDateTimecard, response.timecards)
              );
              this.store$.dispatch(
                new LoadAllSuccess(
                  JobDateEquipTimecard,
                  response.equipmentTimecards
                )
              );
              this.store$.dispatch(
                new LoadAllSuccess(
                  JobDateEquipOptTimecard,
                  response.equipmentOptionTimecards
                )
              );
              this.store$.dispatch(
                new LoadAllSuccess(
                  JobDateUntrackedEquipTimecard,
                  response.untrackedEquipTimecards
                )
              );
              this.store$.dispatch(
                new LoadAllSuccess(JobFieldLogTotalTime, response.totalTime)
              );
              this.store$.dispatch(
                loadJobDateSuccess({ jobDate: response.jobDate })
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  createTimecard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createTimecard),
        map(action => action.payload),
        exhaustMap(request =>
          this.jobDateService.createTimecard(request).pipe(
            map(response => {
              console.log(' Create Timecard Effect ');
              console.log(response);
              this.store$.dispatch(
                new LoadSuccess(JobFieldLog, response.fieldLog)
              );
              this.store$.dispatch(
                new CreateSuccess(JobDateTimecard, response.timecard)
              );
              if (response.updateCrew !== null) {
                this.store$.dispatch(
                  new UpdateSuccess(JobDateCrew, response.updateCrew)
                );
              }
              this.store$.dispatch(loadUserMe());
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  updateTimecard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTimecard),
        map(action => action.payload),
        exhaustMap(request =>
          this.jobDateService.updateTimecard(request).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(JobDateTimecard, response)
              );
              this.store$.dispatch(loadUserMe());
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  deleteTimecard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteTimecard),
        map(action => action.payload),
        exhaustMap(request =>
          this.jobDateService.deleteTimecard(request).pipe(
            map(response => {
              const jobFieldLog = request.fieldLog;
              this.store$.dispatch(
                new DeleteSuccess(JobDateTimecard, request.timecard)
              );
              if (response.fieldLogDeleted && jobFieldLog !== null) {
                this.store$.dispatch(
                  new DeleteSuccess(JobFieldLog, jobFieldLog)
                );
              }
              this.store$.dispatch(loadUserMe());
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  clockOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clockOut),
        map(action => action.payload),
        exhaustMap(request =>
          this.jobDateService.clockOut(request).pipe(
            map(response => {
              if (response.timecard) {
                this.store$.dispatch(
                  new LoadSuccess(JobDateTimecard, response.timecard)
                );
              } else {
                this.store$.dispatch(
                  new DeleteSuccess(JobDateTimecard, request.payload.timecard)
                );
              }
              this.store$.dispatch(
                new CreateManySuccess(
                  JobDateEquipTimecard,
                  response.equipmentTimecards
                )
              );
              this.store$.dispatch(
                new CreateManySuccess(
                  JobDateEquipOptTimecard,
                  response.equipmentOptionTimecards
                )
              );
              this.store$.dispatch(
                new CreateManySuccess(
                  JobDateUntrackedEquipTimecard,
                  response.untrackedTimecards
                )
              );
              if (response.totalTime) {
                this.store$.dispatch(
                  new CreateSuccess(JobFieldLogTotalTime, response.totalTime)
                );
              }
              if (response.deleteFieldLog) {
                this.store$.dispatch(
                  new DeleteByKey(
                    JobFieldLog,
                    request.payload.timecard.jobFieldLog
                  )
                );
              }
              this.store$.dispatch(loadUserMe());
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  updateEquipmentTime$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateEquipTimecards),
        map(action => action.payload),
        exhaustMap(request =>
          this.jobDateService.updateEquipmentTime(request).pipe(
            withLatestFrom(this.store$.pipe(select(selectAllEqupTime))),
            map(([response, existing]) => {
              const id = request.id;
              const equipIds = response.equipmentTimecards.map(tc => tc.id);
              const equipOptIds = response.equipmentOptionTimecards.map(
                tc => tc.id
              );
              const untIds = response.untrackedTimecards.map(tc => tc.id);

              const equipById: { [key: string]: JobDateEquipTimecard } = {};
              existing.equipmentTimecards.forEach(
                equip => (equipById[equip.id] = equip)
              );

              const equipToDelete = existing.equipmentTimecards
                .filter(equip => equip.fieldLog === id)
                .filter(equip => !equipIds.includes(equip.id));

              const untrackedToDelete = existing.equipmentUntrackedTimecards
                .filter(equip => equip.fieldLog === id)
                .filter(equip => !untIds.includes(equip.id));

              const optToDelete = existing.equipmentOptTimecards
                .filter(equip => equipById[equip.jeTimecard].fieldLog === id)
                .filter(equip => !equipOptIds.includes(equip.id));

              this.store$.dispatch(
                new DeleteManySuccess(JobDateEquipTimecard, equipToDelete)
              );
              this.store$.dispatch(
                new DeleteManySuccess(JobDateEquipOptTimecard, optToDelete)
              );
              this.store$.dispatch(
                new DeleteManySuccess(
                  JobDateUntrackedEquipTimecard,
                  untrackedToDelete
                )
              );

              this.store$.dispatch(
                new LoadManySuccess(
                  JobDateEquipTimecard,
                  response.equipmentTimecards
                )
              );
              this.store$.dispatch(
                new LoadManySuccess(
                  JobDateEquipOptTimecard,
                  response.equipmentOptionTimecards
                )
              );
              this.store$.dispatch(
                new LoadManySuccess(
                  JobDateUntrackedEquipTimecard,
                  response.untrackedTimecards
                )
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobDateService: JobDateService,
    private store$: Store<AppState>
  ) {}
}
