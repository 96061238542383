import { UserVM } from './user.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobDateTimecard' })
export class JobDateTimecard {
  @Key id?: string;
  user: string;
  timecardType?: string;
  job?: string;
  jobFieldLog: string;
  clockIn: string;
  clockOut?: string;
  clockInLat?: number;
  clockInLng?: number;
  clockOutLat?: number;
  clockOutLng?: number;
  breakMinutes?: number;
  skipMorningBreak?: boolean;
  skipAfternoonBreak?: boolean;
  status?: string;
  clockInTimestamp?: string;
  clockOutTimestamp?: string;
}

export interface JobDateTimecardVM {
  id?: string;
  user: UserVM;
  timecardType?: string;
  job?: string;
  jobFieldLog: string;
  clockIn: Date;
  clockOut?: Date;
  clockInLat?: number;
  clockInLng?: number;
  clockOutLat?: number;
  clockOutLng?: number;
  breakMinutes?: number;
  skipMorningBreak?: boolean;
  skipAfternoonBreak?: boolean;
  status?: string;
}
