import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobExtra } from '@app/models';

export const {
  initialState: jobExtraInitialState,
  selectors: jobExtraSelectors,
  facade: JobExtraFacadeBase,
} = buildState(JobExtra);

export function jobExtraReducer(
  state = jobExtraInitialState
): IEntityState<JobExtra> {
  return state;
}
