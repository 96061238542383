import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  InterruptedUploadData,
  S3UploadService,
} from '@services/api/s3-upload.service';
import { Observable, of } from 'rxjs';
import { concatMap, filter, take, tap } from 'rxjs/operators';
import { AppState } from '..';
import { appBackground, appResume } from '../app-state/app-state.actions';

import { Capacitor } from '@capacitor/core';
import { JobMediaService } from '@services/api/job-media.service';
import { JobIncidentMediaService } from '@services/api/job-incident-media.service';
import { JobDocumentService } from '@services/api/job-document.service';
import { BackgroundTask } from '@capawesome/capacitor-background-task';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { StorageService } from '@services/storage/storage.service';
async function getIncompleteUploadFile(filename: string): Promise<File> {
  const imageUri = await Filesystem.getUri({
    directory: Directory.Data,
    path: filename,
  });

  const imageUrl = Capacitor.convertFileSrc(imageUri.uri);
  const blob = await fetch(imageUrl).then(r => r.blob());
  const mimeType = 'image/jpeg';
  //const filename = imageUri.substring(imageUri.lastIndexOf('/') + 1);
  return new File([blob], filename, { type: mimeType });
}

@Injectable()
export class BackgroundUploadEffects {
  backgroundUpload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appBackground),
        concatMap(() => {
          console.log('backgrounding..');
          return this.setupBackgroundTask(taskId => {
            this.s3UploadSvc.setExpiration(25000);
            return this.s3UploadSvc.activeStatus$
              .pipe(
                filter(status => status === 'none' || status === 'expired'),
                take(1)
              )
              .pipe(
                tap(result => {
                  if (result && result === 'none') {
                    console.log('uploads completed!');
                    this.s3UploadSvc.finishingUploads$
                      .pipe(
                        filter(finishing => finishing.length === 0),
                        take(1)
                      )
                      .subscribe(_ => {
                        console.log('Background Task Completed.');
                        BackgroundTask.finish({ taskId });
                      });
                  } else {
                    console.log('Not completed.');
                    this.s3UploadSvc.interruptUploads().then(() => {
                      this.s3UploadSvc.finishingUploads$
                        .pipe(
                          filter(finishing => finishing.length === 0),
                          take(1)
                        )
                        .subscribe(_ => {
                          BackgroundTask.finish({ taskId });
                        });
                    });
                  }
                })
              );
          });
        })
      ),
    { dispatch: false }
  );

  resumeUpload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appResume),
        concatMap(async () => {
          console.log('resuming...');
          this.s3UploadSvc.cancelExperiation();
          await this.s3UploadSvc.interruptingUploads$
            .pipe(
              filter(status => status === false),
              take(1)
            )
            .toPromise();
          // handle incomplete uploads
          const keys = await this.storage.keys();
          if (keys.includes('INCOMPLETE_UL')) {
            const uploadData: InterruptedUploadData[] = JSON.parse(
              await this.storage.get('INCOMPLETE_UL')
            );
            for (const upload of uploadData) {
              const file: File = await getIncompleteUploadFile(upload.file);
              switch (upload.entityType) {
                case 'JobMedia':
                  this.jobMedia.continueUpload(upload.entityId, file);
                  break;
                case 'JobIncidentMedia':
                  this.jobIncidentMedia.continueUpload(upload.entityId, file);
                  break;
                case 'JobDocument':
                  this.jobDocument.continueUpload(upload.entityId, file);
                  break;
              }
            }
            await this.storage.remove('INCOMPLETE_UL');
          }
          return null;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private s3UploadSvc: S3UploadService,
    private storage: StorageService,
    private jobMedia: JobMediaService,
    private jobIncidentMedia: JobIncidentMediaService,
    private jobDocument: JobDocumentService
  ) {}

  private setupBackgroundTask<T>(taskCb: (taskId: string) => Observable<T>) {
    return of(null).pipe(
      tap(async () => {
        try {
          const taskId = await BackgroundTask.beforeExit(async () => {
            await taskCb(taskId).toPromise();
          });
        } catch (err) {
          console.log(err);
        }
      })
    );
  }
}
