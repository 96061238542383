import { Injectable } from '@angular/core';
import { EquipmentMachine } from '@app/models';
import { CreateSuccess } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, props, Store } from '@ngrx/store';
import { CreateEquipPayload, EquipService } from '@services/api/equip.service';
import { exhaustMap, map } from 'rxjs/operators';
import { AppState } from '.';

export const createEquipMachine = createAction(
  '[Equipment Machines] Create',
  props<{ payload: CreateEquipPayload }>()
);

@Injectable()
export class EquipmentMachineEffects {
  createEquipMachine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createEquipMachine),
        exhaustMap(action =>
          this.equipSvc.createMachineWithLocation(action.payload).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateSuccess(EquipmentMachine, response)
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private equipSvc: EquipService,
    private store$: Store<AppState>
  ) {}
}
