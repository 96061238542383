import { createAction, props } from '@ngrx/store';

export const loadJobDetails = createAction(
  '[Job Details] Load',
  props<{ job: string }>()
);
export const loadJobDetailsSuccess = createAction(
  '[Job Details API] Load Success',
  props<{ job: string }>()
);
export const loadJobDetailsError = createAction(
  '[Job Details API] Load Error',
  props<{ error: any }>()
);
export const updateJobLatLng = createAction(
  '[Job Details API] Update Lat Lng',
  props<{ job: string; location: { lat: number; lng: number } }>()
);
export const clearJobDetails = createAction('[Job Details] Clear');
