import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobPlan } from '@app/models';

export const {
  initialState: jobPlanInitialState,
  selectors: jobPlanSelectors,
  facade: JobPlanFacadeBase,
} = buildState(JobPlan);

export function jobPlanReducer(
  state = jobPlanInitialState
): IEntityState<JobPlan> {
  return state;
}
