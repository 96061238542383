import { Key, Entity } from '@briebug/ngrx-auto-entity';
import { EquipmentMachineVM } from './equipment-machine.model';

@Entity({ modelName: 'JobDateEquipment' })
export class JobDateEquipment {
  @Key id?: string;
  equip: number;
  arrival: string;
  departure: string;
  actualDeparture: string;
  equipRequirement: string;
  status: string;
  job: string;
}

export interface JobDateEquipmentVM {
  id?: string;
  equip: EquipmentMachineVM;
  arrival: Date;
  departure: Date;
  actualDeparture: Date;
  equipRequirement: string;
  status: string;
  job: string;
}
