import { JobLocate } from '@app/models';
import { Update } from '@briebug/ngrx-auto-entity';
import { exhaustMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppState } from '@app/state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, props, Store } from '@ngrx/store';
import { ActivateLocateRequest, JobService } from '@services/api/job.service';

export const activateJobLocate = createAction(
  '[Job Locate] Activate',
  props<{ payload: ActivateLocateRequest }>()
);

@Injectable()
export class JobLocateEffects {
  activateTicket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(activateJobLocate),
        exhaustMap(action =>
          this.jobService.activateLocate(action.payload).pipe(
            map(ticket => {
              this.store$.dispatch(new Update(JobLocate, ticket));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private jobService: JobService,
    private store$: Store<AppState>
  ) {}
}
