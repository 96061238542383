import { ContractExtraFieldLog } from '@app/models';
import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';

export const {
  initialState: contractExtraFieldLogInitialState,
  selectors: contractExtraFieldLogSelectors,
  facade: ContractExtraFieldLogFacadeBase,
} = buildState(ContractExtraFieldLog);

export function contractExtraFieldLogReducer(
  state = contractExtraFieldLogInitialState
): IEntityState<ContractExtraFieldLog> {
  return state;
}
