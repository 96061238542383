import { ContractExtra } from '@app/models/contract-extra.model';
import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';

export const {
  initialState: contractExtraInitialState,
  selectors: contractExtraSelectors,
  facade: ContractExtraFacadeBase,
} = buildState(ContractExtra);

export function contractExtraReducer(
  state = contractExtraInitialState
): IEntityState<ContractExtra> {
  return state;
}
