<ion-header>
  <ion-toolbar>
    <ion-title>Multiple Active Timecards!</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item color="warning">
      <ion-label class="ion-text-wrap">
        You are currently clocked into multiple timecards. Please choose one to
        delete. You can clock in again, after completing the other timecard.
      </ion-label>
    </ion-item>
    <ion-item *ngFor="let timecard of timecards">
      <ion-label>
        <span *ngIf="jobs[timecard.job] as job">{{ job.jobNumber }}</span>
        <span *ngIf="!jobs[timecard.job]">SHOP</span>
        clocked in at {{ timecard.clockIn | date: 'short' }}
      </ion-label>
      <ion-buttons slot="end">
        <ion-button (click)="onDelete(timecard)" color="danger"
          ><ion-icon slot="icon-only" name="trash"></ion-icon
        ></ion-button>
      </ion-buttons>
    </ion-item>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-toolbar> </ion-toolbar>
</ion-footer>
