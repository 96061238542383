import { EquipmentUntrackedMachine } from './equipment-untracked-machine.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobDateUntrackedEquipTimecard' })
export class JobDateUntrackedEquipTimecard {
  @Key id?: string;
  fieldLog: string;
  equip: number;
  minutes: number;
}

export interface JobDateUntrackedEquipTimecardVM {
  id?: string;
  fieldLog: string;
  equip: EquipmentUntrackedMachine;
  minutes: number;
  hours: number;
}
