import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobDateEquipment } from '@app/models';

export const {
  initialState: jobDateEquipmentInitialState,
  selectors: jobDateEquipmentSelectors,
  facade: JobDateEquipmentFacadeBase,
} = buildState(JobDateEquipment);

export function jobDateEquipmentReducer(
  state = jobDateEquipmentInitialState
): IEntityState<JobDateEquipment> {
  return state;
}
