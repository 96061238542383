import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { JobFieldLogTotalTime } from '@app/models';

export const {
  initialState: jobFieldLogTotalTimeInitialState,
  selectors: jobFieldLogTotalTimeSelectors,
  facade: JobFieldLogTotalTimeFacadeBase,
} = buildState(JobFieldLogTotalTime);

export function jobFieldLogTotalTimeReducer(
  state = jobFieldLogTotalTimeInitialState
): IEntityState<JobFieldLogTotalTime> {
  return state;
}
