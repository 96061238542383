import {
  LoadAllSuccess,
  LoadManySuccess,
  CreateSuccess,
  UpdateSuccess,
  UpdateManySuccess,
  DeleteSuccess,
} from '@briebug/ngrx-auto-entity';
import { exhaustMap, map } from 'rxjs/operators';
import { AppState } from './app.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import {
  EmergencyTicketService,
  CreateEmergencyTicketPayload,
  CreateEmergencyCrewRequest,
  UpdateEmergencyTicketPayload,
  DeleteEmergencyCrewRequest,
  DeleteEmergencyTicketPayload,
} from '@services/api/emergency-ticket.service';
import {
  EmergencyTicket,
  Job,
  JobPlan,
  JobLocate,
  JobPlanCrewRequirement,
  JobPlanCrewAssignment,
  Crew,
  JobPlanEquipReq,
} from '@app/models';

export const loadEmergencyTickets = createAction('[Emergency Tickets] Load');
export const loadEmergencyTicketsPlanner = createAction(
  '[Emergency Tickets] Load Planner'
);

export const createEmergencyTicket = createAction(
  '[Emergency Tickets] Create',
  props<{ payload: CreateEmergencyTicketPayload }>()
);

export const createEmergencyCrew = createAction(
  '[Emergency Tickets] Create Crew',
  props<{ payload: CreateEmergencyCrewRequest }>()
);

export const deleteEmergencyCrew = createAction(
  '[Emergency Tickets] Delete Crew',
  props<{ payload: DeleteEmergencyCrewRequest }>()
);

export const updateEmergencyTicket = createAction(
  '[Emergency Tickets] Update Ticket',
  props<{ payload: UpdateEmergencyTicketPayload }>()
);

export const deleteEmergencyTicket = createAction(
  '[Emergency Tickets] Delete Ticket',
  props<{ payload: DeleteEmergencyTicketPayload }>()
);

@Injectable()
export class EmergencyTicketEffects {
  loadEmergTickets$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadEmergencyTickets),
        exhaustMap(action =>
          this.emergTicketService.loadAll().pipe(
            map(response => {
              this.store$.dispatch(
                new LoadAllSuccess(EmergencyTicket, response.emergencyTickets)
              );
              this.store$.dispatch(new LoadManySuccess(Job, response.jobs));
              this.store$.dispatch(
                new LoadManySuccess(JobPlan, response.jobPlans)
              );
              this.store$.dispatch(
                new LoadManySuccess(JobLocate, response.locates)
              );
              this.store$.dispatch(
                new LoadAllSuccess(
                  JobPlanCrewRequirement,
                  response.crewRequirements
                )
              );
              this.store$.dispatch(
                new LoadAllSuccess(
                  JobPlanCrewAssignment,
                  response.crewAssignments
                )
              );
              this.store$.dispatch(new LoadManySuccess(Crew, response.crews));
              this.store$.dispatch(
                new LoadManySuccess(JobPlanEquipReq, response.equipRequirements)
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  loadPlannerEmergTickets$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadEmergencyTicketsPlanner),
        exhaustMap(action =>
          this.emergTicketService.loadPlanner().pipe(
            map(response => {
              this.store$.dispatch(
                new LoadAllSuccess(EmergencyTicket, response.emergencyTickets)
              );
              this.store$.dispatch(new LoadManySuccess(Job, response.jobs));
              this.store$.dispatch(
                new LoadManySuccess(JobPlan, response.jobPlans)
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  createEmergTickets$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createEmergencyTicket),
        exhaustMap(action =>
          this.emergTicketService.createTicket(action.payload).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateSuccess(EmergencyTicket, response.emergencyTicket)
              );
              this.store$.dispatch(new CreateSuccess(Job, response.job));
              this.store$.dispatch(
                new CreateSuccess(JobPlan, response.jobPlan)
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  deleteEmergTicket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteEmergencyTicket),
        exhaustMap(action =>
          this.emergTicketService.deleteTicket(action.payload).pipe(
            map(response => {
              this.store$.dispatch(
                new DeleteSuccess(
                  EmergencyTicket,
                  action.payload.emergencyTicket
                )
              );
              this.store$.dispatch(
                new DeleteSuccess(JobPlan, action.payload.jobPlan)
              );
              this.store$.dispatch(new DeleteSuccess(Job, action.payload.job));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  deleteEmergCrew$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteEmergencyCrew),
        exhaustMap(action =>
          this.emergTicketService.deleteCrew(action.payload).pipe(
            map(response => {
              this.store$.dispatch(
                new DeleteSuccess(
                  JobPlanCrewAssignment,
                  action.payload.crewAssignment
                )
              );
              this.store$.dispatch(
                new DeleteSuccess(
                  JobPlanCrewRequirement,
                  action.payload.crewRequirement
                )
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  updateEmergTickets$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateEmergencyTicket),
        exhaustMap(action =>
          this.emergTicketService.updateTicket(action.payload).pipe(
            map(response => {
              this.store$.dispatch(
                new UpdateSuccess(EmergencyTicket, response.emergencyTicket)
              );
              this.store$.dispatch(new UpdateSuccess(Job, response.job));
              this.store$.dispatch(
                new UpdateSuccess(JobPlan, response.jobPlan)
              );
              this.store$.dispatch(
                new UpdateManySuccess(
                  JobPlanCrewRequirement,
                  response.crewRequirements
                )
              );
              this.store$.dispatch(
                new UpdateManySuccess(
                  JobPlanCrewAssignment,
                  response.crewAssignments
                )
              );
              this.store$.dispatch(
                new UpdateManySuccess(
                  JobPlanEquipReq,
                  response.equipRequirements
                )
              );
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  createEmergCrew$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createEmergencyCrew),
        exhaustMap(action =>
          this.emergTicketService.createCrew(action.payload).pipe(
            map(response => {
              this.store$.dispatch(
                new CreateSuccess(JobPlanCrewRequirement, response.crewReq)
              );
              this.store$.dispatch(
                new CreateSuccess(JobPlanCrewAssignment, response.crewAssign)
              );
              this.store$.dispatch(new CreateSuccess(Crew, response.crew));
            })
            // catchError(error => {
            //   console.log(error);
            // })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private emergTicketService: EmergencyTicketService,
    private store$: Store<AppState>
  ) {}
}
