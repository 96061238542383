import { createAction, props } from '@ngrx/store';

export const loadAllBaseData = createAction('[Base Data] Load All');
export const loadAllBaseDataSuccess = createAction(
  '[Base Data API] Load All Success'
);
export const loadAllBaseDataError = createAction(
  '[Base Data API] Load All Error',
  props<{ error: any }>()
);
