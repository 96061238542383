import { filter, map, pairwise, take } from 'rxjs/operators';
import { JobIncidentMedia } from '@app/models';
import { Observable } from 'rxjs';
import { S3UploadService, S3UploadPayload } from './s3-upload.service';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@briebug/ngrx-auto-entity';
import { Store } from '@ngrx/store';
import { AppState, jobIncidentMediaSelectors } from '@app/state';

export interface CreateJobIncidentMediaPayload {
  jobIncidentMedia: JobIncidentMedia;
  image: File;
}

@Injectable({
  providedIn: 'root',
})
export class JobIncidentMediaService {
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private s3Uploader: S3UploadService,
    private store$: Store<AppState>
  ) {}

  getLink(mediaId: string): Observable<string> {
    return this.http
      .get<JobIncidentMedia>(`${this.api.url}/jobs/incidents/media/${mediaId}`)
      .pipe(map(result => result.image));
  }

  create(payload: JobIncidentMedia): Observable<JobIncidentMedia> {
    return this.http.post<JobIncidentMedia>(
      `${this.api.url}/jobs/incidents/media`,
      payload
    );
  }

  uploadFile(payload: CreateJobIncidentMediaPayload) {
    console.log(`Uploading to ${payload.jobIncidentMedia.uploadUrl}`);
    const s3Payload: S3UploadPayload = {
      url: payload.jobIncidentMedia.uploadUrl,
      file: payload.image,
      messages: {
        connected: 'Connected...',
        uploadProgress: `Uploading ${payload.image.name}...`,
        uploadProgressComplete: `Processing ${payload.image.name} on server...`,
      },
      entityType: 'JobIncidentMedia',
      entityId: payload.jobIncidentMedia.id,
      onComplete: () => {
        const jobIncidentMedia: JobIncidentMedia = {
          ...payload.jobIncidentMedia,
          imageExists: true,
        };
        this.store$.dispatch(new Update(JobIncidentMedia, jobIncidentMedia));
        return this.store$
          .select(jobIncidentMediaSelectors.selectIsSaving)
          .pipe(
            pairwise(),
            filter(([a, b]) => a === true && b === false),
            take(1)
          );
      },
    };
    this.s3Uploader.upload(s3Payload);
  }

  continueUpload(id: string, image: File) {
    this.http
      .get<JobIncidentMedia>(`${this.api.url}/jobs/incidents/media/${id}`)
      .subscribe(jobIncidentMedia => {
        this.uploadFile({
          jobIncidentMedia,
          image,
        });
      });
  }
}
