import { createEffect, ofType, Actions } from '@ngrx/effects';
import { LoadAllSuccess } from '@briebug/ngrx-auto-entity';
import {
  loadAllMaterials,
  loadAllMaterialsSuccess,
  loadAllMaterialsError,
} from './materials.actions';
import { exhaustMap, mergeMap, catchError, map } from 'rxjs/operators';
import { MaterialsService } from '@services/api/materials.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { MaterialCategory, MaterialVendor, Vendor } from '@app/models';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class MaterialsEffects {
  loadAllMaterials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllMaterials),
      exhaustMap(() =>
        this.materialsService.loadAll().pipe(
          map(materials => {
            this.store$.dispatch(
              new LoadAllSuccess(MaterialCategory, materials.materialCategories)
            );
            this.store$.dispatch(
              new LoadAllSuccess(MaterialVendor, materials.materialVendors)
            );
            this.store$.dispatch(new LoadAllSuccess(Vendor, materials.vendors));
            return loadAllMaterialsSuccess();
          }),
          catchError(error => {
            return of(loadAllMaterialsError({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private materialsService: MaterialsService,
    private store$: Store<AppState>
  ) {}
}
