import { UserVM } from './user.model';
import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'JobDateCrew' })
export class JobDateCrew {
  @Key id?: string;
  crewId: string;
  foreman: string;
  employees: string[];
}

export interface JobDateCrewVM {
  id?: string;
  crewId: string;
  foreman: UserVM;
  employees: UserVM[];
}
